import * as Yup from 'yup';

export const invoiceSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string()
    .email('Invalid email')
    .when('subscribeEmail', {
      is: true,
      then: Yup.string().required('Email is required when subscribing'),
      otherwise: Yup.string().notRequired(),
    }),
  country: Yup.string().required('Country is required'),
  paymentPeriod: Yup.number()
    .required('Payment period is required')
    .positive('Payment period must be positive'),
  invoiceCreationDate: Yup.string().required(
    'Invoice creation date is required'
  ),
  dueDate: Yup.string()
    .required('Payment due date is required')
    .test('is-after', 'Due date must be after creation date', function (value) {
      const { invoiceCreationDate } = this.parent; // Access the parent object
      if (!value || !invoiceCreationDate) return true; // Skip validation if either date is not provided
      return new Date(value) > new Date(invoiceCreationDate); // Check if due date is after creation date
    }),
  currency: Yup.string()
    .required('Currency is required')
    .oneOf(['EUR', 'USD'], 'Invalid currency'),
  amount: Yup.number()
    .required('Amount is required')
    .positive('Amount must be positive'),
});
