import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import { AppContext } from 'store/app.context';
import CardsHeaderAviability from 'components/cards/card-header-aviability';
import DetailsSkillsSettings from './inner-pages/details-skills/details-skills';
import ExperienceEducationSettings from './inner-pages/experience-education/experience-education';
import Availibility from './inner-pages/availability/availability';
import Profile from 'pages/profile';
import PrevieousExperience from './components/preveious-expereince/PrevieousExpereince';
import './settings.scss'
import { NotificationPreferences } from 'pages/profile/components/notifications/notifications';

const Settings = ({ location }) => {
  const { languageState, userState } = useContext(AppContext);
  const [activeItem, setActiveItem] = useState('BASIC');
  useEffect(() => {
    if (location?.state?.activeItem) {
      setActiveItem(location.state.activeItem);
      window.history.replaceState({}, document.title);
    }
  }, []);
  const getActiveItem = (item) => {
    switch (item) {
      case 'DETAILS':
        return <DetailsSkillsSettings />;
      case 'EXPEDU':
        return <ExperienceEducationSettings />;
      case 'BASIC':
        return <Profile />;
      case 'PREVIOUS_EXPERIENCE':
        return <PrevieousExperience />;
      // case 'PORTFOLIO':
      //   return <Portfolio />;
      case 'AVAILABILITY':
        return <Availibility />;
      case 'NOTIFICATIONS':
        return <NotificationPreferences/>
      default:
        return <DetailsSkillsSettings />;
    }
  };

  return (
    <>
      <CardsHeaderAviability title="SETTINGS" previousPath={undefined} />
      <Container className="mt-3 settings-container" fluid>
        <div className="d-flex justify-content-between flex-wrap mb-3">
          <h2>{languageState.translation.SETTINGS.UPDATE_PROFILE}</h2>
          {/* <LinkedInConnect /> */}
        </div>
        <Row>
          <Col className="col-lg-3 col-sm-12 col-12 ">
            <Card>
              <div className="d-flex flex-column s pb-5 ">
                <p
                  className={
                    activeItem === 'BASIC'
                      ? 'item-selected p-3 h4  item-details'
                      : 'p-3 h4  item-details'
                  }
                  onClick={() => setActiveItem('BASIC')}
                >
                  {languageState.translation.SETTINGS.BASIC_INFORMATION}
                </p>
                {userState?.userInfo?.kind === 'FREELANCER' && <p
                  className={
                    activeItem === 'DETAILS'
                      ? 'item-selected p-3 h4  item-details'
                      : 'p-3 h4  item-details'
                  }
                  onClick={() => setActiveItem('DETAILS')}
                >
                  {languageState.translation.SETTINGS.PERSONAL_DETAILS}
                </p>}
               {/*  {userState?.userInfo?.kind === 'FREELANCER' && <p
                  className={
                    activeItem === 'PREVIOUS_EXPERIENCE'
                      ? 'item-selected p-3 h4  item-details'
                      : 'p-3 h4  item-details'
                  }
                  onClick={() => setActiveItem('PREVIOUS_EXPERIENCE')}
                >
                  {languageState.translation.SETTINGS.PREVEIOUS_EXPERIENCE}
                </p>} */}
                {userState?.userInfo?.kind === 'FREELANCER' && <p
                  className={
                    activeItem === 'EXPEDU'
                      ? 'item-selected p-3 h4  item-details'
                      : 'p-3 h4  item-details'
                  }
                  onClick={() => setActiveItem('EXPEDU')}
                >
                  {languageState.translation.SETTINGS.EXPERIENCE_EDUCATION}
                </p>}
                {userState?.userInfo?.kind === 'FREELANCER' && <p
                  className={
                    activeItem === 'AVAILABILITY'
                      ? 'item-selected p-3 h4  item-details'
                      : 'p-3 h4  item-details'
                  }
                  onClick={() => setActiveItem('AVAILABILITY')}
                >
                  {languageState.translation.SETTINGS.AVAILIBILITY}
                </p>}
                {userState?.userInfo?.kind === 'FREELANCER' && <p
                  className={
                    activeItem === 'NOTIFICATIONS'
                      ? 'item-selected p-3 h4  item-details'
                      : 'p-3 h4  item-details'
                  }
                  onClick={() => setActiveItem('NOTIFICATIONS')}
                >
                  {languageState.translation.SETTINGS.NOTIFICATIONS_PREFEENCES}
                </p>}
              </div>
            </Card>
          </Col>
          <Col className="col-lg-9 ">{getActiveItem(activeItem)}</Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
