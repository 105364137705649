import HeaderRecruiter from 'components/Recruiter/HeaderRecruiter';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'store/app.context';
import AddEditMission from 'pages/recruiter/Addmission/Addmission';
import useMissionService from 'services/missions/mission.service';
import { useParams } from 'react-router-dom';

const MissionInfo = () => {
  const [mission, setMission] = useState(null);
  const { missionState } = useContext(AppContext);
  const { getMissionById } = useMissionService();

  const params = useParams();

  useEffect(() => {
    if (params?.id) {
      getMissionById(params.id);
    }
    return () => {
      getMissionById(params.id);
    };
  }, []);

  useEffect(() => {
    if (missionState.selectedMission) {
      setMission(missionState.selectedMission);
    }
  }, [missionState]);

  return (
    <>
      {mission && <AddEditMission type="EDIT" mission={mission} />}
    </>
  );
};

export default MissionInfo;
