import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap"

export const UpgradePremiumModal = ({modalShow, closeModal}) => {
    const history = useHistory();
    return(  <>
    <Modal
        isOpen={modalShow}
        toggle={closeModal}
        centered
        className="p-4">
            <ModalBody className=" text-center d-flex flex-column align-items-center gap-2">
           <p className="text-sm text-bold">You have reached the applications limit this week. Upgrade your plan to get unlimited applications</p> 
        <Button color="" className="w-50 bg-danger text-center rounded-lg text-white text-bold p-2 cursor-pointer" onClick={() => history.push('/private/upgrade_plan')}>Upgrade plan</Button>

            </ModalBody>
        </Modal>
            </>
    )
}