import React, { useContext, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button } from 'reactstrap';
import { AppContext } from 'store/app.context';
import { toast } from 'react-toastify';
import useSettingsService from 'services/settings/settings.service';
import { isNil, isEmpty } from "lodash"
import SKILLS_STATE from 'store/settings/skills/skills.state';

const Missionsskills = ({ skills, setSkills }) => {
  const { languageState, skillsState, dispatchSkillsState } = useContext(AppContext);
  const { getSkills, addSkill } = useSettingsService();
  const [options, setOptions] = useState([]);
  const [skill, setSkill] = useState(null);

  useEffect(() => {
    getSkills();
  }, []);

  useEffect(() => {
    if (skillsState?.skills) {
      const newOptions = skillsState.skills.map(skill => ({
        value: skill.id,
        label: skill.key
      }));
      setOptions(newOptions);
    }
  }, [skillsState]);

  const CustomNoOptionsMessage = () => (
    <div className='text-center my-1'>
      {languageState.translation.COMMON.CREATE_TAG}
    </div>
  );

  return (
    <div>
      <div className="d-flex mt-4 align-items-center">
        <CreatableSelect
          components={{ NoOptionsMessage: CustomNoOptionsMessage }}
          onInputChange={(e) => setSkill(e)}
          value={skill}
          options={skills?.length ? options.filter((el) => {
            return !skills.includes(el)
          }) : skill ? options.filter(el => el.label.toUpperCase().includes(skill.toUpperCase())) : []}
          isClearable
          className="w-100 h-40"
          placeholder={languageState.translation.COMMON.SEARCH_TAG}
          onChange={(e) => {
            !isNil(e) && !isEmpty(e) && setSkills([...skills, e]);
          }}
          onCreateOption={async (e) => {
            const response = await addSkill({ key: e });
            if (response && response.data) {
              const newSkillId = response.data.id;
              const updatedSkills = [
                ...skills,
                { value: newSkillId, label: e },
              ];           
              setOptions([...options, { value: newSkillId, label: e }])
            setSkills(updatedSkills);

          }}
        }
        />
        <Button
          color="primary"
          className="ml-4 pr-6 pl-6 h-40"
          onClick={() => {
            if (skill) {
              setSkills([...skills, skill]);
              setSkill(null);
            }
            else {
              toast.warn(
                "Please select a skill befor add",
                {
                  position: 'bottom-right',
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                }
              );
            }
          }}
        >
          {languageState.translation.COMMON.BUTTONS.ADD}
        </Button>
      </div>
      {(skills && skills.length > 0) ? (
        <div className="d-flex mt-4 align-items-center gap-1 flex-wrap tags-mission">
          <span className="text-bold">Selected skills:</span>
          {skills &&
            skills.map((myItem, index) => (
              <span key={index} className="tag btn-tags text-white cursor-pointer pl-4 pr-4">
                {myItem && myItem.label}
                <a onClick={() => setSkills(skills.filter((item) => item.value !== myItem.value))} />
              </span>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default Missionsskills;
