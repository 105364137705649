const TYPES = {
  SET_QUICKLINKS: '[HOME] SET_QUICKLINKS',
  SET_EVENTS: '[HOME] SET_EVENTS',
  GET_EVENTS: '[HOME] GET_EVENTS',
  GET_QUICKLINKS: '[HOME] GET_QUICKLINKS',
  SET_FEEDS: '[HOME] SET_FEEDS',
  GET_FEEDS: '[HOME] GET_FEEDS',
  SET_NB_NEW_JOBS: '[HOME] GET_NB_NEW_JOBS',
  OPEN_NEWS_MODAL: '[HOME] OPEN_NEWS_MODAL',
  CLOSE_NEWS_MODAL: '[HOME] CLOSE_NEWS_MODAL',
  SET_USER_ID:'[HOME] SET_USER_ID'
};

export default TYPES;
