import { useContext } from 'react';
import { AppContext } from 'store/app.context';
import NOTIFICATION_STATE from 'store/notification/';
import USER_STATE from 'store/user';
import useHttpClient from '../http-client-hook';

const useUserService = () => {
  const { httpClient } = useHttpClient();
  const { dispatchUserState, dispatchNotificationsState } = useContext(AppContext);

  /* Update Basic Infos */
  const updateBasicInfos = async (payload) => httpClient.patch(`/users/me`, payload);
  //   const result = await 
  //   if (result && result.status == 200) {
  //     getUserInfo();
  //     getMySkills();
  //   }
  // }

  /* Get User Info */
  const getUserInfo = async () => {
    const result = await httpClient.get('/users/me');
    if (result && result.status == 200) {
      dispatchUserState({
        type: USER_STATE.types.CHANGE_USER_INFO,
        userInfo: result.data,
      });
    }
  };

  /* Auto Update User Info */
  const autoUpdateUserInfo = async () => {
    const result = await httpClient.get('/users/me');
    if (result && result.status == 200) {
      dispatchUserState({
        type: USER_STATE.types.CHANGE_USER_INFO,
        userInfo: result.data,
      });
    }
  };

  /* Add Address */
  const addAddress = async (payload) => httpClient.post('/address', payload);

  /* Get Address */
  const getAddress = async () => {
    const result = await httpClient.get('/address');
    if (result && result.status == 200) {
      dispatchUserState({
        type: USER_STATE.types.SET_USER_ADDRESS,
        address: result.data,
      });
    }
  };

  /* Add Position */
  const addPosition = async (payload) => httpClient.post('/positions', payload);

  /* Send Notification Message */
  const sendNotificationMessage = async (payload) =>
    httpClient.post('users/notification/message', payload);

  /* Invite Multiple Users */
  const inviteMultipleUsers = async (payload) =>
    httpClient.post('/users/email-invite', payload);
  /* Get Invited User */
  const getInvitedUser = async (email) => {
    const result = await httpClient.get('/users/getUserDetails/' + email, {});
    if (result && result.status === 200) {
      return result.data[0];
    }
  };

  const getSubscriptionsByUser = async () => {
    const result = await httpClient.get('/payment/user-subscription', {});
    return result.data || null
  };

  /* Get Notifications */
  const getNotifications = async (page) => {
    const result = await httpClient.get('/notification', {
      params: { limit: 6, page: page ? page : 1 },
    });
    if (result && result.status === 200) {
      dispatchNotificationsState({
        type: NOTIFICATION_STATE.types.GET_NOTIFICATION,
        notifications: result.data,
      });
    }
  };

  const deleteNotification = async (id) =>{
    const result = httpClient.delete('notification/' + id)
  }


const checkIsValidEUVat = async (countryCode, vatCode) => {
  const result = await httpClient.get(`/invoices/validate-vat?countryCode=${countryCode}&vatNumber=${vatCode}`);
return result.data
}
const createStipeCustomer = async(payload) => {
  const response = await httpClient.post('/users/create-customer', payload)
  return  response
}

const getFreelancerCheckout = () => {
  return  httpClient.get('/payment/checkout-freelancer')
 
}
const getUserPreferences = async(payload) => {
  return await httpClient.get("/notification/preferences")
}
const setUserPreferences = async(payload) => {
  return await httpClient.patch("/notification/preferences", payload)
}
const getSubscriptions = async () => {
  const subscriptions = await  httpClient.get("/subscription")
return subscriptions.data

} 
return {
    addAddress,
    getAddress,
    updateBasicInfos,
    autoUpdateUserInfo,
    getUserInfo,
    addPosition,
    getNotifications,
    deleteNotification,
    inviteMultipleUsers,
    getInvitedUser,
    sendNotificationMessage,
    getSubscriptionsByUser,
    checkIsValidEUVat,
    createStipeCustomer,
    getFreelancerCheckout,
    getUserPreferences,
    setUserPreferences,
    getSubscriptions
  };
};

export default useUserService;
