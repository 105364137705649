import TYPES from './types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.GET_NOTIFICATION: {
      return { ...state, notifications: action.notifications };
    }
    case TYPES.DELETE_NOTIFICATION: {
      const updatedNotifications = state.notifications.items.filter(
        (notification) => notification.id !== action.id
      );
      return {
        ...state,
        notifications: {
          ...state.notifications,
          items: updatedNotifications,
          meta: {
            ...state.notifications.meta,
            totalItems: state.notifications.meta.totalItems - 1,
          },
        },
      };
    }
  }
};

export default reducer;
