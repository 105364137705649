import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PublicNavbar from './navbar/PublicNavbar';
import { useHistory } from 'react-router-dom';
import publicRoutes from 'navigation/public.routes';

const PublicDashboard = () => {
  const history = useHistory();

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/public') {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  const getContainerClass = () => {
    const path = history.location.pathname;
    if (path.includes('/jobs/jobboard')) {
      return 'public-dashboard-jobboard';
    }
    if (path.includes('/add-mission')) {
      return 'public-dashboard-mission';
    }
    return 'public-dashboard-detail-profile';
  };

  return (
    <div>
      {!history.location.pathname.includes('late-invoice-detection') && (
        <PublicNavbar />
      )}
      <div
        className={`${getContainerClass()} public-dashboard-container px-4 rounded ${
          !history.location.pathname.includes('late-invoice-detection')
            ? 'py-0 shadow-lg'
            : 'py-0'
        }`}
      >
        <Switch>{getRoutes(publicRoutes)}</Switch>
      </div>
    </div>
  );
};

export default PublicDashboard;
