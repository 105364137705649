import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalHeader, ModalBody, Table, Button } from 'reactstrap';
import { LateInvoiceDetectionModal } from '../LateInvoiceDetectionModal/lateInvoiceDetectionModal';
import './lateInvoiceDetection.scss';
import { AppContext } from 'store/app.context';
import { useInvoiceService } from 'services/invoice/invoice.service';
import moment from 'moment';
import CountryFlag from 'react-country-flag';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(en);

export const LateInvoiceDetection = () => {
  const { languageState, userState } = useContext(AppContext);
  const { getPublicInvoices, postPublicInvoice } = useInvoiceService();

  const [modalShow, setModalShow] = useState(false);
  const [invoices, setInvoices] = useState([]);

  const translatedCountries = countries.getNames(
    languageState?.defaultLanguage
  );
  const countryOptions = Object.entries(translatedCountries).map(
    ([code, name]) => ({
      value: code,
      label: name,
    })
  );

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const getPreviousInvoices = async () => {
    const response = await getPublicInvoices();
    setInvoices(response || []);
    return;
  };

  useEffect(() => {
    getPreviousInvoices();
  }, []);

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      default:
        return '€';
    }
  };

  const calculateDaysLate = (dueDate) => {
    if (!dueDate) return { days: 0, message: 'No due date provided' };
    const today = new Date();
    const due = new Date(dueDate);
    const diffTime = today - due;
    const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (due > today) {
      return { days, message: `${Math.abs(days)} days left until due date.` };
    } else {
      return { days: days, message: `${Math.abs(days)} days late.` };
    }
  };
  const getCountryName = (country) => {
    const countryName = countryOptions.find(
      (el) => el.value === country
    )?.label;
    return countryName;
  };

  const calculateTotalAmount = () => {
    return invoices.reduce((total, invoice) => total + invoice.amount, 0);
  };

  return (
    <div className="">
      <div className="">
        <div className="invoice-header">
          <p>
            {languageState.translation.INVOICE.PUBLIC_INVOICE.HEADER_PARAGRAPH}
          </p>
          <h1>
            {languageState.translation.INVOICE.PUBLIC_INVOICE.LARGEST_UNPAID}
          </h1>
          <div className="total-amount">
            <span>
              {languageState.translation.INVOICE.PUBLIC_INVOICE.TOTAL_AMOUNT}:
            </span>
            <span className="amount">
              {getCurrencySymbol('EUR')}{' '}
              {calculateTotalAmount()}
            </span>
          </div>
        </div>
        <Button
  className="mb-1 ml-auto float-right inline-block p-0 py-1"
  onClick={() => setModalShow(true)}
          color="primary"
        >
          <p className="font-weight-bold text-center">{languageState.translation.INVOICE.PUBLIC_INVOICE.SUBMIT_LATE}</p>
        </Button>
      </div>

      <Table className="align-items-center table-flush" responsive>
        <thead>
          <tr>
            <th>{languageState.translation.INVOICE.PUBLIC_INVOICE.NAME}</th>
            <th>{languageState.translation.INVOICE.PUBLIC_INVOICE.COUNTRY}</th>
            <th>
              {
                languageState.translation.INVOICE.PUBLIC_INVOICE
                  .PAYMENT_DUE_DATE
              }
            </th>
            <th>{languageState.translation.INVOICE.PUBLIC_INVOICE.AMOUNT}</th>
            <th>
              {languageState.translation.INVOICE.PUBLIC_INVOICE.DAYS_LATE}
            </th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => {
            const daysLateInfo = calculateDaysLate(invoice.dueDate);
            const daysLate = daysLateInfo.days;
            const daysLateClass =
              daysLate > 0
                ? 'text-danger font-weight-bold'
                : daysLate === 0
                ? 'text-primary font-weight-bold'
                : 'text-success font-weight-bold';

            return (
              <tr key={invoice.id}>
                <td>{invoice.name || 'Anonymous Poster'}</td>
                <td>
                  <CountryFlag
                    countryCode={invoice.country}
                    svg
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                      marginRight: '0.5em',
                      borderRadius: '100%',
                    }}
                  />
                  {getCountryName(invoice.country)}
                </td>
                <td className="font-weight-bold">
                  {moment(invoice.dueDate).format('DD-MM-YYYY')}
                </td>
                <td className="font-weight-bold">
                  {getCurrencySymbol(invoice.currency) + invoice.amount}
                </td>
                <td className={daysLateClass}>{daysLateInfo.message}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <LateInvoiceDetectionModal
        modalShow={modalShow}
        closeModal={() => {
          getPreviousInvoices();
          setModalShow(false);
        }}
      />
    </div>
  );
};
