const { default: TYPES } = require('./home.types');

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_QUICKLINKS:
      return {
        ...state,
        quickLinks: action.quickLinks,
      };
    case TYPES.SET_EVENTS:
      return {
        ...state,
        events: action.events,
      };
    case TYPES.SET_FEEDS:
      return {
        ...state,
        feeds: action.feeds,
      };
    case TYPES.SET_NB_NEW_JOBS:
      return {
        ...state,
        nbNewJobs: action.nbNewJobs,
      };
      case TYPES.OPEN_NEWS_MODAL:
        return { ...state, isNewsModalOpen: true };
      case TYPES.CLOSE_NEWS_MODAL:
        return { ...state, isNewsModalOpen: false };
        case TYPES.SET_USER_ID:
          {
            return {...state, publicUser: action.publicUser}
          }
  }
};
export default reducer;
