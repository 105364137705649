import * as yup from 'yup';
import moment from 'moment';

const schema = (validationMessages) =>
  yup.object({
    firstName: yup
      .string()
      .min(
        2,
        `${validationMessages.MIN_LENGTH_FIELD} 3 ${validationMessages.CHARS}`
      )
      .max(
        32,
        `${validationMessages.MAX_LENGTH_FIELD} 32 ${validationMessages.CHARS}`
      )
      .required(validationMessages.REQUIRED_FIELD),
    lastName: yup.string().required(validationMessages.REQUIRED_FIELD),
    email: yup
      .string()
      .email(validationMessages.EMAIL)
      .required(validationMessages.REQUIRED_FIELD),
    company: yup.string().required(validationMessages.REQUIRED_FIELD),
    functionTitle: yup.string().required(validationMessages.REQUIRED_FIELD),
    startDate: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        if (!originalValue) return null;
        const date = moment(originalValue).startOf('day');
        return date.isValid() ? date.toDate() : null;
      })
      .typeError(validationMessages.INVALID_DATE)
      .test('is-valid-date', validationMessages.INVALID_DATE, function (value) {
        return !value || moment(value).isValid();
      })
      .test(
        'is-before-end-date',
        validationMessages.START_DATE_BEFORE_END_DATE,
        function (value) {
          const { endDate } = this.parent;
          if (!value || !endDate) return true;
          return moment(value)
            .startOf('day')
            .isSameOrBefore(moment(endDate).startOf('day'));
        }
      ),
    endDate: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        if (!originalValue) return null;
        const date = moment(originalValue).startOf('day');
        return date.isValid() ? date.toDate() : null;
      })
      .typeError(validationMessages.INVALID_DATE)
      .test('is-valid-date', validationMessages.INVALID_DATE, function (value) {
        return !value || moment(value).isValid();
      }),
  });

export default schema;
