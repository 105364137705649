import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import Dropzone from 'dropzone';
import { AppContext } from 'store/app.context';
import { IMG_PREFIX } from 'config/core';

const MultipleImageUpload = ({ defaultImages, onChanges, onDeleteImage, placeholder, defaultValues }) => {
  const { languageState } = useContext(AppContext);
  const files = useRef([]);
  const [instance, setInstance] = useState()
  useEffect(() => {
    let currentMultipleFile;
    // multiple dropzone file - accepts any type of file
    if (instance) {
    }
    if (!instance) {
      new Dropzone(document.getElementById('dropzone-multiple'), {
        url: 'http://',
        autoProcessQueue: false,
        thumbnailWidth: null,
        thumbnailHeight: null,
        previewsContainer: document.getElementsByClassName(
          'dz-preview-multiple'
        )[0],
        previewTemplate: document.getElementsByClassName('dz-preview-multiple')[0]
          .innerHTML,
        maxFiles: null,
        acceptedFiles: null,
        init: function () {

          setInstance(this)
          this.on('addedfile', function (file) {
            files.current.push(file);
            if (!file.type.match(/image.*/)) {
              // This is not an image, so Dropzone doesn't create a thumbnail.
              // Set a default thumbnail:
              this.emit("thumbnail", file, require('assets/img/pdf.png').default);

              // You could of course generate another image yourself here,
              // and set it as a data url.
            }
            if (defaultImages) {
              onChanges([...defaultImages, ...files.current]);
            } else onChanges(files.current);

          });
          this.on('removedfile', function (file) {
            const updatedFiles = files.current.filter((img) => img.name !== file.name);
            files.current = updatedFiles;
            onChanges(files);
          });
        },

      });


      document.getElementsByClassName('dz-preview-multiple')[0].innerHTML = '';
    }
  }, []);
  useEffect(() => {
    if (instance) {
      if (defaultValues && defaultValues.length > 0) {


        for (const iterator of defaultValues) {
          const isExist = files.current.find((img) => img?.name === iterator);
          if (!isExist) {
            const mockFile = { name: iterator, size: 55, type: 'pdf' }
            instance?.emit("addedfile", mockFile);
          }

        }
      }
    }
  }, [defaultValues])
  useEffect(() => {
    document.getElementsByClassName('dz-message')[0].firstChild.textContent =
      placeholder ? placeholder : languageState.translation.COMMON.DROP_UPLOAD;
  }, [languageState]);

  const deleteImage = (img) => {
    onDeleteImage(img);
  };

  return (
    <div className="dropzone dropzone-multiple" id="dropzone-multiple">
      <div className="fallback">
        <div className="custom-file">
          <input
            className="custom-file-input"
            id="customFileUploadMultiple"
            multiple="multiple"
            type="file"
          />
          <label
            className="custom-file-label"
            htmlFor="customFileUploadMultiple"
          >

          </label>
        </div>
      </div>
      {defaultImages &&
        defaultImages.length > 0 &&
        defaultImages.map((img, index) => (
          <ListGroup key={index} className=" list-group-lg" flush>
            <ListGroupItem className=" px-0">
              <Row className="flex-nowrap align-items-center">
                <Col className=" col-auto">
                  <div className=" avatar">
                    <img
                      alt=""
                      className=" avatar-img rounded "
                      data-dz-thumbnail
                      src={`${IMG_PREFIX}${img}`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = require('assets/img/pdf.png').default;
                      }}

                    />
                  </div>
                </Col>
                <div className=" col ml--3">
                  <h4 className=" mb-1 white-space-nowrap" data-dz-name>
                  </h4>
                  <p className=" small text-muted mb-0" data-dz-size></p>
                </div>
                <Col className=" col-auto">
                  <Button
                    size="sm"
                    color="danger"
                    data-dz-remove
                    onClick={() => deleteImage(img)}
                  >
                    <i className="fas fa-trash" />
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        ))}
      <ListGroup
        className=" dz-preview dz-preview-multiple list-group-lg"
        flush
      >
        <ListGroupItem className=" px-0">
          <Row className="flex-nowrap align-items-center">
            <Col className=" col-auto">
              <div className=" avatar">
                <img
                  alt=""
                  className=" avatar-img rounded "
                  data-dz-thumbnail
                  src=""

                />
              </div>
            </Col>
            <div className=" col ml--3">
              <h4 className=" mb-1 text-word-break-all" data-dz-name></h4>
              <p className=" small text-muted mb-0" data-dz-size></p>
            </div>
            <Col className=" col-auto">
              <Button size="sm" color="danger" outline data-dz-remove>
                <i className="fas fa-trash" />
              </Button>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default MultipleImageUpload;
