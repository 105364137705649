import * as yup from 'yup';

const schema = (validationMessages) =>
  yup.object({
    firstName: yup.string().required(validationMessages.REQUIRED_FIELD),
    lastName: yup.string().required(validationMessages.REQUIRED_FIELD),
    email: yup
      .string().required(validationMessages.REQUIRED_FIELD)
      .email(validationMessages.EMAIL)
      ,
    password: yup
      .string().required(validationMessages.REQUIRED_FIELD)
      .min(8, validationMessages.MIN_PASSWORD)
      .oneOf(
        [yup.ref('confirmPassword'), null],
        validationMessages.PASSWORD_MATCH
      )
      ,
    confirmPassword: yup
      .string().required(validationMessages.REQUIRED_FIELD)
      .min(8, validationMessages.MIN_PASSWORD)
      .oneOf([yup.ref('password'), null], validationMessages.PASSWORD_MATCH)
      ,
      companyName: yup.string().required(validationMessages.REQUIRED_FIELD),
      postalCode: yup.string().required(validationMessages.REQUIRED_FIELD),
      street: yup.string().required(validationMessages.REQUIRED_FIELD),
      houseNumber: yup.string().required(validationMessages.REQUIRED_FIELD),
      city: yup.string().required(validationMessages.REQUIRED_FIELD).matches(/^[a-zA-Z\s\-']+$/, validationMessages.INVALID_CITY),
      VATNumber: yup.string(),
      country: yup.string().required(validationMessages.REQUIRED_FIELD),
      countryCode: yup.string(),
      freelancersEmails: yup.string()
        .nullable()
        .matches(
          /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\n[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/,
          validationMessages.INVALID_EMAIL_LIST
        ),
      });

export default schema;

