import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import { AppContext } from 'store/app.context';
import { Formik } from 'formik';
import { clientSchema } from './validators'
import { toast } from 'react-toastify';
import useUserService from 'services/profile/user.service';
import countries from 'i18n-iso-countries';

import en from 'i18n-iso-countries/langs/en.json';
import CLIENTS_STATE from 'store/clients/clients.state';
countries.registerLocale(en);


export const ModalAddClient = ({ modalShow, closeModal }) => {
    const { languageState, dispatchClientsState } = useContext(AppContext);


    const translatedCountries = countries.getNames(languageState?.defaultLanguage); // Get translated country names
const countryOptions = Object.entries(translatedCountries).map(([code, name]) => ({
  value: code,
  label: name
}));


    const { checkIsValidEUVat } = useUserService();
    const [isLoading, setIsLoading] = useState(false)
    const [isValidVat, setIsValidVat] = useState(false)

    const [EUVatResponse, setEUVatresponse] = useState({
        address: "",
        countryCode: "",
        name: ''
    })
    const [fieldValues, SetFieldValues] = useState({
        VATNumber: '',
        companyName: '',
        address: "",
        alias: ""
    })


    function isValidVAT(input) {
        const cleanedInput = input.replace(/[\s.]+/g, '');
        const vatRegex = /^[A-Z]{2}[A-Z0-9]{10,12}$/;
        return { valid: vatRegex.test(cleanedInput), cleaned: cleanedInput };
    }

    const checkValidEUVat = async (vat) => {
        const countryCode = vat.slice(0, 2);
        const vatCode = vat.slice(2);
        setIsLoading(true)
        const response = await checkIsValidEUVat(countryCode, vatCode);
        setIsValidVat(response.valid);
        if (response.valid) {
            SetFieldValues({
                ...fieldValues,
                VATNumber: vat,
                companyName: response.name,
                address: response.address
                
            })
        }
        else {
            SetFieldValues((prev) => ({
                ...fieldValues,
                companyName: "",
                address: ""
            }))
        }

        setIsLoading(false);
    }


    const addClientHandler = async (values) => {
        if (values) {
            const parsedAddress = parseAddress(values.address)
            const countryCode = values.VATNumber.slice(0, 2);
            const country = countryOptions.find(option => option.value === countryCode);
            parsedAddress.country = country.label;
            const clients = JSON.parse(sessionStorage.getItem("newClientInfo")) || [];
            const newClient = { ...values, address: parsedAddress }
            clients.push(newClient)
            console.log(clients)
            sessionStorage.setItem("newClientInfo", JSON.stringify(clients))
            dispatchClientsState({
                type: CLIENTS_STATE.types.ADD_CLIENT, 
                client: newClient,
              });
            toast.success(languageState.translation.FOCUS.DASHBOARD.LEAD_ADDED, {
                position: 'bottom-right',
                hideProgressBar: false,
                autoClose: 4000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
            closeModal();
        }
    };
    function parseAddress(input) {
        const [firstLine, secondLine] = input.split('\n');
        const firstSpaceIndex = firstLine.indexOf(' ');
        const street = firstLine.substring(0, firstSpaceIndex).trim();
        const houseNumber = firstLine.substring(firstSpaceIndex + 1).trim();
        const postalCodeEndIndex = secondLine.indexOf(' ');
        const postalCode = secondLine.substring(0, postalCodeEndIndex).trim();
        const city = secondLine.substring(postalCodeEndIndex + 1).trim();
        return { street, houseNumber, postalCode, city, country : "" };
    }
    return (
        <Modal
            isOpen={modalShow}
            toggle={closeModal}
            style={{
                maxWidth: '25rem',
                height: '100vh',
                margin: 0,
                marginLeft: 'auto',
                marginRight: '0',
                overflowY: 'hidden'
            }}
        >
            <ModalHeader
                close={
                    <button className="close" onClick={closeModal}>
                        <i className="fa fa-times" />
                    </button>
                }
                className="bg-danger d-flex align-items-center mb-1 p-2"
            >
                <p className='text-white font-weight-bold'>
                    {languageState.translation.INVOICE.ADD_NEW_CLIENT}
                </p>
            </ModalHeader>
            <ModalBody
                className="m-0 pt-0 p-2"
                style={{
                    height: '100vh',
                }}
            >
                <Formik
                    initialValues={fieldValues}
                    enableReinitialize
                    validationSchema={clientSchema(languageState.translation.FORM_VALIDATION)}
                    onSubmit={async () => {
                        await addClientHandler(fieldValues);
                    }}
                >
                    {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) => (
                        <div className="h-100 d-flex flex-column justify-content-between">
                            <div className="d-flex flex-column">
                                {/* Use flex-column for a one-column layout */}

                                <Label className="">{languageState.translation.PROFILE.VAT_NUMBER}<span className='text-red'>*</span></Label>
                                <div className='d-flex align-items-center'>
                                    <Input
                                        className={`${errors.VATNumber || !isValidVat ? "error-input" : ""}`}
                                        placeholder={languageState.translation.PROFILE.VAT_NUMBER}
                                        name="VATNumber"
                                        value={fieldValues.VATNumber}
                                        onChange={(e) => {
                                            setIsValidVat(false)
                                            const { valid, cleaned } = isValidVAT(e.target.value);
                                            setFieldValue("VATNumber", e.target.value)
                                            SetFieldValues({ ...fieldValues, VATNumber: e.target.value });
                                            if (valid) {
                                                checkValidEUVat(cleaned)
                                            }
                                            else {
                                                SetFieldValues({
                                                    ...fieldValues,
                                                    VATNumber:e.target.value,
                                                    companyName: "",
                                                    address: ""
                                                })
                                            }

                                        }}
                                    />
                                    {isLoading && (
                                        <div className="spinner-border text-primary" style={{
                                            width: '1.2rem',
                                            height: '1.2rem',
                                            marginLeft: '-1.5rem'
                                        }}>
                                        </div>
                                    )
                                    }
                                </div>
                                <Label className="">{languageState.translation.COMMON.COMPANY} <span className='text-red'>*</span></Label>
                                <Input
                                    name="companyName"
                                    onChange={(e) => {
                                        setFieldValue("companyName", e.target.value)
                                        SetFieldValues({ ...fieldValues, companyName: e.target.value })
                                    }}
                                    value={fieldValues.companyName}
                                />
                                <p className="text-red">{errors.companyName && touched.companyName && errors.companyName}</p>

                                <Label className="">{languageState.translation.JOBBOARD.ASSIGN_ALIAS}<span className='text-red'>*</span></Label>
                                <Input
                                    name="alias"
                                    onChange={(e) => {
                                        setFieldValue("alias", e.target.value)
                                        SetFieldValues({ ...fieldValues, alias: e.target.value })
                                    }}
                                    value={fieldValues.alias}
                                />
                                <p className="text-red">{errors.alias && touched.alias && errors.alias}</p>

                                <Label className="">{languageState.translation.FOCUS.TEAMS.ADDRESS}<span className='text-red'>*</span></Label>
                                <Input
                                    type="textarea"
                                    name="address"
                                    rows="12"
                                    onChange={(e) => {
                                        setFieldValue("address", e.target.value)
                                        SetFieldValues({ ...fieldValues, address: e.target.value })
                                    }}
                                    value={fieldValues.address}
                                />
                                <p className="text-red">{errors.address && touched.address && errors.address}</p>

                            </div>
                            <div className="text-right m-1 d-flex justify-content-between align-items-center">
                                <p
                                    className="font-weight-bold cursor-pointer"
                                    onClick={closeModal}
                                >
                                    {languageState.translation.COMMON.BUTTONS.CANCEL}
                                </p>

                                <button
                                    className="btn bg-danger text-white"
                                    onClick={() => handleSubmit()}
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    {languageState.translation.COMMON.BUTTONS.SUBMIT}
                                </button>
                            </div>
                        </div>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};