import React, { useState, useContext, useRef, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { DragAndDropUploadMultiple } from 'components/dragAndDrop/dragAndDropUploadMultiple';
import Datetime from 'react-datetime';
import moment from 'moment';
import './lateInvoiceDetectionModal.scss';
import { invoiceSchema } from './schema';
import { AppContext } from 'store/app.context';
import { useInvoiceService } from 'services/invoice/invoice.service';
import useFilesService from 'services/files/files.services';
import { toast } from 'react-toastify';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import Select from 'react-select';

countries.registerLocale(en);

export const LateInvoiceDetectionModal = ({ modalShow, closeModal }) => {
  const { languageState, userState } = useContext(AppContext);
  const { getPublicInvoices, postPublicInvoice } = useInvoiceService();
  const { uploadFile } = useFilesService();
  const [invoices, setInvoices] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showCopied, setShowCopied] = useState(false);
  const [showCheckMark, setShowCheckMark] = useState(false);
  const [subscribeEmail, setSubscribeEmail] = useState(false);
  const fileInputRef = useRef(null);

  const translatedCountries = countries.getNames(
    languageState?.defaultLanguage
  );
  const EnglishCountries = countries.getNames('EN');

  const countryOptions = Object.entries(translatedCountries).map(
    ([code, name]) => ({
      value: code,
      label: name,
    })
  );
  const englishCountryOption = Object.entries(EnglishCountries).map(
    ([code, name]) => ({
      value: code,
      label: name,
    })
  );

  const initialValues = {
    name: '',
    email: '',
    country: 'BE',
    paymentPeriod: '',
    dueDate: '',
    invoiceCreationDate: '',
    currency: 'EUR',
    amount: '',
    subscribeEmail: false,
  };
  const getPreviousInvoices = async () => {
    if (userState?.userInfo) {
      const response = await getPublicInvoices(userState?.userInfo?.id);
      setInvoices(response || []);
      return;
    } else {
      setInvoices([]);
    }
  };

  useEffect(() => {
    getPreviousInvoices();
  }, []);

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      default:
        return '€';
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleDeleteFile = () => {
    setUploadedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const calculateDaysLate = (dueDate) => {
    if (!dueDate) return { days: 0, message: 'No due date provided' };
    const today = new Date();
    const due = new Date(dueDate);
    const diffTime = today - due;
    const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (due > today) {
      return { days, message: `${days} days left until due date.` }; // Due date not reached yet
    } else {
      return { days, message: `${days} days late.` };
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    let attachment = '';
    if (uploadedFile) {
      const response = await uploadFile(uploadedFile);
      attachment = response.data.filename;
    }


    try {
      const payload = {
        name: values.name,
        email: subscribeEmail ? values.email : '',
        paymentPeriod: values.paymentPeriod,
        dueDate: values.dueDate,
        creationDate: values.invoiceCreationDate,
        currency: values.currency,
        country: values.country,
        amount: Number(values.amount),
        document: attachment,
        daysLate: calculateDaysLate(values.dueDate).days,
      };
      await postPublicInvoice(payload);

      toast.success(
        languageState.translation.INVOICE.PUBLIC_INVOICE.INVOICE_POSTED_SUCCESS,
        {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        }
      );
      closeModal();
      resetForm();
      await getPreviousInvoices();
      setUploadedFile(null);
    } catch (error) {
      console.error('Error submitting invoice:', error);
    }
  };

  const handleShare = async () => {
    const publicUrl =
      'https://app.extraexpertise.be/public/late-invoice-detection';
    try {
      await navigator.clipboard.writeText(publicUrl);
      setShowCopied(true);
      setShowCheckMark(true);
      setTimeout(() => {
        setShowCopied(false);
        setShowCheckMark(false);
      }, 5000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <Modal
      isOpen={modalShow}
      toggle={closeModal}
      style={{
        maxWidth: '35rem',
        height: '100vh',
        margin: 0,
        marginLeft: 'auto',
        marginRight: '0',
        overflowY: 'hidden',
      }}
    >
      <ModalHeader
        close={
          <button className="close" onClick={closeModal}>
            <i className="fa fa-times" />
          </button>
        }
      >
        <h2>{languageState.translation.INVOICE.PUBLIC_INVOICE.SUBMIT_LATE_MODAL_TITLE}</h2>
      </ModalHeader>
      <ModalBody className="late-invoice-container">
        <Formik
          initialValues={initialValues}
          validationSchema={invoiceSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue, values, resetForm }) => (
            <Form className="invoice-form">
              <div className=" d-flex flex-column justify-content-around">
                <div className="form-section">
                  <div className="file-upload-section">
                    <p>
                      {
                        languageState.translation.INVOICE.PUBLIC_INVOICE
                          .SELECT_FILE
                      }
                    </p>
                    <div className="file-upload-container">
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        accept=".pdf"
                      />
                      <button
                        type="button"
                        className="upload-button"
                        onClick={handleUploadClick}
                      >
                        <i className="fa fa-upload"></i>
                        {uploadedFile ? uploadedFile.name : 'Upload document'}
                      </button>
                      {uploadedFile && (
                        <button
                          type="button"
                          className="delete-file-btn"
                          onClick={handleDeleteFile}
                        >
                          ×
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-section h-75">
                  <h2>
                    {
                      languageState.translation.INVOICE.PUBLIC_INVOICE
                        .ENTER_DETAILS
                    }
                  </h2>
                  <div className="form-fields">
                    <div className="form-field">
                      <Field
                        type="text"
                        name="name"
                        placeholder={
                          languageState.translation.INVOICE.PUBLIC_INVOICE
                            .FIRST_NAME
                        }
                        className={errors.name && touched.name ? 'error' : ''}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error-message"
                      />
                    </div>

                    <div className="form-field">
                      <Select
                        options={countryOptions}
                        value={countryOptions.find(
                          (el) => el.value === values.country
                        )}
                        placeholder={
                          languageState.defaultLanguage === 'EN'
                            ? 'Belgium'
                            : 'België'
                        }
                        menuPlacement="top"
                        onChange={(e) => setFieldValue('country', e.value)}
                      />

                      <ErrorMessage
                        name="country"
                        component="div"
                        className="error-message"
                      />
                    </div>

                    <div className="form-field">
                      <Field
                        type="number"
                        name="paymentPeriod"
                        placeholder={
                          languageState.translation.INVOICE.PUBLIC_INVOICE
                            .PAYMENT_PERIOD
                        }
                        className={
                          errors.paymentPeriod && touched.paymentPeriod
                            ? 'error'
                            : ''
                        }
                      />
                      <ErrorMessage
                        name="paymentPeriod"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="form-field">
                      <Datetime
                        className={`${
                          errors.invoiceCreationDate &&
                          touched.invoiceCreationDate
                            ? 'error'
                            : ''
                        }`}
                        onChange={(e) => {
                          const date = moment(e).startOf('day').toDate();
                          setFieldValue('invoiceCreationDate', date);
                        }}
                        inputProps={{
                          placeholder:
                            languageState.translation.INVOICE.PUBLIC_INVOICE
                              .INVOICE_CREATION_DATE,
                          name: 'invoiceCreationDate',
                        }}
                        dateFormat="DD-MM-YYYY"
                        closeOnSelect={true}
                        timeFormat={false}
                        utc={true}
                        value={
                          values.invoiceCreationDate
                            ? moment(values.invoiceCreationDate).format(
                                'DD-MM-YYYY'
                              )
                            : ''
                        }
                      />
                      <ErrorMessage
                        name="invoiceCreationDate"
                        component="div"
                        className="error-message"
                      />
                    </div>

                    <div className="form-field">
                      <Datetime
                        className={`${
                          errors.dueDate && touched.dueDate ? 'error' : ''
                        }`}
                        onChange={(e) => {
                          const date = moment(e).startOf('day').toDate();
                          setFieldValue('dueDate', date);
                        }}
                        inputProps={{
                          placeholder:
                            languageState.translation.INVOICE.PUBLIC_INVOICE
                              .PAYMENT_DUE_DATE,
                          name: 'dueDate',
                        }}
                        dateFormat="DD-MM-YYYY"
                        closeOnSelect={true}
                        timeFormat={false}
                        utc={true}
                        value={
                          values.dueDate
                            ? moment(values.dueDate).format('DD-MM-YYYY')
                            : ''
                        }
                      />
                      <ErrorMessage
                        name="dueDate"
                        component="div"
                        className="error-message"
                      />
                    </div>

                    <div className="form-field d-none">
                    <Field
                        type="text"
                        name="currency"
                        placeholder={
                          languageState.translation.INVOICE.PUBLIC_INVOICE
                            .SELECT_CURRENCY
                        }
                        className={
                          errors.currency && touched.currency ? 'error' : ''
                        }
                        value={`${
                          languageState.translation.INVOICE.PUBLIC_INVOICE
                            .SELECT_CURRENCY
                        }: ${values.currency}`}
                        disabled={true}
                      />
                  
                      <ErrorMessage
                        name="currency"
                        component="div"
                        className="error-message"
                      />
                    </div>

                    <div className="form-field">
                      <Field
                        type="number"
                        name="amount"
                        placeholder={
                          languageState.translation.INVOICE.PUBLIC_INVOICE
                            .AMOUNT
                        }
                        className={
                          errors.amount && touched.amount ? 'error' : ''
                        }
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="d-flex gap-1">
                      <Field
                        className="form-checkbox"
                        type="checkbox"
                        name="subscribeEmail"
                        checked={subscribeEmail}
                        onChange={() => {
                          setFieldValue('subscribeEmail', !subscribeEmail);
                          setSubscribeEmail(!subscribeEmail);
                        }}
                      />
                      <label>
                        {
                          languageState.translation.INVOICE.PUBLIC_INVOICE
                            .SUBSCRIBE_EMAIL
                        }
                      </label>
                    </div>

                    {subscribeEmail && (
                      <div className="form-field">
                        <Field
                          type="email"
                          name="email"
                          placeholder={
                            languageState.translation.INVOICE.PUBLIC_INVOICE
                              .EMAIL
                          }
                          className={
                            errors.email && touched.email ? 'error' : ''
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className="form-actions">
                  <button
                    type="reset"
                    className="reset-btn"
                    onClick={() => {
                      resetForm({ values: initialValues });
                      setUploadedFile(null);
                      setSubscribeEmail(false);
                    }}
                  >
                    {
                      languageState.translation.INVOICE.PUBLIC_INVOICE
                        .RESET_DEFAULT
                    }
                  </button>
                  <button type="submit" className="submit-btn">
                    {
                      languageState.translation.INVOICE.PUBLIC_INVOICE
                        .SUBMIT_INVOICE
                    }
                  </button>
                </div>

                <div className="share-section">
                  <button
                    type="button"
                    className="share-btn"
                    onClick={handleShare}
                  >
                    {showCopied ? (
                      <>
                        <span className="text-success">
                          {
                            languageState.translation.INVOICE.PUBLIC_INVOICE
                              .COPIED
                          }
                        </span>
                        <i className="fa fa-check check-icon animated text-success"></i>
                      </>
                    ) : (
                      <>
                        <i className="fa fa-copy share-icon"></i>
                        <span className="text-danger">
                          {
                            languageState.translation.INVOICE.PUBLIC_INVOICE
                              .SHARE_FRIENDS
                          }
                        </span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};
