import ProfileDetails from 'pages/connect/inner-pages/profile-details';
import APP_ROUTES from './app.routes';
import Jobboard from 'pages/jobs/jobboard';
import JobDetails from 'pages/jobs/job-details/JobDetails';
import AddEditMission from 'pages/recruiter/Addmission/Addmission.jsx';
import { LateInvoiceDetection } from 'pages/invoice/LateInvoiceDetection/lateInvoiceDetection';

const publicRoutes = [
  {
    collapse: false,
    path: APP_ROUTES.PUBLIC_ROUTE.JOBS.BOARD,
    name: 'FREELANCE_JOBS',
    layout: '/public',
    component: Jobboard,
    icon: 'ni ni-briefcase-24 text-blue',
  },
  {
    collapse: false,
    path: APP_ROUTES.PUBLIC_ROUTE.JOBS.DETAILS + '/:id',
    name: 'JOB_DETAILS',
    layout: '/public',
    component: JobDetails,
    icon: 'ni ni-single-copy-04 text-blue',
  },
  {
    collapse: false,
    path: APP_ROUTES.PUBLIC_ROUTE.PROFILE.PROFILE_DETAILS + '/:id',
    name: 'PROFILE_DETAILS',
    layout: '/public',
    component: ProfileDetails,
    icon: 'ni ni-single-02 text-blue',
  },
  {
    collapse: false,
    path: APP_ROUTES.PUBLIC_ROUTE.MISSIONS.ADD_MISSION,
    name: 'ADD_MISSION',
    layout: '/public',
    component: AddEditMission,
    icon: 'ni ni-shop text-red',
    hide: false,
  },
  {
    collapse: false,
    path: APP_ROUTES.PUBLIC_ROUTE.INVOICES.LATE_INVOICE_DETECTION,
    name: 'LATE_INVOICE_DETECTION',
    layout: '/public',
    component: LateInvoiceDetection,
    icon: 'ni ni-money-coins text-red',
    hide: false,
  },
];
export default publicRoutes;
