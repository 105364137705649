import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import routes, { innerRoutesAdmin, innerRoutesCompany, innerRoutesFreelancer, innerRoutesRecruiter } from '../../navigation/routes';
import AdminNavbar from './navbar/admin-navbar';
import AdminFooter from './footer/admin-footer';
import Sidebar from './sidebar/sidebar';
import APP_ROUTES from 'navigation/app.routes';
import useFocusService from 'services/focus/focus.service';
import { AppContext } from 'store/app.context';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import StepperModal from 'components/modalStepper/modal-stepper';
import useUserService from 'services/profile/user.service';
import PerrmissionProvider from 'config/core/PerrmissionProvider';
import { isAuthorized } from 'utils';
import { DEFAULT_PRIVATE_PATH, MODULES } from 'config/core';
import { NewsModal } from 'components/NewsModal/NewsModal';
import HOME_STATE from 'store/home/home.state';

const Admin = () => {
  const mainContentRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const [routesByRole, setRoutesByRole] = useState(null);
  const [showNewsModal, setShowNewsModal] = useState(false);
  const { getUserInfo, getSubscriptionsByUser } = useUserService();
  const { missionState, sharedState, userState, homeState, dispatchHomeState } = useContext(AppContext);
  const { getProjects } = useFocusService();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [document]);

  useEffect(() => {
    if (!isAuthorized()) {
      return;
    }
    initiateSidenav()
    getProjects();
    // getMisions();
    getUserInfo();
    getRoutesByUserType();
  }, [routes]);


  const getRoutesByUserType = async () => {
    let { modules } = await getSubscriptionsByUser();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (!modules.includes("HOME")) {
      modules = ["HOME", "LATE_INVOICE_DETECTION", ...modules]; // Any user have to has access to home page
    }
    // TODO: keep logs => will be removed after testing all login types 
    if (userInfo?.kind === "ADMIN") {
      modules = [
        "HOME",
        "CHAT",
        "JOBS",
        "CONTACT",
        "TOOLS",
        "CONNECT",
        "MISSIONS",
        "YOUR_NETWORK",
        "GROW",
        "FOCUS",
        "IMPORT_FREELANCERS",
      ]
    }
    console.log(userInfo?.kind, "==>", modules)
    if (userInfo && routes.length) {
      const filteredRoutes = routes.filter(
        (route) => route.role.includes(userInfo?.kind) && modules.includes(route.name)
      );
      setRoutesByRole(filteredRoutes);
    }
  }

  useEffect(() => {
    routes.forEach((item) => {
      if (missionState.missions && item.name == 'FOCUS') {
        item.views = missionState.missions;
      }
    });
  }, [routes, missionState]);

  useEffect(() => {
    if (location.pathname.includes('profile') && !isAuthorized()) {
      history.push(
        `/public${APP_ROUTES.PUBLIC_ROUTE.PROFILE.PROFILE_DETAILS
        }${location.pathname.slice(
          location.pathname.lastIndexOf('/'),
          location.pathname.length
        )}`
      );
    }
  }, [location]);

  
  useEffect(() => {
    const hasSeenWhatsNew = localStorage.getItem("hasSeenWhatsNew") || false;
    if (!hasSeenWhatsNew && !homeState.isNewsModalOpen && userState?.userInfo?.kind === "FREELANCER") {
      setTimeout(() => {
        dispatchHomeState({ type: HOME_STATE.types.OPEN_NEWS_MODAL })
        localStorage.setItem("hasSeenWhatsNew", "true");
      }, 1400)
    }
  }, [])


  const getRoutes = (routes) =>
    routes && routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/private') {
        if (sharedState.currentModule == MODULES.MISSION_OVERVIEW)
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
              exact={true}
            />
          );
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact={prop.exact ? true : false}
          />
        );
      }
      return null;
    });

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = () => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };

  // toggles collapse between mini sidenav and normal
  const initiateSidenav = () => {
    if (document.body.classList.contains('g-sidenav-hidden')) {
      document.body.classList.add('g-sidenav-show');
      document.body.classList.remove('g-sidenav-hidden');
      document.body.classList.add('g-sidenav-pinned');
    }
  };
  const getNavbarTheme = () =>
    location.pathname.indexOf('admin/alternative-dashboard') === -1
      ? 'dark'
      : 'light';

  return (
    <PerrmissionProvider>
      {
        /* hide this as of breadcrumbs are ready */
        <Sidebar
          routesByRole={routesByRole}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          logo={{
            innerLink: '/',
            imgSrc: require('../../assets/img/brand/ee_logo_whitebg.png')
              .default,
            imgAlt: 'ExtraExpertise.',
          }}

        />
      }
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        {routesByRole && (
          <Switch>
            {getRoutes(routesByRole)}
            {getRoutes(
              userState.userInfo?.kind === "FREELANCER"
                ? innerRoutesFreelancer
                : userState.userInfo?.kind === "RECRUITER"
                  ? innerRoutesRecruiter
                  : userState.userInfo?.kind === "COMPANY"
                    ? innerRoutesCompany
                    : innerRoutesAdmin
            )}
            <Route
              exact
              path="/private/linkedin"
              component={LinkedInCallback}
            />
              <Redirect from="*" to={`/private${APP_ROUTES.HOME}`} />
          </Switch>
        )}
        <AdminFooter />
      </div>
      {(userState.userInfo && userState.userInfo.kind === "FREELANCER") && <StepperModal userInfo={userState.userInfo} />}
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}

      <NewsModal
        modalShow={homeState.isNewsModalOpen}
        closeModal={() => dispatchHomeState({ type: HOME_STATE.types.CLOSE_NEWS_MODAL })} />
    </PerrmissionProvider>

  );
};
export default Admin;