import React, { useEffect } from 'react';

const GetLaunchList = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://getlaunchlist.com/js/widget.js';
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="getlaunchlist-widget">
      <div className="launchlist-widget" data-key-id="7zkh6c" data-height="200px"></div>
    </div>
  );
};

export default GetLaunchList;
