import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Login from 'pages/auth/login';
import DashboardLayout from 'components/layout/dashboard.layout';
import Register from 'pages/auth/register';
import Loader from 'components/loader';
import { ToastContainer } from 'react-toastify';
import { isAuthorized } from 'utils';
import ForgotPassword from 'pages/auth/forgot-password/index';
import UpdatePassword from 'pages/auth/update-password';
import useAuthService from 'services/auth/auth.service';
import { AppContext } from 'store/app.context';
import TagManager from 'react-gtm-module';
import WebSocketProvider from 'services/socket';
import ChatProvider from 'services/chat-hook';
import LANGUAGE_STATE from 'store/language';
import ModalError from 'components/modalerror/ModalError';
import PublicDashboard from 'components/layout/PublicDashboard';
import APP_ROUTES from 'navigation/app.routes';
import RegisterByInvitation from 'pages/auth/register/RegisterByInvitation';
import './App.css';
import './expertiseTheme.css';

const tagManagerArgs = {
  gtmId: 'GTM-W5Z7RH4',
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  const { autoLogin } = useAuthService();
  const { dispatchChangeLanguage } = useContext(AppContext);
  useEffect(() => {
    if (isAuthorized()) {
      autoLogin();
    }
    const langauge = localStorage.getItem('language');
    if (langauge) {
      dispatchChangeLanguage({
        language: langauge,
        type: LANGUAGE_STATE.types.CHANGE_LANGUAGE,
      });
    }
  }, []);
  const CheckIfAuthorized = () => {
    if (isAuthorized() || window.location.pathname.includes(APP_ROUTES.CONNECT.PROFILE_DETAILS)) {
      return true
    } else {
      false
    }
  }
  return (
    <>
      <WebSocketProvider>
        <ChatProvider>
          <Loader />
          <BrowserRouter>
            <Switch>
              <Route
                path="/private"
                render={(props) =>
                  CheckIfAuthorized() ? (
                    <DashboardLayout {...props} />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                path="/register/step-one"
                exact
                render={(props) =>
                  !isAuthorized() ? (
                    <Register {...props} />
                  ) : (
                    <Redirect to="/private/home" />
                  )
                }
              />
              <Route
                path="/public"
                render={() =>
                  <PublicDashboard />

                }
              />
              <Route
                path="/register/invited-user/:email"
                render={(props) =>
                  !isAuthorized() ? (
                    <RegisterByInvitation {...props} />
                  ) : (
                    <Redirect to="/private/home" />
                  )
                }
              />
              <Route
                path="/register/:userType"
                render={(props) =>
                  !isAuthorized() ? (
                    <Register {...props} />
                  ) : (
                    <Redirect to="/private/home" />
                  )
                }
              />

              <Route
                path="/login"
                render={(props) =>
                  !isAuthorized() ? (
                    <Login {...props} />
                  ) : (
                    <Redirect to="/private/home" />
                  )
                }
              />
              <Route
                path="/forgot-password"
                render={(props) =>
                  !isAuthorized() ? (
                    <ForgotPassword {...props} />
                  ) : (
                    <Redirect to="/private/home" />
                  )
                }
              />
              <Route
                path="/reset-password"
                render={(props) =>
                  !isAuthorized() ? (
                    <UpdatePassword {...props} />
                  ) : (
                    <Redirect to="/private/home" />
                  )
                }
              />
            
              <Route path="/" render={() => <Redirect to="/login" />} />
              {/* <Route path="" render={(props) => <App {...props} />} /> */}
              <Redirect from="*" to="/private" />
            </Switch>
          </BrowserRouter>
          <ToastContainer />
        </ChatProvider>
      </WebSocketProvider>
      <ModalError />
    </>
  );
};
export default App;
