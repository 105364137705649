import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ModalBody, Input, Button, ModalFooter, Container, CustomInput, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { AppContext } from 'store/app.context';
import STEPPER_STATE from 'store/stepper';
import { firstStepperScreenSchema } from './schema';
import useUserService from 'services/profile/user.service';
import { professions, statuses, workPreferations, workTypes } from './onboarding.constant';
import countries from 'i18n-iso-countries';
import Select from 'react-select';
import en from 'i18n-iso-countries/langs/en.json';
import useSettingsService from 'services/settings/settings.service';
import LoaderPage from 'components/LoaderPage/LoaderPage';

countries.registerLocale(en);

const FirstStepperScreen = ({ timeRemaining }) => {
  const { dispatchStepperState, languageState, skillsState, userState } = useContext(AppContext);
  const { autoUpdateUserInfo, updateBasicInfos } = useUserService();
  const { getSkills, getTimeZones, addSkill } = useSettingsService();
  const [skills, setSkills] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [defaultTimeZone, setDefaultTimeZone] = useState(null);
  const [isLoading, setIsLoading] = useState(true)

  const translatedCountries = countries.getNames(languageState?.defaultLanguage);
  const EnglishCountries = countries.getNames("EN")

  const englishCountryOption = Object.entries(EnglishCountries).map(([code, name]) => ({
    value: code,
    label: name
  }));
  const countryOptions = Object.entries(translatedCountries).map(([code, name]) => ({
    value: code,
    label: name
  }));

  useEffect(() => {
    getSkills();
    getAllTimeZones()
  }, []);

  useEffect(() => {
    if (skillsState && skillsState.skills) {
      const newOptions = skillsState.skills.map(skill => ({
        value: skill.id,
        label: skill.key
      }));
      setSkills(newOptions);
    }
  }, [skillsState]);

  const getAllTimeZones = async () => {
    setIsLoading(true)
    const res = await getTimeZones();
    const data = (res || []).map((el) => {
      return {
        value: el.id,
        label: `${el.name}: ${el.description} (${el.utcOffset})`
      }
    })
    const timeZoneByDefault = (data || []).find(el => el.label.includes('Brussels'))
    setTimeZones(data)
    setDefaultTimeZone(timeZoneByDefault)
    setIsLoading(false)
  }

  const handleFirstStepperScreenSubmit = async (values) => {
    let englishCountry =  englishCountryOption.find((country) => values.region === country.value)
    const dataToSend = {
      ...values,
      currency: "EUR",
      skills: values.skills.filter((el) => !el.isOther).map(el => el.value),
      region: englishCountry?.value ? englishCountry?.value : "",
      country: englishCountry?.label ? englishCountry?.label : "",
      countryCode: englishCountry?.value ? englishCountry?.value : "",
       }
       
    const result = await updateBasicInfos(dataToSend);
    if (result && result.status === 200) {
      autoUpdateUserInfo();
    }
    dispatchStepperState({
      type: STEPPER_STATE.types.CHANGE_CURRENT_STEPPER_STATE,
      currentStepPayload: 1,
    });
  };

  if (isLoading) {
    return <LoaderPage />
  }

  return <Formik
    initialValues={{
      shouldNotShowDailyRate: false,
      profession: userState?.userInfo?.profession || undefined,
      statusAviability: userState?.userInfo?.statusAviability || undefined,
      workType: userState?.userInfo?.workType || undefined,
      experienceYears: userState?.userInfo?.experienceYears || null,
      amount: userState?.userInfo?.amount || undefined,
      aboutMe: userState?.userInfo?.aboutMe || undefined,
      paymentType: userState?.userInfo?.paymentType || undefined,
      region: userState?.userInfo?.region || "BE",
      city: userState?.userInfo?.city || undefined,
      timeZone: userState?.userInfo?.timeZone || defaultTimeZone?.value,
      linkedinProfile: userState?.userInfo?.linkedinProfile || undefined,
      profileWebsite: userState?.userInfo?.profileWebsite || undefined,
      skills: [
        ...(userState?.userInfo?.skills || []).map(skill => ({
          value: skill.id,
          label: skill.key,
          isOther: false
        }))
      ]
    }}
    onSubmit={(values) => {
      handleFirstStepperScreenSubmit(values);
    }}
    validationSchema={firstStepperScreenSchema(
      languageState.translation.FORM_VALIDATION
    )}
  >
    {({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => {
      return (
        <Form className='first-step-onboarding'>
          <ModalBody className='pt-0'>
            <div className='font-weight-bolder'>{languageState.translation.STEPPER.BASIC_INFO_SECTION_1}</div>
            <Container className='mb-2 d-flex flex-wrap align-items-center gap-2'>
              <div className='my-2 mr-1'>
                {languageState.translation.STEPPER.I_AM}
              </div>
              <div className='my-2' style={{ flex: 1 }}>
                <ReactSelect
                  className={`w-100 ${errors.profession && touched.profession ? "error-input" : ""}`}
                  placeholder={languageState.translation.STEPPER.FIRST_STEP_PLACEHOLDERS.PROFESSION}
                  options={professions.map((el) => {
                    return {
                      label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[el],
                      value: el,
                    }
                  })}
                  name="profession"
                  value={values.profession ? {
                    label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[values.profession],
                    value: values.profession
                  } : null}
                  onChange={({ value }) => {
                    setFieldValue('profession', value);
                  }}
                />
              </div>
              <div className='my-2 mr-1'>
                {languageState.translation.STEPPER.WITH}
              </div>
              <div className='my-2'>
                <Input
                  type="number"
                  style={{ width: 50 }}
                  min={0}
                  placeholder="10"
                  name="experienceYears"
                  onChange={handleChange}
                  value={values.experienceYears}
                  className={`w-20 ${errors.experienceYears && touched.experienceYears ? "error-input" : ""}`}
                />
              </div>
              <div className='my-2 mr-1'>
                {languageState.translation.STEPPER.YEARS_AND_LIKE_TO_WORK}
              </div>
              <div className='my-2' style={{ flex: 1 }}>
                <ReactSelect
                  className={`w-100 ${errors.workType && touched.workType ? "error-input" : ""}`}
                  placeholder={languageState.translation.STEPPER.FIRST_STEP_PLACEHOLDERS.WORK_TYPE}
                  options={workTypes.map((el) => {
                    return {
                      label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[el],
                      value: el,
                    }
                  })}
                  name="workType"
                  value={values.workType ? {
                    label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[values.workType],
                    value: values.workType
                  } : null}
                  onChange={({ value }) => {
                    setFieldValue('workType', value);
                  }}
                />
              </div>
            </Container>
            <Container className='mb-2 d-flex flex-wrap align-items-center gap-2'>
              {
                values.shouldNotShowDailyRate
                  ? null
                  : <>
                    <div className='my-2 mr-1'>
                      {languageState.translation.STEPPER.MY}
                    </div>
                    <div className='my-2' style={{ flex: 1 }}>
                      <ReactSelect
                        className={`w-100 ${errors.paymentType && touched.paymentType ? "error-input" : ""}`}
                        placeholder={languageState.translation.STEPPER.FIRST_STEP_PLACEHOLDERS.WORK_PREFERENCE}
                        options={workPreferations.map((el) => {
                          return {
                            label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[el],
                            value: el,
                          }
                        })}
                        name="paymentType"
                        value={values.paymentType ? {
                          label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[values.paymentType],
                          value: values.paymentType
                        } : null}
                        onChange={({ value }) => {
                          setFieldValue('paymentType', value);
                        }}
                      />
                    </div>
                    <div className='my-2 mr-1'>
                      {languageState.translation.STEPPER.RATE}
                    </div>
                    <div className='my-2' style={{ flex: 1 }}>
                      <InputGroup>
                        <Input
                          className={`w-100 ${errors.amount && touched.amount ? "error-input" : ""}`}
                          placeholder={languageState.translation.STEPPER.FIRST_STEP_PLACEHOLDERS.X_RATE}
                          type="number"
                          min={0}
                          name="amount"
                          value={values.amount}
                          onChange={handleChange}
                        />
                        <InputGroupAddon addonType='append'>
                          <InputGroupText className='h-40'>
                            {userState && userState.userInfo && userState.userInfo.currency ? userState.userInfo.currency : 'Eur'}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </div>
                  </>
              }
              <div className='my-2 mr-1'>
                {languageState.translation.STEPPER.CURRENTLY}
              </div>
              <div className='my-2' style={{ flex: 1 }}>
                <ReactSelect
                  className={`w-100 ${errors.statusAviability && touched.statusAviability ? "error-input" : ""}`}
                  placeholder={languageState.translation.STEPPER.FIRST_STEP_PLACEHOLDERS.AVAIBLITY}
                  options={statuses.map((el) => {
                    return {
                      label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[el],
                      value: el,
                    }
                  })}
                  name="statusAviability"
                  value={values.statusAviability ? {
                    label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[values.statusAviability],
                    value: values.statusAviability
                  } : null}
                  onChange={({ value }) => {
                    setFieldValue('statusAviability', value);
                  }}
                />
              </div>
              {/*            <div className='my-2'>
                <CustomInput
                  name="shouldNotShowDailyRate"
                  id='shouldNotShowDailyRate'
                  type='checkbox'
                  className='custom-control-Primary mb-0 d-flex align-items-center'
                  label={languageState.translation.STEPPER.HIDDEN_RATE}
                  onChange={(e) => {
                    setFieldValue('shouldNotShowDailyRate', e.target.checked);
                    setFieldValue('amount', undefined);
                    setFieldValue('paymentType', undefined);
                  }}
                />
              </div> */}
            </Container>
            <Container className='margin-md-checkbox mb-2 d-flex flex-wrap align-items-center gap-2'>
              <div className='my-2 mr-1'>
                {languageState.translation.COMMON.HELP_IDENTIFY_SKILLS}
              </div>
              <div className='my-2' style={{ flex: 1 }}>
                <CreatableSelect
                  name="skills"
                  noOptionsMessage={() => languageState.translation.COMMON.SKILLS_SELECT_EMPTY}
                  value={values.skills || []}
                  options={skills}
                  isMulti
                  isClearable
                  isSearchable
                  className={`w-100 ${errors.skills && touched.skills ? "error-input" : ""}`}
                  placeholder={languageState.translation.COMMON.SEARCH_TAG}
                  onChange={(e) => {
                    const userSkills = (e || []).map((el) => el)
                    setFieldValue("skills", userSkills)
                  }}
                  onCreateOption={async (newSkill) => {
                    const response = await addSkill({ key: newSkill });
                    if (response && response.data) {
                      const newSkillId = response.data.id;
                      const updatedSkills = [
                        ...values.skills,
                        { value: newSkillId, label: newSkill },
                      ];
                      setSkills([...skills, { value: newSkillId, label: newSkill },
                      ])
                      setFieldValue("skills", updatedSkills)
                    }
                  }
                  }
                />
              </div>
            </Container>
            <Container className='mb-2 d-flex flex-wrap align-items-center gap-2 ' >
              <div className='my-2 mr-1'>
                {languageState.translation.STEPPER.BASIC_INFO_DETAILS}
              </div>
              <div className='my-2' style={{ flex: 1 }}>
                <Input
                  className={`w-100 ${errors.aboutMe && touched.aboutMe ? "error-input" : ""}`}
                  name="aboutMe"
                  placeholder={languageState.translation.STEPPER.BASIC_INFO_DETAILS_PLACEHOLDER}
                  onChange={handleChange}
                />
              </div>
            </Container>

            <div className='mt-4 font-weight-bolder'>{languageState.translation.STEPPER.BASIC_INFO_SECTION_2}</div>
            <Container className='mb-2 d-flex flex-wrap align-items-center gap-2'>
              <div className='my-2 mr-1'>
                {languageState.translation.STEPPER.MY_TIMEZONE}
              </div>
              <div className='my-2 ml-1' style={{ flex: 1 }}>
                <ReactSelect
                  className="w-100"
                  placeholder={languageState.translation.STEPPER.FIRST_STEP_PLACEHOLDERS.SELECT_TIME_ZONE}
                  options={timeZones || []}
                  value={values.timeZone
                    ? (timeZones || []).find((el) => el.value === values.timeZone)
                    : null
                  }
                  name="timeZone"
                  onChange={({ value }) => {
                    setFieldValue('timeZone', value);
                  }}
                />
              </div>
              <div className='my-2 mr-1'>
                {languageState.translation.STEPPER.AND_LIVE_IN}
              </div>
              <div className='my-2' style={{ flex: 1 }}>
                <Select
                  options={countryOptions}
                  value={countryOptions.find((el) => el.value === values.region)}
                  placeholder={languageState.translation.PROFILE.COUNTRY}
                  menuPlacement='top'
                  onChange={(e) => setFieldValue('region', e.value)}
                />
              </div>
              <div className='my-2 mr-1'>
                {languageState.translation.STEPPER.IN}
              </div>
              <div className='my-2' style={{ flex: 1 }}>
                <Input
                  className="w-100"
                  placeholder={languageState.translation.COMMON.CITY}
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                />
              </div>
            </Container>
            <Container className='mb-2 d-flex flex-wrap align-items-center gap-2'>
              <div className='my-2 mr-1'>
                {languageState.translation.STEPPER.YOU_CAN_FIND_ME_ONLINE}
              </div>
              <div className='my-2' style={{ flex: 1 }}>
                <Input
                  className={`w-100 ${errors.linkedinProfile && touched.linkedinProfile ? "error-input" : ""}`}
                  name="linkedinProfile"
                  placeholder={languageState.translation.STEPPER.LINK_TO_YOUR_LINKEDIN_PROFILE}
                  onChange={handleChange}
                />
                <small className="text-red">
                  {errors && errors.linkedinProfile && touched.linkedinProfile && errors.linkedinProfile}
                </small>
              </div>
              <div className='my-2 mr-1'>
                {languageState.translation.STEPPER.OR_VIA}
              </div>
              <div className='my-2' style={{ flex: 1 }}>
                <Input
                  className={`w-100 ${errors.profileWebsite && touched.profileWebsite ? "error-input" : ""}`}
                  name="profileWebsite"
                  placeholder={languageState.translation.STEPPER.LINK_TO_YOUR_WEBSITE_PORTFOLIO}
                  onChange={handleChange}
                />
                <small className="text-red">
                  {errors && errors.profileWebsite && touched.profileWebsite && errors.profileWebsite}
                </small>
              </div>
            </Container>
          </ModalBody>
          <ModalFooter className='d-flex justify-content-end'>

            <Button
              className="btn-next-link"
              color="link"
              onClick={() => {
                // handle Submit form
                handleSubmit();
              }}
            >
              {languageState.translation.COMMON.STARTONBOARDING}
            </Button>
          </ModalFooter>

        </Form>
      );
    }}
  </Formik>

};

export default FirstStepperScreen
