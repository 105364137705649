import React, { useContext, useEffect } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import { Button, Card, CardBody, FormGroup, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from 'reactstrap';
import USER_STATE from 'store/user';
import AuthHeader from 'components/layout/header/auth-header';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'store/app.context';
import { Formik, Field } from 'formik';
import schema from './schema';
import useAuthService from 'services/auth/auth.service';
import { toast } from 'react-toastify';
import ModalSendMail from 'components/modalSendMail/modalSendMail';
import LANGUAGE_STATE from 'store/language';

const Login = () => {
  const { search, location } = useLocation();
  const history = useHistory();
  const { languageState, dispatchUserState, dispatchChangeLanguage, homeState } = useContext(AppContext);
  const { signin } = useAuthService();
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const params = new URLSearchParams(search);

  useEffect(() => {
    if (params && params.get('verify')) {
      if (params.get('verify') === 'error') {
        toast.error(languageState.translation.AUTH.CONFIRMATION.FAILURE, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success(languageState.translation.AUTH.CONFIRMATION.SUCCESS, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, []);

  const initialValues = {
    email: history?.location?.state?.email ? history?.location?.state?.email :'',
    password: '',
  };

  const onChangeRememberMe = (event) => {
    setRememberMe(!rememberMe);
  };

  const submitForm = async (values) => {
    // if (rememberMe) {
    //   Cookies.set('username', values.email);
    //   const encryptedPassword = CryptoJS.AES.encrypt(values.password, 'secret key').toString();
    //   Cookies.set('password', encryptedPassword);
    // }
    const requestData = {
      email: values.email,
      password: values.password,
      remember_me: rememberMe,
    };
    const result = await signin(requestData);
    if (result && result.status === 201) {
      if (result.data.user.language) {
        dispatchChangeLanguage({
          language: result.data.user.language,
          type: LANGUAGE_STATE.types.CHANGE_LANGUAGE,
        });
      }
      toast.success(languageState.translation.ALERT.SUCCESS_LOGIN, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatchUserState({
        type: USER_STATE.types.SET_USER_INFO,
        userInfo: result.data.user,
        token: result.data.token,
        chatToken: result.data?.chatToken || '',
      });
      history.push('/private/home');
    }
  };
  const handleKeyDown = (e, handleSubmit) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="auth-container bg-white">
      <AuthHeader title={languageState.translation.AUTH.SIGNIN.HEADER} />

      <Container className="mt--8 pb-5">
        <Formik
          initialValues={initialValues}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={(values) => submitForm(values)}
        >
          {({ errors, touched, values, handleSubmit }) => (
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  {/* <CardHeader className="bg-transparent pb-5">
                    <div className="text-muted text-center mt-2 mb-3">
                      <small>
                        {languageState.translation.AUTH.SIGNIN.SIGNIN_WITH}
                      </small>
                    </div>
                    <div className="btn-wrapper text-center">
                      <Button
                        className="btn-neutral btn-icon"
                        color="default"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <span className="btn-inner--icon mr-1">
                          <img
                            alt="..."
                            src={
                              require('assets/img/icons/common/github.svg')
                                .default
                            }
                          />
                        </span>
                        <span className="btn-inner--text">Github</span>
                      </Button>
                      <Button
                        className="btn-neutral btn-icon"
                        color="default"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <span className="btn-inner--icon mr-1">
                          <img
                            alt="..."
                            src={
                              require('assets/img/icons/common/google.svg')
                                .default
                            }
                          />
                        </span>
                        <span className="btn-inner--text">Google</span>
                      </Button>
                    </div>
                  </CardHeader> */}
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4 text-dark">
                      <small>
                        {
                          languageState.translation.AUTH.SIGNIN
                            .SIGNIN_CREDENTIALS
                        }
                      </small>
                    </div>
                    <FormGroup
                      className={classnames('mb-3', {
                        focused: focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          className="form-control"
                          placeholder={languageState.translation.COMMON.EMAIL}
                          name="email"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(true)}
                        />
                      </InputGroup>
                      {errors.email && touched.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          className="form-control"
                          placeholder={
                            languageState.translation.COMMON.PASSWORD
                          }
                          type="password"
                          name="password"
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(true)}
                          onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
                        />
                      </InputGroup>
                      {errors.password && touched.password && (
                        <div className="invalid-feedback">
                          {errors.password}
                        </div>
                      )}
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                        onChange={(e) => onChangeRememberMe(e)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin"
                      >
                        <span className="text-muted">
                          {languageState.translation.AUTH.SIGNIN.REMEMBER_ME}
                        </span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button
                        className="my-4 btn-exprimary"
                        style={{
                          backgroundColor: '#FA5252',
                        }}
                        onClick={() => handleSubmit()}
                        type="button"
                      >
                        {languageState.translation.AUTH.SIGNIN.LOGIN_SUBMIT}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
                <Row className="mt-3">
                  <Col xs="6">
                    <Link
                      className="text-dark"
                      to="/forgot-password"
                      onClick={(e) => history.push('/forgot-password')}
                    >
                      <small>
                        {languageState.translation.AUTH.SIGNIN.FORGOT_PASSWORD}?
                      </small>
                    </Link>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Link
                      className="text-dark"
                      to={`/register/step-one${params && params.get('subscription_type') ? `?subscription_type=${params.get('subscription_type')}` : (homeState.publicUser ? `?subscription_type=COMPANY` : '')}`}
                      >
                      <small>
                        {languageState.translation.AUTH.SIGNIN.CREATE_NEW_ACCOUNT}
                      </small>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Formik>
      </Container>
      <ModalSendMail />
    </div>
  );
};

export default Login;
