import React, { useContext, useEffect, useMemo, useState } from 'react';
// reactstrap components
import {
  Card,
  Table,
  Row,
  Col,
  CardBody,
  CardText,
  CardTitle,
  Progress,
  Container,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
// core components
import { AppContext } from 'store/app.context';
import ModalMainInfo from 'components/modalmaininfo/ModalMainInfo';
import CardsFocus from 'components/cards/card-focus';
import useHomeService from 'services/home/home.service';
import moment from 'moment';
import APP_ROUTES from 'navigation/app.routes';
import { useHistory } from 'react-router-dom';
import './index.scss';
import useJobsService from 'services/jobs/jobs.service';
import { getQueryParamsFromObject } from 'utils';
import { DEFAULT_PRIVATE_PATH } from 'config/core';
import { MatchingJobsCard } from 'components/cards/card-matchingJobs';
import useUserService from 'services/profile/user.service';
import useDashboardService from 'services/dashboard/dashboard.service';
import ModalAddLink from 'components/modaladdlink/ModalAddLink';
import CardNotifications from 'components/cards/card-notification';
import { API_BASE_URL } from 'config/core';
import GetLaunchList from 'components/GetLaunchList/GetLaunchList';

const Dashboard = () => {
  const history = useHistory();
  moment.locale('en');
  const {
    languageState,
    homeState,
    userState,
    dashboardState,
    notificationsState,
  } = useContext(AppContext);
  const { getQuickLinks } = useHomeService();
  const { getJobsSaved } = useJobsService();
  const { getSubscriptionsByUser, updateBasicInfos } = useUserService();
  const { getDashBoardStats } = useDashboardService();
  const [modalInfoShow, setModalInfoShow] = useState(false);
  const [mainInfo] = useState('');
  const projectTable = React.useRef(null);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [membershipState, setMembershipState] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [timeLeft, setTimeLeft] = useState('');

  moment.locale(languageState.defaultLanguage || 'nl');
  const cardData = useMemo(
    () => [
      {
        id: 'PROFILE',
        titleKey:
          languageState.translation.HOME.DASHBOARD.COMPLETE_YOUR_PROFILE,
        textKey: languageState.translation.HOME.DASHBOARD.COMPLETE_SUBTEXT,
        buttonKey: languageState.translation.HOME.DASHBOARD.COMPLETE_NOW,
        link: DEFAULT_PRIVATE_PATH + APP_ROUTES.SETTINGS,
      },
      {
        id: 'JOBS',
        titleKey:
          languageState.translation.HOME.DASHBOARD.FIND_YOUR_NEXT_PROJECT,
        textKey: languageState.translation.HOME.DASHBOARD.FIND_SUBTEXT,
        buttonKey: languageState.translation.HOME.DASHBOARD.GO_TO_JOBBOARD,
        link: '/private/jobs/jobboard',
      },
      {
        id: 'NETWORK',
        titleKey: languageState.translation.HOME.DASHBOARD.GROW_YOUR_NETWORK,
        textKey: languageState.translation.HOME.DASHBOARD.GROW_SUBTEXT,
        buttonKey: languageState.translation.HOME.DASHBOARD.DISPLAY_MEMBERS,
        link: '/private/connect/network',
      },
    ],
    [languageState]
  );

  /*const extraData = [
    {
      title: languageState.translation.HOME.DASHBOARD.ANNOUNCEMENTS,
      description: languageState.translation.HOME.DASHBOARD.ANNOUNCEMENTS_SUBTEXT,
      imagePath: "/dashboard/trustAndSafety.png",
      url: "https://extraexpertise.be/landing/shop/"
    },
    {
      title: languageState.translation.HOME.DASHBOARD.TRUST_SAFETY,
      description: languageState.translation.HOME.DASHBOARD.TRUST_SAFETY_SUBTEXT,
      imagePath: "/dashboard/anouncement.png",
      url: "https://extraexpertise.be/blog/"
    },
    {
      title: languageState.translation.HOME.DASHBOARD.MANAGE_YOUR_PROJECTS,
      description: languageState.translation.HOME.DASHBOARD.MANAGE_YOUR_PROJECT_SUBTEXT,
      imagePath: "/dashboard/bonus.png",
      url: "https://extraexpertise.be/landing/leden-voordeel/"
    },

  ];*/

  useEffect(() => {
    const getStats = async () => {
      await getDashBoardStats();
    };
    getStats();
  }, []);

  useEffect(() => {
    const savedSteps = userState.userInfo.completedSteps;
    if (savedSteps) {
      setCompletedSteps(savedSteps);
    }
  }, [completedSteps]);

  useEffect(() => {
    getQuickLinks();
    const geSubscription = async () => {
      const membership = await getSubscriptionsByUser();
      setMembershipState(membership);
    };
    geSubscription();
  }, []);

  const getGreeting = () => {
    const currentDate = new Date();
    const time = currentDate.getHours();
    if (time >= 12 && time < 18)
      return (
        languageState.translation.COMMON.GOOD_AFTERNOON +
        ' ' +
        userState?.userInfo?.firstName
      );
    if (time < 12 && time > 4)
      return (
        languageState.translation.COMMON.GOOD_MORNING +
        ' ' +
        userState?.userInfo?.firstName
      );
    return (
      languageState.translation.COMMON.GOOD_EVENING +
      ' ' +
      userState?.userInfo?.firstName
    );
  };

  useEffect(() => {
    const reqData = getQueryParamsFromObject({});
    getJobsSaved(reqData);
  }, []);

  const calculProgress = () => {
    return (completedSteps.length / cardData.length) * 100;
  };

  const handleButtonClick = (id, link) => {
    if (!completedSteps.includes(id)) {
      setCompletedSteps([...completedSteps, id]);
      setTimeout(() => {
        window.location.href = link;
      }, 300);
    }
    updateBasicInfos({ completedSteps: [...completedSteps, id] });
  };
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('nl-BE', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'NOT_STARTED':
        return {
          color: '#fb6340',
          backgroundColor: 'rgba(251,99,64,.12)',
          statusText: languageState.translation.COMMON.STATUS_LIST.NOT_STARTED,
          borderRadius: '0.25rem',
          textAlign: 'center',
        };
      case 'WAITING_FOR_APPROVAL':
        return {
          color: '#5e72e4',
          backgroundColor: 'rgba(94,114,228,.12)',
          borderRadius: '0.25rem',
          statusText:
            languageState.translation.COMMON.STATUS_LIST.WAITING_FOR_APPROVAL,
          textAlign: 'center',
        };
      case 'PUBLISHED':
        return {
          color: '#2dce89',
          backgroundColor: 'rgba(45,206,137,.12)',
          borderRadius: '0.25rem',
          statusText: languageState.translation.COMMON.STATUS_LIST.PUBLISHED,
          textAlign: 'center',
        };
      case 'CLOSED':
        return {
          color: '#f5365c',
          backgroundColor: 'rgba(245,54,92,.12)',
          borderRadius: '0.25rem',
          statusText: languageState.translation.COMMON.STATUS_LIST.CLOSED,
          textAlign: 'center',
        };
      default:
        return {
          color: '',
          backgroundColor: '#F8F9FA',
          borderRadius: '0.25rem',
          statusText: 'Unknown',
          textAlign: 'center',
        };
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const targetDate = moment(membershipState?.renewalDate * 1000);
      const now = moment();
      const duration = moment.duration(targetDate.diff(now));

      const days = duration.days();
      const hours = duration.hours();

      setTimeLeft(`${days} days, ${hours} hours left`);

      if (duration.asSeconds() <= 0) {
        clearInterval(interval); // Stop the interval when the countdown reaches 0
      }
    }, 1000);

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [membershipState]);

  return (
    <>
      <Container className="container-home p-4" fluid>
        <header className="mb-2 lg-12">
          <h2 className="text-black text-xl mb-2">{getGreeting()} 👋</h2>
        </header>
        <div className="">
          <div className="steps-container">
            <h2 className="text-muted mb-3 text-xl">
              {languageState.translation.HOME.DASHBOARD.YOUR_ROAD_TO_SUCCESS} .
              . .
            </h2>
            <div style={{ position: 'relative', width: '98%' }}>
              {!(completedSteps.length === 3) && (
                <>
                  {/* Progress Percentage on Top */}

                  <div
                    style={{
                      position: 'absolute',
                      top: '-20px',
                      left: `${calculProgress() + 1}%`,
                      transform: 'translateX(-50%)',
                      fontSize: '0.7rem',
                      fontWeight: '600',
                      color: `${
                        calculProgress() === 100
                          ? '#22bb33'
                          : calculProgress() > 60
                          ? '#5bc0de'
                          : '#f0ad4e'
                      }`,
                    }}
                  >
                    {Math.floor(calculProgress())}%
                  </div>
                  {/* Progress Bar */}
                  <Progress
                    max="100"
                    value={calculProgress()}
                    color={
                      calculProgress() === 100
                        ? 'success'
                        : calculProgress() > 60
                        ? 'info'
                        : 'warning'
                    }
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: '-20px',
                      right: 0,
                      fontSize: '0.7rem',
                      fontWeight: '600',
                      color: '#22bb33',
                    }}
                  >
                    100%
                  </div>
                </>
              )}
            </div>

            <Row className="cards-container">
              {cardData
                .filter((card) => !completedSteps.includes(card.id))
                .map((card, index) => (
                  <Col xs="12" sm="6" md="6" lg="4" key={index}>
                    <Card className="onboard-card">
                      <CardBody>
                        <CardTitle tag="h5" className="card-title">
                          {card.titleKey}
                        </CardTitle>
                        <CardText className="card-text">
                          {card.textKey}
                        </CardText>
                      </CardBody>
                      <button
                        className="button border-none"
                        onClick={() => {
                          handleButtonClick(card.id, card.link);
                        }}
                      >
                        {card.buttonKey}
                      </button>
                    </Card>
                  </Col>
                ))}
            </Row>
          </div>
          <Row className="cards-container">
            <Col xs="12" sm="6" md="6" lg="4" key="subscription">
              <Card className="status-card">
                <CardBody className="status-card-body">
                  <CardTitle tag="h5" className="status-card-title">
                    {languageState.translation.HOME.DASHBOARD.YOUR_MEMBERSHIP}
                  </CardTitle>
                  <div className="status-card-content">
                    {membershipState?.name === 'Basic' ? (
                      <>
                        <CardText className="status-info">
                          <span className="status-label">
                            {
                              languageState.translation.HOME.DASHBOARD
                                .MEMBERSHIP
                            }
                            :
                          </span>
                          <span className="status-value">
                            {membershipState?.name}
                          </span>
                        </CardText>
                        <a
                          href={
                            membershipState.invoiceLink ||
                            `${API_BASE_URL}payment/checkout-freelancer/${userState.userInfo.id}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="status-action-link"
                        >
                          {
                            languageState.translation.HOME.DASHBOARD
                              .UPGRADE_TO_PREMIUM
                          }
                        </a>
                      </>
                    ) : (
                      <>
                        <CardText className="status-info">
                          <span className="status-label">
                            {
                              languageState.translation.HOME.DASHBOARD
                                .YOUR_SUBSCRIPTION
                            }
                          </span>
                          <span className="status-value">
                            {membershipState?.name}
                          </span>
                        </CardText>
                        <CardText className="status-info">
                          <span className="status-label">
                            {
                              languageState.translation.HOME.DASHBOARD
                                .RENEWAL_DATE
                            }
                            :
                          </span>
                          <span className="status-value">{timeLeft}</span>
                        </CardText>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" sm="6" md="6" lg="4" key="application">
              <Card className="status-card">
                <CardBody className="status-card-body">
                  <CardTitle tag="h5" className="status-card-title">
                    {languageState.translation.SIDEBAR.APPLICATIONS}
                  </CardTitle>
                  <div className="status-card-content">
                    <CardText className="status-info">
                      <span className="status-label">
                        {languageState.translation.COMMON.TOTAL}:
                      </span>
                      <span className="status-value">
                        {dashboardState.dashBoardStats?.totalApplications || 0}
                      </span>
                    </CardText>
                    <CardText className="status-info">
                      <span className="status-label">
                        {languageState.translation.JOBBOARD.THIS_MONTH}:
                      </span>
                      <span className="status-value">
                        {dashboardState.dashBoardStats?.monthlyApplications ||
                          0}
                      </span>
                    </CardText>
                    <CardText className="status-info">
                      <span className="status-label">
                        {languageState.translation.JOBBOARD.THIS_WEEK}:
                      </span>
                      <span className="status-value">
                        {dashboardState.dashBoardStats?.weeklyApplications || 0}
                        {membershipState?.name === 'Basic' &&
                          dashboardState.dashBoardStats?.weeklyApplications >=
                            1 && (
                            <span className="limit-warning">
                              (
                              {
                                languageState.translation.HOME.DASHBOARD
                                  .WEEKLY_LIMIT
                              }
                              )
                            </span>
                          )}
                      </span>
                    </CardText>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" sm="6" md="6" lg="4" key="earning">
              <Card className="status-card">
                <CardBody className="status-card-body">
                <CardTitle tag="h5" className="status-card-title">
                {languageState.translation.HOME.DASHBOARD.
                SPECIAL_FOR_YOU}
                  </CardTitle>
                  <div className="">
                    <GetLaunchList />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/*daily links*/}

          <div className="d-flex justify-content-between align-items-center mr-1">
            <h2 className="mb-2 p-1">
              {languageState.translation.COMMON.DAILY_LINKS}
            </h2>

            <button className="add-shortcut" onClick={() => setModalShow(true)}>
              {languageState.translation.HOME.DASHBOARD.ADD_NEW_SHORTCUT}
            </button>
          </div>
          <Row className="cards-container mb-2">
            {homeState &&
              homeState.quickLinks.map((tool, index) => (
                <Col className="mb-2" xs="12" sm="6" md="6" lg="4" key={index}>
                  <CardsFocus
                    name={tool.name}
                    key={tool.id}
                    category={tool.category}
                    link={tool.link}
                    id={tool?.id}
                    idproject={undefined}
                  />
                </Col>
              ))}
          </Row>

          {notificationsState?.notifications?.items.length ? (
            <CardNotifications />
          ) : null}
          <h3 className="mt-4 mb-2 p-1">
            {languageState.translation.HOME.DASHBOARD.LATEST_JOBS}
          </h3>
          <Row className="h-100">
            <div className="col">
              <Card className="">
                <div
                  className="table-responsive h-100 height"
                  ref={projectTable}
                >
                  <Table className="table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">
                          {languageState.translation.CONNECT.JOB_TITLE}
                        </th>
                        <th className="text-center" scope="col">
                          {languageState.translation.JOBBOARD.NUMBER_VIEWS}
                        </th>
                        {/* <th
                          className='text-center'
                          scope="col"
                        >{languageState.translation.COMMON.STATUS}</th> */}

                        <th className="text-center" scope="col">
                          {languageState.translation.COMMON.PUBLICATION_DATE}
                        </th>
                        <th></th>
                      </tr>

                      {/* <th scope="col" /> */}
                    </thead>
                    <tbody className="list h-100">
                      {dashboardState.dashBoardStats &&
                        dashboardState.dashBoardStats?.latestJobs.map(
                          (job, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  history.push(
                                    `/private/jobs/job-details/${job.id}`
                                  );
                                }}
                              >
                                <span className="name mb-0 projet fs-1 text-center">
                                  {job.functionTitle}
                                </span>
                              </td>
                              <td className="text-center">{job?.nbViews}</td>

                              {/* <td className='item-center'>
                              <div style={getStatusStyle(job.status)}>
                                <span
                                  style={{
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    marginRight: '0.5rem',
                                    borderRadius: '50%',
                                    backgroundColor: getStatusStyle(job.status).color,
                                  }}
                                />
                                {getStatusStyle(job.status).statusText}
                              </div>
                            </td> */}
                              <td className="text-center">
                                {moment(job?.createdAt).format('DD MMMM YYYY')}
                              </td>
                              <td>
                                <i
                                  className="fas fa-eye cursor-pointer"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      'previousUrl',
                                      'dashboard'
                                    );
                                    history.push(
                                      `/private/jobs/job-details/${job.id}`
                                    );
                                  }}
                                ></i>
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </div>
          </Row>

          {/* Matching Opportunities */}
          {dashboardState.dashBoardStats?.matchingJobs.length ? (
            <>
              <h2 className="section-title p-1">
                {
                  languageState.translation.HOME.DASHBOARD
                    .MATCHING_OPPORTUNITIES
                }
              </h2>
              <Row className="cards-container">
                {dashboardState.dashBoardStats?.matchingJobs &&
                  dashboardState.dashBoardStats?.matchingJobs.map(
                    (job, index) => (
                      <Col xs="12" sm="6" md="6" lg="4" key={index}>
                        <Card className="matching-card">
                          <MatchingJobsCard job={job} />
                        </Card>
                      </Col>
                    )
                  )}
              </Row>
            </>
          ) : null}

          {/* <h2 className='section-title mt-2'>{languageState.translation.HOME.DASHBOARD.EXTRAS}</h2>
          <Row className="cards-container">
            {extraData.map((card, index) => (
              <Col xs="12" sm="6" md="6" lg="4" key={index}>
                <Card className="extra-card ">
              
                  <div className="extra-text">
                    <h2>{card.title}</h2>
                    <p className="">{card.description}</p>
                  </div>
                  <div className="extra-image">
                    <img src={card.imagePath} alt={`${card.title} Icon`} />
                  </div>
                </Card>
              </Col>
            ))}
          </Row> */}
        </div>
      </Container>
      <ModalMainInfo
        modalShow={modalInfoShow}
        closeModal={() => setModalInfoShow(false)}
        mainInfo={mainInfo}
        header={languageState.translation.FOCUS.DASHBOARD.PROJECT_DESCRIPTION}
      />
      <ModalAddLink
        modalShow={modalShow}
        closeModal={() => setModalShow(false)}
        idproject={undefined}
      />
    </>
  );
};

export default Dashboard;
