import { useContext } from 'react';
import useHttpClient from 'services/http-client-hook';
import { AppContext } from 'store/app.context';
import CLIENTS_STATE from 'store/clients/clients.state';

export const useInvoiceService = () => {
  const { dispatchClientsState } = useContext(AppContext);
  const { httpClient } = useHttpClient();

  const getInvoiceData = () => {
    return;
  };

  const addInvoiceData = (data) => {
    return;
  };

  const postPublicInvoice = async (payload) => {
    try {
      const response = await httpClient.post('/invoices/public', payload);
      return response.data;
    } catch (error) {
      console.error('Error posting public invoice:', error);
      throw error;
    }
  };

  const getPublicInvoices = async () => {
    try {
      const response = await httpClient.get(`/invoices/public`);
      return response.data;
    } catch (error) {
      console.error('Error fetching public invoices:', error);
      throw error;
    }
  };

  const getClients = () => {
    const clients = JSON.parse(sessionStorage.getItem('newClientInfo')) || [];
    dispatchClientsState({
      type: CLIENTS_STATE.types.SET_CLIENTS,
      clients: [
        {
          companyName: 'Demo',
          alias: '',
          companyIban: 'BE71096123456769',
          VATNumber: 'BE123467889',
          address: {
            street: 'Sparrendreef',
            postalCode: '2800',
            city: 'Mechelen',
            country: 'België',
            houseNumber: '14',
          },
        },
        ...clients,
      ],
    });
  };

  return {
    getInvoiceData,
    addInvoiceData,
    getClients,
    postPublicInvoice,
    getPublicInvoices,
  };
};
