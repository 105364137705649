import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalHeader } from 'reactstrap';
import useUserService from 'services/profile/user.service';
import { AppContext } from 'store/app.context';
import FirstScreenModal from './first-screen-modal';
import FirstStepperScreen from './first-stepper-screen';
import LastScreenModal from './last-screen-modal';
import SecondStepperScreen from './second-stepper-screen';
import ThirdStepperScreen from './third-stepper-screen';
import Stepper from './stepper';
import moment from 'moment';
import { calculateTimeLeft } from './onboarding.helper';
import "./stepper.css"
import _ from 'lodash';
import HOME_STATE from 'store/home/home.state';

const StepperModal = ({ userInfo }) => {
  const history = useHistory();
  const { autoUpdateUserInfo, updateBasicInfos } = useUserService();
  const { stepperState, dispatchHomeState } = useContext(AppContext);
  const [userRole, setUserRole] = useState(userInfo?.kind)
  const [onboarding, setOnboarding] = useState(false)
  const [timeLeft, setTimeLeft] = useState(moment.duration('0:2:55'));
  const [actionAfterOnboarding, setActionAfterOnboarding] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState({ hours: 0, minutes: 2, seconds: 55 });

  useEffect(() => {
    // TODO: to be removed just to clean data and change statusAviability = "available" to "OPEN_FOR_PROJECT"
    if (userInfo) {
      const statusAviability = userInfo.statusAviability
      if (_.toLower(statusAviability) === "available") {
        updateBasicInfos({ statusAviability: "OPEN_FOR_PROJECT" }) //"available" ===> "OPEN_FOR_PROJECT"
      }
    }

  }, [userInfo])

  useEffect(() => {
    if (!userInfo.stepper) {
      setUserRole(userInfo.kind)
      setOnboarding(true)
    }
  }, [])

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const { newDuration, hours, minutes, seconds } = calculateTimeLeft(timeLeft)
      setTimeLeft(newDuration);
      if (hours > 0 || minutes > 0 || seconds >= 0) setTimeRemaining({ hours, minutes, seconds })
    }, 1000);
    return () => clearInterval(timerInterval);
  }, []);

  const disableStepper = async () => {
    const result = await updateBasicInfos({
      stepper: true,
    });

    if (result && result.status === 200) {
      autoUpdateUserInfo();
      setOnboarding(false);
      if (actionAfterOnboarding === 1) {
        history.push('/private/jobs/jobboard')
      }
      else if (actionAfterOnboarding === 2) {
        history.push('/private/home/dashboard?add-project')
      }
      else if (actionAfterOnboarding === 3) {
        history.push('/private/connect/add-multiple-contacts')
      }
    }
    dispatchHomeState({type: HOME_STATE.types.OPEN_NEWS_MODAL})
  };

  const FreelancerOnboardingModal = () => {
    if (stepperState.isStepperEnded) {
      return <LastScreenModal disableStepper={disableStepper} />
    }
    else {
      return <>
        <Stepper setOnboarding={setOnboarding} />
        {
          stepperState.currentStep === 1 && (
            <FirstStepperScreen timeRemaining={timeRemaining} />
          )
        }

        {
          stepperState.currentStep === 2 && (
            <SecondStepperScreen timeRemaining={timeRemaining} />
          )
        }

        {/* {
          stepperState.currentStep === 3 && (
            <ThirdStepperScreen
              actionAfterOnboarding={actionAfterOnboarding}
              setActionAfterOnboarding={setActionAfterOnboarding}
            />
          )
        } */}
      </>
    }
  }

  if (onboarding && userRole === 'FREELANCER') {
    if (stepperState.currentStep === 0 && !stepperState.isStepperEnded) {
      return <FirstScreenModal setOnboarding={setOnboarding} />
    }

    return (
      <Modal className='onboarding-modal'
        size="xl"
        style={{ height: "calc(100% - 5rem)" }}
        centered
        isOpen={onboarding}
      >
        <ModalHeader />
        {FreelancerOnboardingModal()}
      </Modal>
    );
  }
  return null
};

export default StepperModal;
