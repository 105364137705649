import React, { useContext } from 'react';
import { AppContext } from 'store/app.context';
import moment from "moment" 
import './Invitation.css';
import useUserService from 'services/profile/user.service';

const InvitationNotification = ({ notification }) => {
  const { languageState } = useContext(AppContext);

  return (
       <div className="invitation-item w-100 position-relative">
    

      <h3 className="d-inline m-0">{notification.title}</h3>
      <p className="d-inline">{notification?.description}.</p>
      <span className="notification-time">
        {moment(notification.createdAt).format('D MMMM YYYY HH:mm')}
      </span>
    </div>
  );
};

export default InvitationNotification;
