import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import useUserService from 'services/profile/user.service';
import useSettingsService from 'services/settings/settings.service';
import { AppContext } from 'store/app.context';
import CreatableSelect from 'react-select/creatable';

const AddHardSkillsSelect = () => {
  const { languageState, skillsState, userState } = useContext(AppContext);
  const { getSkills, addSkill } = useSettingsService();
  const { autoUpdateUserInfo, updateBasicInfos } = useUserService();
  const [skills, setSkills] = useState([]);
  const [mySkills, setMySkills] = useState([]);

  useEffect(() => {
    getSkills();
  }, []);

  useEffect(() => {
    if (skillsState && skillsState.skills) {
      const newOptions = (skillsState.skills || [])
        .filter((skill) => skill.type !== 'SOFT')
        .map((skill) => ({ value: skill.id, label: skill.key }));
      setSkills(newOptions);
    }
  }, [skillsState]);

  useEffect(() => {
    if (userState?.userInfo) {
      setMySkills(
        [
          ...(userState?.userInfo?.skills || [])
            .filter((skill) => skill.type !== "SOFT")
            .map(skill => ({
              value: skill.id,
              label: skill.key,
            }))
        ]
      )
    }
  }, [userState])

  const AddHardtSkills = async (selectedSkills) => {
    const oldSkills = (userState?.userInfo?.skills || []).filter((skill) => skill.type !== "NORMAL").map(el => el.id);
    const newSkills = selectedSkills.filter((el) => !el.isOther).map(el => el.value);
    const result = await updateBasicInfos({ skills: [...(oldSkills || []), ...(newSkills || [])] });
    if (result && result.status === 200) {
      autoUpdateUserInfo();
      toast.success(languageState.translation.ALERT.SUCCESS_CHANGE_INFO, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }
  const handleCreateOption = async (newSkill) => {
    const response = await addSkill({ key: newSkill, type: 'NORMAL' });
    if (response && response.data) {
      const newSkillId = response.data.id;
      const updatedSkills = [
        ...mySkills,
        { value: newSkillId, label: newSkill },
      ];
      await AddHardtSkills(updatedSkills);
      setSkills([...skills, { value: newSkillId, label: newSkill }])
      setMySkills(updatedSkills);
    }  
};

  return (
    <>
      <Row>
        <Col xs="12">
          <CreatableSelect
            name="skills"
            noOptionsMessage={() => languageState.translation.COMMON.SKILLS_SELECT_EMPTY}
            value={mySkills}
            options={skills}
            isMulti
            isClearable
            isSearchable
            placeholder={languageState.translation.COMMON.SEARCH_TAG}
            onChange={(e) => {
              const userSkills = (e || []).map((el) => el)
              AddHardtSkills(userSkills)
            }}
            onCreateOption={handleCreateOption}
          />
        </Col>
      </Row>
    </>
  );
};

export default AddHardSkillsSelect;
