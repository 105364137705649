import React, { useContext, useEffect, useState } from 'react';
// react library for routing
import {
  useLocation,
  NavLink as NavLinkRRD,
  Link,
  useHistory,
} from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from 'react-perfect-scrollbar';
// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Select from 'react-select';
import { AppContext } from 'store/app.context';
import PROJECT_STATE from 'store/projects';
import ModalAddProject from 'components/ModalAddProjet/ModalAddProjet';
import ModalAddContact from 'components/modal-addd-contact/ModalAddContact';
import './sidebar.scss';
import _ from 'lodash';
import useHomeService from 'services/home/home.service';
import Avatar from 'components/avatar/avatar';
import { IMG_PREFIX } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import USER_STATE from 'store/user';
import SHARED_STATE from 'store/shared';
import { MODULES } from 'config/core';
import { toast } from 'react-toastify';
import version from 'utils/getPlatformVersion';
import ModalGroup from 'components/modalGroup/ModalGroup';
import { statuses } from 'components/modalStepper/onboarding.constant';
import useUserService from 'services/profile/user.service';
import { ModalAddClient } from 'components/ModalAddClient/addClientModal';
import { NewsModal } from 'components/NewsModal/NewsModal';
import AddFeedbackModal from 'components/ModalAddFeedback/modalAddFeedback';
import HOME_STATE from 'store/home/home.state';
import NewsCard from './newsCard/NewsCard';

const Sidebar = ({
  toggleSidenav,
  sidenavOpen,
  logo,
  rtlActive,
  routesByRole,
}) => {
  const history = useHistory();
  // Context
  const { getNbNewJob } = useHomeService();
  const {
    languageState,
    dispatchProjectState,
    userState,
    homeState,
    dispatchUserState,
    dispatchSharedState,
    dispatchHomeState,
    newsState,
  } = useContext(AppContext);
  // States
  const [state, setState] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [groupModalShow, setGroupModalShow] = useState(false);
  const [clientsModalShow, SetClientsModalShow] = useState(false);
  const [modalExternalContactModal, setModalExternalContactModal] =
    useState(false);
  const [user, setUser] = useState(userState?.userInfo);
  const [statusAviability, setStatusAviability] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [userVat, setUserVat] = useState(null);
  const { updateBasicInfos, autoUpdateUserInfo, getUserInfo } =
    useUserService();
  // Location
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    getNbNewJob();
  }, []);

  useEffect(() => {
    if (userState && userState.userInfo) {
      setUser(userState.userInfo);
      setStatusAviability(userState.userInfo.statusAviability || undefined);
      setUserVat(userState?.userInfo?.address?.[0]?.VATNumber || null);
    }
  }, [userState]);

  useEffect(() => {
    if (routesByRole?.length) {
      if (!document.body.classList.contains('g-sidenav-pinned')) {
        document.body.classList.add('g-sidenav-pinned');
      }
    }
  }, [routesByRole]);

  const updateStatusAviability = async (status) => {
    setStatusAviability(status);
    const reqData = {
      statusAviability: status,
    };
    const result2 = await updateBasicInfos(reqData);
    if (result2 && result2.status === 200) {
      autoUpdateUserInfo();
      getUserInfo();
    }
  };

  const activeRoute = (routeName) => {
    if (modalExternalContactModal) return '';
    return _.startsWith(location.pathname, routeName) ? 'active' : '';
  };
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  };

  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      }
    }
    return false;
  };
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = (prop) => {
    if (prop && prop.to) {
      dispatchProjectState({
        type: PROJECT_STATE.types.SELECT_PROJECT,
        project: prop,
      });
    }
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop['state']] = !state[prop.state];

        return (
          <NavItem key={key}>
            <NavLink
              href="#"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              className={classnames({
                //active: getCollapseInitialState(prop.views),
              })}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon ? (
                <div className="d-flex gap-2">
                  <i className={prop.icon} />
                  <span className="nav-link-text">
                    {prop.ignoreTranslate
                      ? prop.name
                      : languageState.translation.SIDEBAR[prop.name]}{' '}
                    {prop.iconLast && (
                      <i
                        className={`${prop.iconLast} text-end ml-5`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setModalShow(true);
                        }}
                      />
                    )}
                  </span>
                  {prop.NBJobs && homeState.nbNewJobs > 0 ? (
                    <Badge color="primary" className="ml-5">
                      {homeState && homeState.nbNewJobs}
                    </Badge>
                  ) : null}
                </div>
              ) : prop.miniName ? (
                <div className="">
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal">
                    {' '}
                    {prop.ignoreTranslate
                      ? prop.name
                      : languageState.translation.SIDEBAR[prop.name]}
                  </span>
                  {prop.iconLast && (
                    <i
                      className={`${prop.iconLast} text-end`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  )}
                  {prop.NBJobs && homeState.nbNewJobs > 0 ? (
                    <Badge color="primary" className="ml-5">
                      {homeState && homeState.nbNewJobs}
                    </Badge>
                  ) : null}
                </div>
              ) : null}
            </NavLink>
            <Collapse isOpen={state[prop.state]}>
              {prop?.name === 'FOCUS' && (
                <Nav
                  className="nav-sm flex-column nav"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setModalShow(true)}
                >
                  <NavLink>
                    <span className="sidenav-mini-icon"> + </span>
                    <span className="sidenav-normal">
                      {languageState.translation.SIDEBAR.ADD_PROJECT}
                    </span>
                    {prop.iconLast && (
                      <i
                        className={`${prop.iconLast} text-end`}
                        onClick={(e) => e.stopPropagation()}
                      />
                    )}
                  </NavLink>
                </Nav>
              )}
              {/* {prop?.name === 'CONNECT' || prop?.name === 'NETWORK' ?
                <Nav
                  className="nav-sm flex-column nav"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setModalExternalContactModal(true)}
                >
                  <NavLink className={modalExternalContactModal ? 'active' : ''} >
                    <span className="sidenav-mini-icon"> + </span>
                    <span className="sidenav-normal">
                      {languageState.translation.SIDEBAR.ADD_CONTACT}
                    </span>
                  </NavLink>
                </Nav>
                : null} */}

              <Nav className="nav-sm flex-column">
                {createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem key={key}>
          <NavLink
            className={activeRoute(prop.layout + prop.path)}
            to={prop.to ? prop.layout + prop.to : prop.layout + prop.path}
            activeClassName=""
            onClick={() => closeSidenav(prop)}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <div className="d-flex justify-content-center gap-2">
                <i className={prop.icon} />
                <span
                  className={`nav-link-text ${
                    prop.name === 'UPGRADE_PLAN' ? 'text-bold' : ''
                  }`}
                >
                  {prop.ignoreTranslate
                    ? prop.name
                    : languageState.translation.SIDEBAR[prop.name]}
                </span>
                {prop.iconLast && (
                  <i
                    className={`${prop.iconLast} text-end`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                )}
                {prop.NBJobs && homeState.nbNewJobs > 0 ? (
                  <Badge color="primary" className="">
                    {homeState && homeState.nbNewJobs}
                  </Badge>
                ) : null}
              </div>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <div
                  className={`d-flex ${
                    prop.iconLast
                      ? 'justify-content-between align-items-center w-100'
                      : 'justify-content-start w-100'
                  }`}
                >
                  <span className="sidenav-normal">
                    {' '}
                    {prop.ignoreTranslate
                      ? prop.name
                      : languageState.translation.SIDEBAR[prop.name]}
                  </span>
                  {prop.iconLast && (
                    <i
                      className={`${prop.iconLast} text-end rounded bordered add-icon`}
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          prop.name === 'YOUR_TEAMS' ||
                          prop.name === 'TALENTPOOLS'
                        ) {
                          setGroupModalShow(true);
                        }
                        if (prop.name === 'CUSTOMERS') {
                          SetClientsModalShow(true);
                        }
                      }}
                    />
                  )}
                  {prop.NBJobs && homeState.nbNewJobs > 0 ? (
                    <Badge color="primary" className="ml-4">
                      {homeState && homeState.nbNewJobs}
                    </Badge>
                  ) : null}
                </div>
              </>
            ) : prop.ignoreTranslate ? (
              prop.name
            ) : (
              languageState.translation.SIDEBAR[prop.name]
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    };
  }

  const logout = (e) => {
    toast.dismiss();
    dispatchUserState({
      type: USER_STATE.types.RESET_USER_INFO,
    });
    dispatchSharedState({
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
      module: MODULES.AUTH,
    });
    history.push('/login');
  };
  const handleStatusSelect = (status) => {
    let additionalMessage = '';
    if (status === 'NOT_AVAILABLE') {
      additionalMessage =
        languageState.translation.SIDEBAR.NOT_RECEIVE_NOTIFICATIONS;
    } else if (
      statusAviability === 'NOT_AVAILABLE' &&
      (status === 'OPEN_FOR_PROJECT' || status === 'OPEN_FOR_PART_TIME')
    ) {
      additionalMessage =
        languageState.translation.SIDEBAR.RECEIVE_NOTIFICATIONS;
    } else if (
      (statusAviability === 'OPEN_FOR_PROJECT' ||
        statusAviability === 'OPEN_FOR_PART_TIME') &&
      (status === 'OPEN_FOR_PROJECT' || status === 'OPEN_FOR_PART_TIME')
    ) {
      additionalMessage = '';
    }
    toast.success(
      `${languageState.translation.ALERT.STATUS_CHANGED}: ${languageState.translation.STEPPER.FIRST_STEP_OPTIONS[status]}. ${additionalMessage}`,
      {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      }
    );

    // Update the status availability and close the dropdown
    updateStatusAviability(status);
    setStatusDropdownOpen(false);
  };

  const scrollBarInner = (
    <div className="scrollbar-inner h-100">
      <div className="d-flex flex-column h-sidebar-content justify-content-between">
        <div>
          <div
            className="sidenav-header d-flex align-items-center justify-content-between"
            onClick={() => history.push('/private/home/dashboard')}
          >
            {logo ? (
              <div className="sidebar-logo">
                <img
                  alt={logo.imgAlt}
                  src={logo.imgSrc}
                  className="navbar-brand-img"
                />
              </div>
            ) : null}
            <div className="ml-auto sidebar-toggle-icon">
              <div
                className={classnames('sidenav-toggler d-none d-xl-block', {
                  active: sidenavOpen,
                })}
                onClick={toggleSidenav}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </div>
          </div>
          <div className="border-bottom mb-3 pb-3">
            <div
              className="sidebar-user-info d-flex align-items-center justify-content-between mx-3 c-pointer"
              onClick={() =>
                history.push(
                  `/private${APP_ROUTES.CONNECT.PROFILE_DETAILS}/${
                    user && user.id
                  }`
                )
              }
            >
              <div className="d-flex align-items-center gap-1">
                {user && user.profileImage ? (
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="user profile-picture"
                      src={`${IMG_PREFIX}${user.profileImage}`}
                    />
                  </span>
                ) : (
                  <Avatar
                    firstName={user && user.firstName}
                    lastName={user && user.lastName}
                    name={`${user && user.firstName} ${user && user.lastName}`}
                    forSidebar
                    className="rounded-circle custom-avatar "
                  />
                )}
                <div className="d-flex flex-column user-information">
                  <h4 className="mb-0">{`${user && user.firstName} ${
                    user && user.lastName
                  }`}</h4>
                  <h6 className="mb-0">
                    {user &&
                      languageState.translation.STEPPER.FIRST_STEP_OPTIONS[
                        user.profession
                      ]}
                  </h6>
                </div>
              </div>
            </div>
            {userState.userInfo?.kind === 'FREELANCER' && (
              <div className="user-status mt-3 mx-4">
                <Dropdown
                  isOpen={isOpen}
                  toggle={toggleDropdown}
                  className="w-100"
                >
                  <DropdownToggle
                    caret={true}
                    tag="div"
                    onClick={toggleDropdown}
                    className="w-100"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      padding: '2px 10px',
                    }}
                  >
                    <div className="status-select-display">
                      {statusAviability ? (
                        <div className="d-flex align-items-center gap-1">
                          <span
                            className={`status-icon ${statusAviability.toLowerCase()}`}
                          ></span>
                          <p className="font-weight-400">
                            {
                              languageState.translation.STEPPER
                                .FIRST_STEP_OPTIONS[statusAviability]
                            }
                          </p>
                        </div>
                      ) : (
                        languageState.translation.COMMON.MAKE_A_CHOICE
                      )}
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    {statuses.map((status) => (
                      <div
                        key={status}
                        className="d-flex align-items-center status-option py-2 px-1"
                        onClick={() => handleStatusSelect(status)}
                        style={{ cursor: 'pointer' }}
                      >
                        <span
                          className={`status-icon ${status.toLowerCase()} mx-2`}
                        ></span>
                        {
                          languageState.translation.STEPPER.FIRST_STEP_OPTIONS[
                            status
                          ]
                        }
                      </div>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            <div className="navbar-inner">
              {routesByRole && (
                <Collapse navbar isOpen={true}>
                  <Nav navbar>{createLinks(routesByRole)}</Nav>
                </Collapse>
              )}
            </div>
          </div>
        </div>
        <Nav className="d-flex flex-column justify-content-end h-100 gap-1 my-2">
          {/*<div className='mb-2'>
          <NewsCard />
        </div>*/}
          <NavItem
            className="mx-4 d-flex align-items-center c-pointer"
            onClick={() => {
              window.location.href =
                'https://www.facebook.com/groups/1285177021492348';
            }}
          >
            <i
              className="fab fa-facebook-f"
              style={{
                color: '#fa5252',
                minWidth: '2rem',
                fontSize: '0.8rem',
                lineHeight: '1.2rem',
              }}
            />
            <span>{languageState.translation.SIDEBAR.COMMUNITY}</span>
          </NavItem>
          <NavItem
            className="mx-4 d-flex align-items-center c-pointer"
            onClick={() =>
              dispatchHomeState({ type: HOME_STATE.types.OPEN_NEWS_MODAL })
            }
          >
            <i
              className="fas fa-rocket"
              style={{
                color: '#fa5252',
                minWidth: '2rem',
                fontSize: '0.8rem',
                lineHeight: '1.2rem',
              }}
            />
            <span>{languageState.translation.SIDEBAR.NEWS_MODAL}</span>
            <div color="primary" className="newsBadge">
              {newsState?.news.count || 0}
            </div>
          </NavItem>
          <NavItem
            className="mx-4 d-flex align-items-center c-pointer"
            onClick={() => setShowFeedbackModal(true)}
          >
            <i
              className="fas fa-magic"
              style={{
                color: '#fa5252',
                minWidth: '2rem',
                fontSize: '0.8rem',
                lineHeight: '1.2rem',
              }}
            />
            <span>{languageState.translation.SIDEBAR.SUGGEST_IDEA}</span>
          </NavItem>
          <NavItem
            className="mx-4 d-flex align-items-center c-pointer"
            onClick={() => {
              window.location.href =
                'https://extraexpertise.be/hulp/handleiding';
            }}
          >
            <i
              className="fas fa-user-cog"
              style={{
                color: '#fa5252',
                minWidth: '2rem',
                fontSize: '0.8rem',
                lineHeight: '1.2rem',
              }}
            />
            <span>{languageState.translation.SIDEBAR.HELP_CENTER}</span>
          </NavItem>
        </Nav>
        <div className="d-flex flex-column justify-content-between c-pointer logout-item">
          {userVat && (
            <p className="mx-auto mt-1">
              {languageState.translation.PROFILE.VAT_NUMBER}: {userVat}
            </p>
          )}
          <p className="version-platform">
            {languageState.translation.COMMON.VERSION} {version}
          </p>
          <div
            onClick={(e) => logout(e)}
            className="mx-4 d-flex align-items-center"
          >
            <i
              className="ni ni-user-run"
              style={{
                color: '#fa5252',
                minWidth: '2rem',
                fontSize: '0.8rem',
                lineHeight: '1.5rem',
              }}
            />
            <span>{languageState.translation.NAVBAR.LOGOUT}</span>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <Navbar
      id="sidebar"
      className={
        'sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ' +
        (rtlActive ? '' : 'fixed-left')
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf('Win') > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
      <ModalGroup
        modalShow={groupModalShow}
        closeModal={() => {
          setGroupModalShow(false);
        }}
      />
      <ModalAddProject
        modalShow={modalShow}
        closeModal={() => setModalShow(false)}
      />
      <ModalAddContact
        modalShow={modalExternalContactModal}
        closeModal={() => setModalExternalContactModal(false)}
      />
      <ModalAddClient
        modalShow={clientsModalShow}
        closeModal={() => SetClientsModalShow(false)}
      />

      <AddFeedbackModal
        modalShow={showFeedbackModal}
        closeModal={() => setShowFeedbackModal(false)}
      />
    </Navbar>
  );
};

Sidebar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: true,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;
