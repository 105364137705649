import { useContext, useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import "./newsModal.scss"
import { AppContext } from "store/app.context";
import useHomeService from "services/home/home.service";


export const NewsModal = ({ modalShow, closeModal }) => {
  const { languageState, newsState } = useContext(AppContext)
  const { getNews } = useHomeService();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [news, setNews] = useState([])

  useEffect(() => {
    const fetchNews = async () => {
    await getNews()
    };
    fetchNews();
  }, []);

  useEffect(()=> {
    setNews((newsState?.news?.items || []).filter((item) => item.language === languageState.defaultLanguage))
  }, [languageState])

  const handleNext = () => {
    if (currentIndex < news.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const { title, categories, content, moreInfoLink, pubDate, description, cta } =
    news[currentIndex] || {};

  return (
    <Modal className="news-modal" isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader className="news-modal-header" close={
        <button className="news-modal-close" onClick={closeModal}>
          ×
        </button>
      }>
        <h5 className="news-modal-title">{title}</h5>
        
        <p style={{fontWeight: '500'}}>{pubDate}</p>

      </ModalHeader>

      <ModalBody className={`news-modal-body`}>
        <p className="news-modal-subtitle">{categories}</p>
        <p className="news-modal-description">{description}</p>
        <div className="news-modal-content">{content}</div>
      </ModalBody>
      <ModalFooter className="news-modal-footer">
        <div className='d-flex justify-content-between align-items-center w-100'>
        {news.length > 1 && (
            <button
              className="news-modal-nav"
              onClick={handlePrev}
              disabled={currentIndex === 0}
            >
              <i className="fas fa-chevron-left"></i>
            </button>
        )}
        <a className="see-more-link" href={moreInfoLink} target="_blank" rel="noopener noreferrer">
          {cta}     
          </a>
          {news.length > 1 && (   <button
              className="news-modal-nav"
              onClick={handleNext}
              disabled={currentIndex === news.length - 1}
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          )}
          </div>
        
      </ModalFooter>
    </Modal>)
}