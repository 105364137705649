import { projects } from 'config/const';
import { M_PROJECTS } from 'config/mock/projects';
import APP_ROUTES from 'navigation/app.routes';
import FocusContainer from 'pages/focus';
import { useContext } from 'react';
import useMissionService from 'services/missions/mission.service';
import { AppContext } from 'store/app.context';
import MISSION_STATE from 'store/missions/missions.state';
import PROJECT_STATE from 'store/projects';
import useHttpClient from '../http-client-hook';

const useFocusService = () => {
  const { httpClient } = useHttpClient();
  const { dispatchProjectState, dispatchMissionState } = useContext(AppContext);

  const addProject = async (data) => {
    return httpClient.post('/missions/project', data);
  };
  const getProjects = async () => {
    // const result = await httpClient.get('/projects/mine');
    // if (result.data) {
    //   dispatchProjectState({
    //     type: PROJECT_STATE.TYPES.SET_PROJECTS,
    //     projects: result.data,
    //   });
    // }
    /**
     * Just a mock will be removed and change with the above code when API is ready
     */
    let mock = M_PROJECTS;
    mock = mock.map((item) => {
      const test = {
        ...item,
        to: `${APP_ROUTES.FOCUS.DASHBOARD}/${item.id}`,
        miniName: '',
        layout: '/private',
        ignoreTranslate: true,
      };
      return test;
    });
    dispatchProjectState({
      type: PROJECT_STATE.types.SET_PROJECTS,
      projects: mock,
    });
  };

  const getMisions = async () => {
    const result = await httpClient.get('/missions/freelancer');
    if (result && result.status == 200) {
      dispatchMissionState({
        type: MISSION_STATE.types.SET_MISSIONS,
        missions: result?.data.map((item) => {
          const test = {
            ...item,
            name: item.name ? item.name : item.functionTitle,
            tools: item.tools ? item.tools : [],
            tasks: item.tasks ? item.tasks : [],

            to: `${APP_ROUTES.FOCUS.DASHBOARD}/${item.id}`,
            miniName: '',
            layout: '/private',
            ignoreTranslate: true,
          };
          return test;
        }),
      });
    }
  };
  const createQuickLink = async (payload) =>
    httpClient.post('/quick-links', payload);

  const editQuickLink = async (payload) => {
    console.log('payload', payload);
    return httpClient.patch('/quick-links/' + payload.id, payload);
  };

  const deleteQuickLink = async (quicklinkId) =>
    httpClient.delete('/quick-links/' + quicklinkId);
  
  const getTeam = async (idproject) => {
    const result = await httpClient.get(`/fakerusers/${idproject}`);
    if (result && result.status == 200) {
      dispatchMissionState({
        type: MISSION_STATE.types.SET_TEAM,
        team: result?.data,
      });
    }
  };

  const getTeamMission = async (idproject) => {
    const result = await httpClient.get(`/missions/team/${idproject}`);
    if (result && result.status == 200) {
      dispatchMissionState({
        type: MISSION_STATE.types.SET_TEAM,
        team: result?.data,
      });
    }
  };
  const addTemMember = async (payload) =>
    httpClient.post('/fakerusers', payload);

  const addEvent = async (payload) => httpClient.post('/events', payload);
  const getEvents = async (idproject) => {
    const result = await httpClient.get(`/events/${idproject}`);
    if (result && result.status == 200) {
      dispatchMissionState({
        type: MISSION_STATE.types.SET_EVENTS,
        events: result?.data,
      });
    }
  };
  const updateEvent = async (payload) => httpClient.put('/events', payload);
  const deleteMyEvent = async (eventId) =>
    httpClient.delete(`/events/${eventId}`);
  const createTask = async (payload) => httpClient.post('/tasks', payload);
  const editTask = async (payload) => httpClient.put('/tasks', payload);
  const deleteTask = async (taskId) => httpClient.delete('/tasks/' + taskId);

  return {
    getProjects,
    addProject,
    getMisions,
    createQuickLink,
    createTask,
    getTeam,
    getTeamMission,
    addTemMember,
    addEvent,
    getEvents,
    updateEvent,
    deleteMyEvent,
    editQuickLink,
    deleteQuickLink,
    editTask,
    deleteTask,
  };
};

export default useFocusService;
