import CardsHeaderUser from 'components/cards/card-header-portfolio';
import { IMG_PREFIX } from 'config/core';
import { MODULES } from 'config/core';
import React, { useContext, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Container, Spinner } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';
import CONNECT_STATE from 'store/connect/connect.state';
import SHARED_STATE from 'store/shared';

const Resume = () => {
  const {
    languageState,
    dispatchSharedState,
    dispatchConnectState,
  } = useContext(AppContext);

  const { getUserById } = useConnectService();
  const { id } = useParams();

  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [userResume, setUserResume] = useState(null);
  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const result = await getUserById(userId);
      if (result?.status === 200) {
        setUser(result.data);
        setUserResume(result.data.resume || null);
      }
    } catch (error) {
      console.error("Failed to fetch user data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setUserId(id);
    }
  }, [id]);

  useEffect(() => {
    if (userId) {
      getUser();
      dispatchConnectState({
        type: CONNECT_STATE.types.SELECT_USER,
        activeUser: userId,
      });
    }
  }, [userId]);

  useEffect(() => {
    dispatchSharedState({
      module: MODULES.CONNECT_YOUR_NETWORK,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  return (
    <React.Fragment>
      <CardHeader className="p-0 font-weight-bold fs-2">
        {languageState.translation.COMMON.RESUME}
      </CardHeader>
      {loading ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner color="primary" />
        </div>
      ) : userResume ? (
        <Container fluid className="mt-3">
          <div className="d-flex justify-content-center">
            <Document
              file={`${IMG_PREFIX}${userResume}`}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
            >
              <Page size="A3" pageNumber={pageNumber} width={window.innerWidth > 600 ? 400 : 700} />
            </Document>
          </div>

          <div className="d-flex justify-content-center mt-3 align-items-center">
            <ButtonGroup>
              <Button
                disabled={pageNumber === 1}
                onClick={() => setPageNumber(pageNumber - 1)}
              >
                {languageState.translation.COMMON.PREVIOUS_PAGE}
              </Button>

              <span className="mx-3">
                {` ${pageNumber} / ${numPages}`}
              </span>

              <Button
                disabled={pageNumber >= numPages}
                onClick={() => setPageNumber(pageNumber + 1)}
              >
                {languageState.translation.COMMON.NEXT_PAGE}
              </Button>
            </ButtonGroup>
          </div>
        </Container>
      ) : (
        <div className="mt-4 text-center">
          <h5 className="text-muted">
            {languageState.translation.CONNECT.NO_RESUME}
          </h5>
        </div>
      )}
    </React.Fragment>
  );
};

export default Resume;
