import TYPES from './dashboard.type';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.GET_DASHBOARD_STATS:
      return {
        ...state,
        dashBoardStats: action.data,
      };
      case TYPES.UPDATE_WEEKLY_APPS:
        return {
          ...state,
           weeklyApplications: action.weeklyApplications
        }
  }
};

export default reducer;
