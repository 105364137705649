import TYPES from './clients.types';
import reducer from './clients.reducer';

const INITIAL_STATE = {
    clients : []
};

const CLIENTS_STATE = {
    types: TYPES,
    initialState: INITIAL_STATE,
    reducer,
};

export default CLIENTS_STATE;