import { useCallback, useContext } from 'react';
import useHttpClient from '../http-client-hook';
import { AppContext } from 'store/app.context';
import APPLICATIONS_STATE from 'store/applications/applications.state';


export const useSalesPipelineService = () => {
  const { httpClient } = useHttpClient();


  const getAllApplications = async () => {
    try {
      const result = await httpClient.get('/jobs/my-applications');
      return result.data

    } catch (error) {
      console.error("Error fetching applications:", error);
    }
  };

  const getNotes = async (applicationId) => {
    try {
      const response = await httpClient.get(`jobs-notes/${applicationId}`);
      
      return response.data
    }
    catch (error) {
      console.error('Error fetching notes:', error);
      throw error;
    }

  }
  const addNote = async (note, id) => {
    try {
      const response = await httpClient.post(`jobs-notes/${id}`, { note: note });
      return response.data;
    } catch (error) {
      console.error("Error adding note:", error);
      throw error;
    }
  }
  const deleteApplication = async (jobId) => {
    try {
      const response = await httpClient.delete(`jobs/delete-application/${jobId}`)
    } catch (error) {
      console.error("Error canceling application:", error);
      throw error;
    }

  }

 const deleteNote = async (id) => {
    try {
      const response = await httpClient.delete(`jobs-notes/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting note:", error);
      throw error;
    }
  }
  const updateExtraData = (id, payload) => {
    return httpClient.patch(`jobs/application-extra-data/${id}`, payload);
  }


  return {
    getNotes,
    addNote,
    updateExtraData,
    deleteApplication,
    getAllApplications,
    deleteNote,
  };
};

