import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { AppContext } from 'store/app.context';
import { useHistory } from 'react-router-dom';
import { MISSION_STATUS } from 'config/core';
import { Badge } from 'reactstrap';

export const MatchingJobsCard = ({ job }) => {
    const history = useHistory()
    const { languageState } = useContext(AppContext);
    // const urlToShare = `${API_BASE_URL}jobs/share/${job.id}`

    const [allSkills, setAllSkills] = useState([])
    const [tooltipIndex, setTooltipIndex] = useState(null)



    useEffect(() => {
        if (job) {
            const displayArray = (job.missionsSkills || []).slice(0, 3);
            const hasMore = (job.missionsSkills || []).length -  displayArray.length;
            setAllSkills( hasMore
              ? [...displayArray, { key: `+${hasMore} others`, id: 'more' }]
              : displayArray);
        }

    }, [job])
    return (
        <div className="matching-card p-3 shadow-sm w-100 d-flex flex-column justify-content-start align-items-start">
        <div className="text-muted mb-2" style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
          <p className="mb-0 font-weight-bold">
            {languageState.translation.COMMON.PUBLISHED}: {moment(job.createdAt).format('DD MMMM YYYY h:mmA')}
          </p>
        </div>
      
        <div className="matching-job-details">
      
          {job?.city && (
            <div className="matching-job-details-item">
              <i className="fas fa-map-marker-alt fa-sm" />
              <p className="matching-details-text">
                {job?.city + ', ' + job?.country}
              </p>
            </div>
          )}
          {job?.hoursPerWeek > 35 ? (
            <div className="matching-job-details-item">
              <i className="fas fa-clock fa-sm"></i>
              <p className="matching-details-text">
                {languageState.translation.COMMON.FULL_TIME}
              </p>
            </div>
          ) : (
            <div className="matching-job-details-item">
              <i className="fas fa-clock fa-sm"></i>
              <p className="matching-details-text">
                {languageState.translation.COMMON.PART_TIME}
              </p>
            </div>
          )}
          {job?.remote && (
            <div className="matching-job-details-item">
              <i className="fas fa-home fa-sm"></i>
              <p className="matching-details-text">{languageState.translation.COMMON.REMOTE}</p>
            </div>
          )}
        </div>
      <div className='matching-job-status'>
      <p className="location-text text-bold d-inline">{languageState.translation.COMMON.STATUS}: </p>
      {
                  job?.status && (
                    <div className="job-location-item">
                      <Badge
                        className={`d-flex align-items-center badge ${job.status === MISSION_STATUS.FINISHED
                          ? 'bg-exprimary'
                          : job.status === MISSION_STATUS.PUBLISHED
                            ? 'bg-success'
                            : job.status === MISSION_STATUS.CANDIDATE_FOUND || job.status === MISSION_STATUS.ON_GOING || job.status === MISSION_STATUS.INTERVIEWING
                              ? 'bg-warning'
                              : job.status === MISSION_STATUS.NOT_STARTED || job.status === MISSION_STATUS.WAITING_FOR_APPROVAL
                                ? 'bg-success'
                                : 'bg-danger'
                          }`}
                      >
                        <i
                          className={
                            job.status === MISSION_STATUS.FINISHED
                              ? 'bg-exprimary'
                              : job.status === MISSION_STATUS.PUBLISHED
                                ? 'bg-success'
                                : job.status === MISSION_STATUS.CANDIDATE_FOUND || job.status === MISSION_STATUS.ON_GOING || job.status === MISSION_STATUS.INTERVIEWING
                                  ? 'bg-warning'
                                  : job.status === MISSION_STATUS.NOT_STARTED || job.status === MISSION_STATUS.WAITING_FOR_APPROVAL
                                    ? 'bg-success'
                                    : 'bg-success'
                          }
                        />
                        <span className="text-white">
                          {languageState.translation.COMMON.STATUS_LIST[job.status]}
                        </span>
                      </Badge>
                    </div>
                  )
                }
      </div>
        <div className="matching-job-title">
          <h3>{job?.functionTitle || '---'}</h3>
        </div>
      
        {allSkills && allSkills.length ? (
          <div className="matching-required-skills">
            <p className="matching-required-skills-text">{languageState.translation.COMMON.REQUIRED_SKILLS}:</p>
            <div className="matching-skills-container">
              {allSkills?.map((missionSkill) => (
                <div key={missionSkill.id} className="matching-skill">
                  <p className="matching-skill-text">
                    {missionSkill.key}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="matching-no-skills">
            <p>{languageState.translation.COMMON.NO_REQUIRED_SKILLS}</p>
          </div>
        )}
      
        <div className="matching-action-buttons d-flex justify-content-between w-100">
          <button
            className="btn btn-exprimary w-100"
            onClick={() => {
              sessionStorage.setItem("previousUrl", "dashboard");
              history.push(`/private/jobs/job-details/${job.id}`);
            }}
          >
            {languageState.translation.COMMON.SHOW_DETAILS}
          </button>
          <button
            className="btn btn-primary w-100"
            onClick={() => {
              history.push({
                pathname: '/private/chat',
                state: { pathJob: `${window.location.origin}/private/jobs/job-details/${job.id}` },
              });
            }}
          >
            {languageState.translation.COMMON.SHARE_WITH_FRIEND}
          </button>
        </div>
      </div>
    );
};

