import { useContext, useEffect, useState } from "react";
import { Button, Row, Col, CardText, ListGroup, ListGroupItem, Container } from "reactstrap";
import './upgradePlan.scss'
import { AppContext } from "store/app.context";
import { API_BASE_URL } from "config/core";
import useUserService from "services/profile/user.service";
export const UpgradePlan = () => {
    const { languageState, userState } = useContext(AppContext);
    const { getSubscriptionsByUser } = useUserService();
    const [membershipState, setMembershipState] = useState(null);

    useEffect(() => {
        const geSubscription = async () => {
          const membership = await getSubscriptionsByUser()
          setMembershipState(membership)
        }
        geSubscription()
      }, []);
    return (
        <Container className="max-w-4xl bg-white p-4" fluid>
            <div className="upgarde-plan-container">
                <div className="text-section">
                    <h2 className="title text-2xl font-bold mb-3">{languageState.translation.COMMON.SUBSCRIPTION.UPGRADE_PLAN_TITLE}</h2>
                    <p className=" text-lg text-gray-600 mb-6">
                        {languageState.translation.COMMON.SUBSCRIPTION.UPGRADE_PLAN_SUBTITLE}.
                    </p>
                </div>
                <div className="image-section">
                    <img src="/upgrade_plan/upgrade_plan.png" alt="Extra Expertise" className="w-full h-auto" />
                </div>
            </div>

            <Row className="g-4">
                <Col md="5" className="mb-4">
                    <div className="plan-card d-flex flex-column">
                        <h3 className="text-xl font-semibold mb-7 ">Basic</h3>
                        <ul className="mt-1 list-unstyled">
                            <li className="d-flex align-items-center gap-2 text-sm">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.FREEMIUM_OPTIONS.ONE}
                            </li>
                            <li className="d-flex align-items-center gap-2 text-sm">
                                <i className="ni ni-check-bold text-xl " />{languageState.translation.COMMON.SUBSCRIPTION.FREEMIUM_OPTIONS.TWO}
                            </li>
                            <li className="d-flex align-items-center gap-2 text-sm">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.FREEMIUM_OPTIONS.THREE}
                            </li>
                            <li className="d-flex align-items-center gap-2 text-sm">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.FREEMIUM_OPTIONS.FOUR}
                            </li>
                        </ul>
                    </div>
                </Col>

                <Col md="7" className="mb-4">
                    <div className="plan-card">
                        <div className="d-flex justify-content-between">
                            <h3 className="text-xl font-semibold">Expert</h3>
                            <p className="text-2xl font-bold"><span className="text-bold text-xl">€25</span> <span className="text-sm">{languageState.translation.COMMON.SUBSCRIPTION.PER_MONTH}</span></p>
                        </div>
                        <Button color="danger" className="w-full mt-4" onClick={() => window.location.href = `${membershipState.invoiceLink ? membershipState.invoiceLink : `${API_BASE_URL}payment/checkout-freelancer/${userState.userInfo.id}`}`}
                        >{languageState.translation.HOME.DASHBOARD.UPGRADE_TO_PREMIUM}</Button>
                        <ul className="mt-4 list-unstyled">
                            <li className="d-flex align-items-center gap-2">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.PREMIUM_OPTIONS.ONE}
                            </li>
                            <li className="d-flex align-items-center gap-2">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.PREMIUM_OPTIONS.TWO}
                            </li>
                            <li className="d-flex align-items-center gap-2">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.PREMIUM_OPTIONS.THREE}
                            </li>
                            <li className="d-flex align-items-center gap-2">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.PREMIUM_OPTIONS.FOUR}
                            </li>
                            <li className="d-flex align-items-center gap-2">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.PREMIUM_OPTIONS.FIVE}
                            </li>
                            <li className="d-flex align-items-center gap-2">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.PREMIUM_OPTIONS.SIX}
                            </li>
                            <li className="d-flex align-items-center gap-2">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.PREMIUM_OPTIONS.ELEVEN}
                            </li>
                            <li className="d-flex align-items-center gap-2">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.PREMIUM_OPTIONS.SEVEN}
                            </li>
                            <li className="d-flex align-items-center gap-2">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.PREMIUM_OPTIONS.EIGHT}
                            </li>
                            <li className="d-flex align-items-center gap-2">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.PREMIUM_OPTIONS.NINE}
                            </li>
                            <li className="d-flex align-items-center gap-2">
                                <i className="ni ni-check-bold text-xl" />{languageState.translation.COMMON.SUBSCRIPTION.PREMIUM_OPTIONS.TEN}
                            </li>
                        </ul>



                    </div>
                </Col>
            </Row>
        </Container>
    );
};
