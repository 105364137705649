import TYPES from './clients.types';

const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.SET_CLIENTS:
            return{
                ...state,
                clients: action.clients
            }
            /*case TYPES.UPDATE_CLIENT:
                return {
                    ...state,
                    clients: state.clients.map((client) =>
                        client.id === action.client.id
                          ? { ...client }
                          : client
                      ),
                    };*/
            case TYPES.ADD_CLIENT:
                return{
                    ...state,
                    clients: [...state.clients, action.client]
                }    
        default:
            return state;
    }

};

export default reducer;
