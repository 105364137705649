import BreadcrumbComponent from 'components/breadcrumb';
import { DEFAULT_PRIVATE_PATH, IMG_PREFIX } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Badge, Button, Container, Row } from 'reactstrap';
import { AppContext } from 'store/app.context';
import HOME_STATE from 'store/home/home.state';
import { isAuthorized } from 'utils';

export const ProfileHeaderCard = ({ name, user, previousPath }) => {
    const { languageState, userState, dispatchHomeState } = useContext(AppContext);
    const [hasCompanyName, setHasCompanyName] = useState(false)
    const history = useHistory();
    const { id } = useParams();
    const { search } = useLocation();

    const [userId, setUserId] = useState('')
    useEffect(() => {
        if(id){
            setUserId(id)
        }
    }, [id])

    useEffect(() => {
        const queryParams = new URLSearchParams(search);
        const companyNameExists = !!queryParams.get('company_name');
        setHasCompanyName(companyNameExists);
    }, [search]);

    return (
        <>
            <div className="d-flex flex-column justify-content-center header pb-4 rounded px-4 shadow">
                <div className='return-overview-page-link' >
                    <BreadcrumbComponent breadCrumbActive={name} breadCrumbLinks={[{ label: languageState.translation.COMMON.RETURN_TO_OVERVIEW, link: previousPath, type: 'return_to_previous' }]} homeJobs={undefined} />
                </div>
                <Container
                    className="d-flex pt-6 ml-3 justify-content-between gap-2 w-75 flex-wrap"
                    fluid
                >
                    <Row className="d-flex gap-1 align-items-center justify-content-center">
                        <div className="rounded-img-container">
                            <img className="rounded-img-profile object-fit-cover"
                                src={
                                    user?.profileImage
                                        ? `${IMG_PREFIX}${user.profileImage}`
                                        : 'assets/img/man.png'
                                }
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = require('assets/img/man.png').default;
                                }}
                            />
                        </div>
                        <div className='d-flex flex-column  align-items-center align-items-sm-start'>
                            <h1 className="text-bold text-xl text-white mb-1">{name}</h1>
                            {
                                user.region
                                    ? <p className="text-muted d-flex align-items-center text-bold mb-1" style={{ gap: 5 }}>
                                        <i className="ni ni-world" />
                                        {user.city
                                            ? <span>{`${user.city},`}</span>
                                            : null
                                        }
                                        <label>{user.region}</label>
                                    </p>
                                    : null
                            }
                            {user?.workingTime && (
                                <p className="text-white ">
                                    <span className="font-weight-bold font-small-4">{languageState.translation.COMMON.TYPE} :</span>{' '}
                                    {user?.workingTime === 'FULL_TIME'
                                        ? 'Full Time'
                                        : 'Part Time'}{' '}
                                    {user?.profession && languageState.translation.STEPPER.FIRST_STEP_OPTIONS[user.profession]}
                                </p>
                            )}
                            {!history.location.pathname.includes('/public') ? ((userState?.userInfo?.kind === 'COMPANY' || userState?.userInfo?.kind === 'ADMIN' || userState?.userInfo?.kind === 'RECRUITER') ||  (userState.userInfo.id === user.id)) && user?.resume && (
                                <p className="text-white mt-2">
                                    <span className="font-weight-bold font-small-4">CV :</span>{' '}
                                    <Badge className='bg-primary-light'>
                                        <i className='fa fa-link mr-2' />
                                        <a className='text-primary' target='_blank' href={`${IMG_PREFIX}${user.resume}`}>{user.resume}</a>
                                    </Badge>
                                </p>
                            ) : null}
                        </div>
                    </Row>
                    <Row>

                    {userState?.userInfo?.id !== userId && (userState?.userInfo?.kind === "COMPANY" || userState?.userInfo?.kind === "RECRUITER" || userState?.userInfo?.kind === "ADMIN"  || (hasCompanyName)) &&                            <div className="d-flex flex-column h-100 w-100 justify-content-center">
                                    <Button
                                    className="mb-2"
                                    color={'secondary'}
                                    onClick={(e) =>{
                                        if (hasCompanyName) {
                                            dispatchHomeState({
                                                type: HOME_STATE.types.SET_USER_ID,
                                                publicUser: user,
                                            });
                                        }
                                        history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CHAT, {
                                            user: user,
                                        })
                                    }
                                    }
                                    >
                                        {languageState.translation.CONNECT.CHAT_WITH}
                                        {` ${name.toUpperCase()}`}
                                    </Button>
                            </div>
                        }
                        </Row>
                </Container>

            </div >
        </>
    );
};

