import React, { useContext, useEffect } from 'react';
import { Modal, ModalBody, Button, ModalFooter, ModalHeader, Container } from 'reactstrap';
import { AppContext } from 'store/app.context';
import STEPPER_STATE from 'store/stepper';
import DropwdownFlags from 'components/flags-dropdown';
import HOME_STATE from 'store/home/home.state';

const FirstScreenModal = ({ setOnboarding }) => {
  const { languageState, userState, dispatchStepperState, dispatchHomeState } = useContext(AppContext);
useEffect(() => {
  dispatchHomeState({type: HOME_STATE.types.CLOSE_NEWS_MODAL})
})
  return (
    <Modal className='first-step-modal' isOpen={true} size="lg" centered>
      <ModalHeader />
      <ModalBody>
        <h3>
          {`${languageState.translation.COMMON.GREETINGS} ${userState?.userInfo?.firstName?.toUpperCase()},`}
        </h3>
        <h1 className='title-welcome-onboarding'>
          {languageState.translation.COMMON.NEW_WELCOME}
        </h1>
        <h5 className='sub-title-welcome-onboarding'>
          {languageState.translation.COMMON.S1_P1}
        </h5>
        <h3 style={{ fontWeight: 300, fontSize: 15, fontStyle: 'normal' }}>
          {languageState.translation.COMMON.S1_P2}
        </h3>
        <h3 style={{ color: '#FB6340' }}>
          {languageState.translation.COMMON.S1_P3}
        </h3>
      </ModalBody>
      <ModalFooter className='mb-2 d-flex justify-content-between'>
        <DropwdownFlags direction="right" color="#212529" />
        <Button
          className="btn-next-link"
          color="link"
          onClick={() => {
            dispatchStepperState({
              type: STEPPER_STATE.types.INCREMENT_STEP,
            });
          }}
        >
          {languageState.translation.COMMON.STARTONBOARDING}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FirstScreenModal;
