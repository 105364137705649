import CardsHeaderFocus from "components/cards/card-header-focus"
import { Fragment, useContext, useEffect, useState } from "react"
import { AppContext } from "store/app.context"
import Select from 'react-select';
import { Card, Col, Container, CustomInput, Form, Input, Row } from "reactstrap"
import "./invoice.css"
import { Formik } from "formik"
import moment from "moment";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { SEPAQRGenerator } from "sepa-qr-code";
import { IMG_PREFIX } from 'config/core';
import { DragAndDropUploadMultiple } from "components/dragAndDrop/dragAndDropUploadMultiple";
import useFilesService from "services/files/files.services";
import { toast } from 'react-toastify';
import { ModalAddClient } from "components/ModalAddClient/addClientModal";
import useUserService from "services/profile/user.service";
import { PDFDocument } from "pdf-lib";
import { useInvoiceService } from "services/invoice/invoice.service";

export const Invoice = () => {
    const { languageState, userState, clientsState } = useContext(AppContext);
    const { uploadFile, uploadMultipleFiles } = useFilesService();
    const { checkIsValidEUVat } = useUserService()
    const {getClients} = useInvoiceService()
    const [useDefault, setUseDefault] = useState(false);
    const [invoiceState, setInvoiceState] = useState({
        invoiceId: "",
        invoiceYear: new Date().getFullYear(),
        invoiceNumber:String(parseInt(localStorage.getItem("invoiceId"), 10) || 0).padStart(3, '0'),
        freelancerLogo: localStorage.getItem("defaultFreelancerLogo") || "",
        paymentDueDate: parseInt(localStorage.getItem("defaultPaymentDueDate"), 10) || 7,
        bankAccountNumber: localStorage.getItem("defaultBankAccountNumber") || "",
        freelancerVat: localStorage.getItem("defaultFreelancerVat") || userState.userInfo?.address[userState.userInfo?.address.length - 1]?.VATNumber || "",
        freelancerAddress: {
            country: userState.userInfo?.address[0]?.country,
            city: userState.userInfo?.address[0]?.city,
            street: userState.userInfo?.address[0]?.street,
            postalCode: userState.userInfo?.address[0]?.postalCode,
            houseNumber: "",
        },
        companyName: "",
        companyIban: "",
        companyVat: "",
        companyAddress: {
            street: "",
            postalCode: "",
            city: "",
            country: "",
            houseNumber: ""
        },
        companyAlias: "",
        createdAt: new Date(),
        attachments: [],
    });

    const [invoiceRow, setInvoiceRow] = useState([{
        item: "",
        quantity: null,
        unitPrice: null,
        vatPerRow: null,
        total: 0,
    }])

    /* const [optionsState, setOptionState] = useState([
         { label: "GENERATE_QR_CODE", enabled: false },
         { label: "STRUCTURAL_NOTE", enabled: true },
         { label: "SEND_VIA_PEPPOL", enabled: true },
         { label: "FORWARD_TO_EMAIL", enabled: false },
         { label: "AUTOMATIC_REMINDER", enabled: true },
         { label: "CREATE_PAYMENT_LINK", enabled: true },
         { label: "SETUP_MONTHLY_INVOICE", enabled: true },
     ])*/

    const [invoiceData, setInvoiceData] = useState({
        subtotal: 0,
        calculatedTaxes: 0,
        totalAmount: 0,
        calculatedExtraExpertiseFee: 0,
        amountReceived: 0,
    });
    const [logoFile, setLogoFile] = useState({ file: null, filename: "" });
    const [attachementsFiles, setAttachementsFiles] = useState([]);
    const [isGenerateEnabled, setIsGenerateEnabled] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [showClientModal, setShowClientModal] = useState(false);

    const [isValidVat, setIsValidVat] = useState(true);

    useEffect(() => {
        getClients();
    }, [clientsState.clients.length])

    useEffect(() => {
        checkValidEUVat(isValidVAT(invoiceState.freelancerVat).cleaned)
    }, [])
    
    useEffect(() => {
        const isCompanyDetailsValid =
        invoiceState.companyName.length > 0
        const isFreelancerDetailsValid =
            isValidVat &&
            isValidIBAN(invoiceState.bankAccountNumber) &&
            invoiceState.paymentDueDate > 0;

        const areAllRowsValid = invoiceRow.every(row =>
            row.item.trim() !== '' &&
            row.quantity > 0 &&
            row.unitPrice > 0 &&
            row.vatPerRow >= 0
        );

        if (!isFreelancerDetailsValid) {
            setUseDefault(true)
        }
        setIsGenerateEnabled(
            isCompanyDetailsValid &&
            isFreelancerDetailsValid &&
            areAllRowsValid &&
            Number(invoiceState.invoiceNumber) != 0 &&
            invoiceState.invoiceYear != 0
        );
    }, [invoiceState, invoiceRow]);

    useEffect(() => {
        const id = `${invoiceState.invoiceYear}-${invoiceState.invoiceNumber && Number(invoiceState.invoiceNumber) !== 0 ? (invoiceState.invoiceNumber).padStart(3, '0') : 0}`;
        setInvoiceState({ ...invoiceState, invoiceId: id });
    }, [invoiceState.invoiceYear, invoiceState.invoiceNumber]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInvoiceState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleItemInputChange = (e, index) => {
        const { name, value } = e.target;

        setInvoiceRow(prevState => {
            const updatedRows = [...prevState];
            updatedRows[index] = {
                ...updatedRows[index],
                [name]: value,
            };
            return updatedRows;
        });
    };
    const handleSelectChange = (selectedOption) => {
        setInvoiceState(prevState => ({
            ...prevState,
            paymentDueDate: selectedOption?.value
        }));
    };
    const handleCompanySelect = (selectedOption) => {
        const selectedClient = clientsState.clients.find(client => client.companyName === selectedOption.value);
        if (selectedClient) {
            setInputValue(selectedClient.alias ? selectedClient.alias : selectedClient.companyName )
            setInvoiceState({
                ...invoiceState,
                companyName: selectedClient.companyName || "",
                companyVat: selectedClient.VATNumber || "",
                companyAddress: {
                    street: selectedClient.address?.street || "",
                    postalCode: selectedClient.address?.postalCode || "",
                    city: selectedClient.address?.city || "",
                    country: selectedClient.address?.country || "",
                    houseNumber: selectedClient.address?.houseNumber || "",
                },
                companyAlias: selectedClient.alias || "",
            });
        }
    };


    /* const NoOptionsMessage = (props) => (
         <components.NoOptionsMessage {...props}>
             <div>
                 <p>{languageState.translation.INVOICE.NO_COMPANY_FOUND}</p>
                 <a
                     onClick={() => {
                         setCompanyDetails(true);
                         setInvoiceState({ ...invoiceState, companyName: inputValue })
                     }}
                     className=""
                     style={{
                         marginTop: "8px",
                         padding: "6px 12px",
                         background: "#007bff",
                         color: "#000",
                         border: "none",
                         borderRadius: "4px",
                         cursor: "pointer",
                     }}
 
                 >
                     {languageState.translation.INVOICE.ADD_NEW_COMPANY}Add new company
                 </a>
             </div>
         </components.NoOptionsMessage>
     );*/
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('nl-BE', {
            style: 'currency',
            currency: 'EUR'
        }).format(amount);
    };

    useEffect(() => {
        const subtotal = invoiceRow.reduce((acc, row) => acc + row.total, 0);
        const calculatedTaxes = invoiceRow.reduce(
            (acc, row) => acc + (row.total * row.vatPerRow) / 100,
            0
        );
        const totalAmount = subtotal + calculatedTaxes;
        const calculatedExtraExpertiseFee = totalAmount * 0.10;
        const amountReceived = totalAmount + calculatedExtraExpertiseFee;

        setInvoiceData({
            subtotal,
            calculatedTaxes,
            totalAmount,
            calculatedExtraExpertiseFee,
            amountReceived,
        });
    }, [invoiceRow]);



    const resetToDefault = () => {
        setInvoiceRow([{
            item: "Hours",
            quantity: 0,
            unitPrice: 0,
            vatPerRow: 0,
            total: 0,
        }]);
        setInvoiceData({
            subtotal: 0,
            calculatedTaxes: 0,
            totalAmount: 0,
            calculatedExtraExpertiseFee: 0,
            amountReceived: 0,
        });
        setInvoiceState({
            invoiceNumber: "0",
            invoiceYear: 0,
            invoiceId: "",
            freelancerLogo: "",
            paymentDueDate: 10,
            bankAccountNumber: "",
            freelancerVat: userState.userInfo.address[0].VATNumber,
            freelancerAddress: {
                country: userState.userInfo.address[0].country,
                city: userState.userInfo.address[0].city,
                street: userState.userInfo.address[0].street,
                postalCode: userState.userInfo.address[0].postalCode,
                houseNumber: "",
            },
            companyName: "Demo client",
            companyAlias: "",
            companyIban: "BE43 7360 7025 4336",
            companyVat: "BE123456789",
            companyAddress: {
                street: "",
                postalCode: "",
                city: "",
                country: "",
                houseNumber: ""
            },
            createdAt: new Date(),
            attachments: [],
        })
    };
    /*const handleToggle = (index) => {
        setOptionState((prevOptions) =>
            prevOptions.map((option, i) =>
                i === index ? { ...option, enabled: !option.enabled } : option
            )
        );
    };*/

    const handleGenerateInvoice = async () => {

        //const selectedOptions = optionsState.filter(option => option.enabled);
        let freelancerLogo = ""
        let attachments = []
        if (logoFile.file) {
            const response = await uploadFile(logoFile.file)
            freelancerLogo = response.data.filename
            setInvoiceState({ ...invoiceState, freelancerLogo: response.data.filename })
        }
        else {
            freelancerLogo = invoiceState.freelancerLogo
        }

        if (attachementsFiles.length > 0) {
            const response = await uploadMultipleFiles(attachementsFiles)
            attachments = response.data
            setInvoiceState({ ...invoiceState, attachments: attachments })
        }

        generateInvoice(freelancerLogo, attachments);
        setInvoiceState({ ...invoiceState, invoiceNumber: String(Number(invoiceState.invoiceNumber) + 1) })
        localStorage.setItem("invoiceId", (Number(invoiceState.invoiceNumber) + 1).toString())
    }

    const generateInvoice = async (freelancerLogo, attachments) => {
        const {
            paymentDueDate,
            bankAccountNumber,
            freelancerAddress,
            freelancerVat,
            createdAt,
            companyName,
            companyVat,
            companyAddress,
            invoiceId
        } = invoiceState;
        const {
            subtotal,
            calculatedTaxes,
            calculatedExtraExpertiseFee,
            totalAmount
        } = invoiceData;

        const dueDate = new Date(
            createdAt.getTime() + paymentDueDate * 24 * 60 * 60 * 1000
        ).toLocaleDateString();

        const doc = new jsPDF();

        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text(`${languageState.translation.INVOICE.INVOICE}: #${invoiceId}`, 100, 10);

        if (freelancerLogo.length > 0) {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.src = `${IMG_PREFIX}${freelancerLogo}`;

            await new Promise((resolve, reject) => {
                img.onload = () => {
                    const aspectRatio = img.width / img.height;
                    const maxWidth = 70;
                    let targetHeight = 30;
                    let targetWidth = targetHeight * aspectRatio;
                    if (targetWidth > maxWidth) {
                        targetWidth = maxWidth;
                        targetHeight = maxWidth / aspectRatio;
                    }
                    doc.addImage(img, "PNG", 10, 10, targetWidth, targetHeight);
                    resolve();
                };

                img.onerror = () => reject(new Error("Failed to load the logo image."));
            });

        }

        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(`${userState.userInfo.firstName + ' ' + userState.userInfo.lastName}`, 14, 45);
        doc.text(`${freelancerAddress.street || "N/A"}`, 14, 51);
        doc.text(`${companyAddress.houseNumber || "N/A"}`, 14 + doc.getTextWidth(freelancerAddress.street) + 2, 51);
        doc.text(`${freelancerAddress.postalCode || "N/A"}`, 14, 57);
        doc.text(`${freelancerAddress.city || "N/A"}`, 25, 57);
        doc.text(`${freelancerAddress.country || "N/A"}`, 14, 63);
        doc.text(`${freelancerVat || "N/A"}`, 14, 69);

        doc.text(`${companyName}`, 130, 45);
        doc.text(`${companyAddress.street || "N/A"}`, 130, 51);
        doc.text(`${companyAddress.houseNumber || "N/A"}`, 130 + doc.getTextWidth(companyAddress.street) + 2, 51);
        doc.text(`${companyAddress.postalCode || "N/A"}`, 130, 57);
        doc.text(`${companyAddress.city || "N/A"}`, 141, 57);
        doc.text(`${companyAddress.country || "N/A"}`, 130, 63);
        doc.text(`${companyVat}`, 130, 69);

        doc.text(`${languageState.translation.INVOICE.DATE}:`, 14, 83);
        doc.text(`${moment(createdAt).format(
            'DD MMMM YYYY'
        )}`, 52, 83);
        doc.text(`${languageState.translation.INVOICE.DUE}:`, 14, 89);
        doc.text(`${moment(dueDate).format(
            'DD MMMM YYYY'
        )}`, 52, 89);

        doc.autoTable({
            startY: 95,
            head: [[languageState.translation.COMMON.DESCRIPTION, languageState.translation.INVOICE.QUANTITY, languageState.translation.INVOICE.UNIT_PRICE, 'VAT', languageState.translation.COMMON.AMOUNT]],
            body: invoiceRow.map((row) => [
                row.item,
                row.quantity,
                `${formatCurrency(row.unitPrice)}`,
                `${(row.vatPerRow || 0).toFixed(2)}%`,
                `${formatCurrency(row.total.toFixed(2))}`,
            ]),
            theme: 'grid',
            styles: {
                lineColor: [0, 0, 0],
                lineWidth: 0,
                cellPadding: 4,
                fontSize: 12,
                valign: 'middle',
            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontSize: 12,
                fontStyle: 'bold',
            },
            bodyStyles: {
                fillColor: [245, 245, 245],
                textColor: [0, 0, 0],
                lineWidth: 0,
            },
            alternateRowStyles: {
                fillColor: [255, 255, 255],
            },
            tableLineWidth: 0.2,
            horizontalPageBreak: true,
            columnStyles: {
                0: { cellWidth: '50%' },
                1: { cellWidth: '10%' },
                2: { cellWidth: '15%' },
                3: { cellWidth: '10%' },
                4: { cellWidth: '15%' },
            },
        });

        const summaryStartY = doc.lastAutoTable.finalY + 5;

        const cardX = 94;
        const cardY = summaryStartY;
        const summaryCardWidth = 101;
        const summaryCardHeight = 30;

        doc.setDrawColor(180, 180, 180);
        doc.setFillColor(240, 240, 240);
        doc.rect(cardX, cardY, summaryCardWidth, summaryCardHeight, "FD");

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        doc.setTextColor(0, 0, 0);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);

        const summaryContent = [
            [languageState.translation.INVOICE.SUBTOTAL, formatCurrency(subtotal.toFixed(2))],
            [`${languageState.translation.COMMON.TOTAL} VAT`, formatCurrency(calculatedTaxes.toFixed(2))],
            [`${languageState.translation.INVOICE.AMOUNT_DUE} ${moment(new Date(dueDate)).format(
                'DD MMMM YYYY'
            )}`, formatCurrency((totalAmount).toFixed(2))],
        ];

        let contentStartY = cardY + 6;
        const lineHeight = 7;
        summaryContent.forEach(([label, value]) => {
            doc.text(`${label}:`, cardX + 4, contentStartY);
            doc.text(value, cardX + 100, contentStartY, { align: "right" });
            contentStartY += lineHeight;
        });

        const cardWidth = 200;
        const cardHeight = 60;
        const cardMargin = 10;

        const cardStartY = 225;

        doc.setFillColor(240, 240, 240);
        doc.setDrawColor(180, 180, 180);
        doc.rect(5, cardStartY, cardWidth, cardHeight, 'FD');

        doc.setFontSize(14);
        doc.setTextColor(255, 0, 0);
        doc.text(languageState.translation.INVOICE.PAYMENT_CONDITIONS, 10, cardStartY + 8);

        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);

        const maxWidth = doc.internal.pageSize.width * 0.6;
        doc.text(languageState.translation.INVOICE.PAYMENT_CONDITIONS_PARAGRAPH, 10, cardStartY + 20, { maxWidth: maxWidth });
        doc.setFont("helvetica", "bold");
        doc.text(`${languageState.translation.INVOICE.PLEASE_USE} ${invoiceId} ${languageState.translation.INVOICE.REFERENCE_NUMBER}`, 10, cardStartY + 43);
        doc.setFont("helvetica", "normal");
        doc.text(`${languageState.translation.INVOICE.CONTACT} support@extraexpertise.be`, 10, cardStartY + 50);
        doc.text(
            languageState.translation.INVOICE.PAY_QRCODE,
            145,
            cardStartY + 8,
        );

        const qrCodeData = {
            name: companyName,
            iban: bankAccountNumber,
            amount: `${parseFloat(`${totalAmount + calculatedExtraExpertiseFee}`).toFixed(2)}`,
            remittanceInformation: `Invoice:#${invoiceId} | ${userState.userInfo.firstName} ${userState.userInfo.lastName}`,
        }
        const qrCodeImage = await generateQRCode(qrCodeData);

        doc.addImage(
            qrCodeImage,
            "PNG",
            152,
            cardStartY + 15,
            40,
            40
        );
        doc.setFontSize(12);
        doc.text(
            languageState.translation.INVOICE.GENERATED,
            38,
            292,
        );
        doc.addImage(
            require('../../assets/img/brand/ee_logo_whitebg.png').default,
            "PNG",
            100,
            286,
            70,
            10
        );

        const pdfFiles = [];
        const imageFiles = [];

        for (const file of attachementsFiles) {
            const fileType = file.type;

            if (fileType === "application/pdf") {
                pdfFiles.push(file); 
            } else if (fileType.startsWith("image/")) {
                imageFiles.push(file);
            }
        }
        const mainPdfBytes = doc.output("arraybuffer");
        const mainPdfDoc = await PDFDocument.load(mainPdfBytes);
        if (pdfFiles.length > 0) {

            for (const file of pdfFiles) {
                const fileBytes = await file.arrayBuffer();
                const externalPdf = await PDFDocument.load(fileBytes);
                const copiedPages = await mainPdfDoc.copyPages(externalPdf, externalPdf.getPageIndices());
                copiedPages.forEach((page) => mainPdfDoc.addPage(page));
            }
        }
        if (imageFiles.length > 0) {
            for (const file of imageFiles) {
                const imageBytes = await file.arrayBuffer();
                const fileExtension = file.name.split(".").pop().toLowerCase();
                let imgEmbed;
                if (fileExtension === "jpg" || fileExtension === "jpeg") {
                    imgEmbed = await mainPdfDoc.embedJpg(imageBytes);
                } else if (fileExtension === "png") {
                    imgEmbed = await mainPdfDoc.embedPng(imageBytes);
                }
                if (imgEmbed) {
                    const page = mainPdfDoc.addPage();
                    const { width, height } = page.getSize();

                    const margin = 20;

                    const maxWidth = width - 2 * margin;
                    const maxHeight = height - 2 * margin;

                    const imgWidth = imgEmbed.width;
                    const imgHeight = imgEmbed.height;

                    const aspectRatio = imgWidth / imgHeight;

                    let targetWidth = maxWidth;
                    let targetHeight = maxWidth / aspectRatio;

                    if (targetHeight > maxHeight) {
                        targetHeight = maxHeight;
                        targetWidth = maxHeight * aspectRatio;
                    }
                    const x = (width - targetWidth) / 2;
                    const y = (height - targetHeight) / 2;

                    page.drawImage(imgEmbed, {
                        x: x,
                        y: y,
                        width: targetWidth,
                        height: targetHeight,
                    });
                }
            }
        }

        const mergedPdfBytes = await mainPdfDoc.save();
        const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `Invoice${invoiceId}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };


    const saveDefaults = async () => {
        let freelancerLogo = ""
        if (logoFile.file) {
            const response = await uploadFile(logoFile.file)
            freelancerLogo = response.data.filename
            setInvoiceState({ ...invoiceState, freelancerLogo: response.data.filename })
        }
        localStorage.setItem("defaultBankAccountNumber", invoiceState.bankAccountNumber);
        localStorage.setItem("defaultFreelancerVat", invoiceState.freelancerVat);
        localStorage.setItem("defaultPaymentDueDate", invoiceState.paymentDueDate.toString());
        localStorage.setItem("defaultFreelancerLogo", freelancerLogo);
        toast.success(languageState.translation.INVOICE.DEFAULTS_UPDATED, {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
        });
    };
    const generateQRCode = async (data) => {

        const generator = new SEPAQRGenerator();
        try {
            const qrCode = await generator.generateQRCode(data)
            return qrCode
        }
        catch (error) {
            console.error("Error generating QR code:", error.message);
        };
    };

    const handleAddNewRow = () => {
        const newRow = {
            item: "",
            quantity: 0,
            unitPrice: 0,
            vatPerRow: 0,
            total: 0,
        };
        setInvoiceRow([...invoiceRow, newRow]);
    };

    const handleDeleteRow = (index) => {
        const updatedDetails = invoiceRow.filter((_, i) => i !== index);
        const updatedEditedDetails = invoiceRow.filter((_, i) => i !== index);
        setInvoiceRow(updatedDetails);
        setInvoiceRow(updatedEditedDetails);
    };
    const handleRemoveFile = () => {
        setLogoFile({ file: null, filename: "" })
        setInvoiceState({ ...invoiceState, freelancerLogo: "" })
    }
    const handleDetailsInputChange = (e, index) => {
        const { name, value } = e.target;
        setInvoiceRow((prevDetails) => {
            const updatedDetails = [...prevDetails];
            updatedDetails[index] = {
                ...updatedDetails[index],
                [name]: Number(value),
            };
            updatedDetails[index].total = updatedDetails[index].unitPrice * updatedDetails[index].quantity;
            return updatedDetails;
        });
    };
    function isValidIBAN(input) {
        const cleanedInput = input.replace(/\s+/g, '');
        const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{4,30}$/;
        return ibanRegex.test(cleanedInput);
    }

    function isValidVAT(input) {
        const cleanedInput = input.replace(/[\s.]+/g, '');
        const vatRegex = /^[A-Z]{2}[A-Z0-9]{10,12}$/;
        setIsValidVat(vatRegex.test(cleanedInput))
        return { valid: vatRegex.test(cleanedInput), cleaned: cleanedInput };
    }
    const checkValidEUVat = async (vat) => {
        const countryCode = vat.slice(0, 2);
        const vatCode = vat.slice(2);
        const response = await checkIsValidEUVat(countryCode, vatCode);
        setIsValidVat(response.valid ? response.valid : true);
    }

    const handleAttachementsUpload = (files) => {
        setAttachementsFiles([...attachementsFiles, ...files]);
    };
    const handleDeleteAttachemets = (index) => {
        const updatedAttachments = attachementsFiles.filter((_, idx) => idx !== index);
        setAttachementsFiles(updatedAttachments)
    }
    return (
        <>
            <CardsHeaderFocus
                name="Generate Invoice"
                breadcrumb
                className=''
                published="" type={undefined} subName={undefined}
                breadCrumbLinks={[{
                    label: "Dashboard",
                    link: "/home/dashboard"
                }]}
            />
            <Container className="invoice-container mt-3" fluid>

                <Col lg={12} className='d-flex align-items-center justify-content-between flex-wrap'>
                    <div>
                        <h3 className='font-weight-500'>
                            {languageState.translation.INVOICE.CONFIGURE_DEFAULT}
                        </h3>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mb-2'>
                        <span className='font-weight-500 mr-5'>{languageState.translation.INVOICE.CHANGE_DEFAULT}</span>
                        <CustomInput
                            type="switch"
                            label={<Fragment>
                                <span className={`${useDefault ? 'yes-label' : 'no-label'} switch-icon-left text-capitalize`}>{useDefault ? languageState.translation.COMMON.YES : languageState.translation.COMMON.NO}</span>
                            </Fragment>}
                            className={`${useDefault ? 'switch-on' : 'switch-off'}`}
                            id={'view_filter'}
                            name={'view_filter'}
                            inline
                            checked={useDefault}
                            onChange={(e) => {
                                setInvoiceState({ ...invoiceState })
                                setUseDefault(e.target.checked)
                            }}
                        />
                    </div>
                </Col>
                {useDefault &&

                    <Card className="p-3 h-100">
                        <Formik
                            initialValues={{
                                logo: "",
                                paymentDueDate: 0,
                                vatRate: 0,
                                bankAccountNumber: '',
                                companyName: "",
                                freelancerVat: "",
                                createdAt: moment().format("DD MMMM YYYY")
                            }}
                            onSubmit={(values) => {
                                //setPaymentState({ ...values });
                            }}
                        >
                            {({ handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-2 pl-3">
                                        <Col lg={11}>
                                            <p>
                                                {languageState.translation.INVOICE.INVOICE_INSTRUCTION}.
                                            </p>
                                        </Col>
                                    </Row>
                                    <Col lg={10}>
                                        <Row className="d-flex">
                                            <Col lg={6} md={12} className="form-group d-flex align-items-end gap-1">
                                                <div>
                                                    <label htmlFor="invoicYear" className="font-weight-600 label-input">
                                                        {languageState.translation.INVOICE.INVOICE_NUMBER}<span className="text-red"> *</span>
                                                    </label>
                                                    <Input
                                                        id="invoicYear"
                                                        type="number"
                                                        name="invoicYear"
                                                        className="form-control"
                                                        placeholder="000"
                                                        value={invoiceState.invoiceYear}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div>
                                                    <Input
                                                        id="invoiceNumber"
                                                        name="invoiceNumber"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="000"
                                                        value={invoiceState.invoiceNumber}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6} md={12} className="form-group d-flex flex-column mb-3">
                                                <label htmlFor="logo" className="font-weight-600 label-input mb-1">
                                                    {languageState.translation.INVOICE.UPLOAD_LOGO}
                                                </label>
                                                <div className="file-upload-container d-flex align-items-center gap-1">
                                                    <Input
                                                        type="file"
                                                        name="logo"
                                                        multiple
                                                        accept="image/*"
                                                        id="file-upload"
                                                        onChange={(event) => {
                                                            setLogoFile({ ...logoFile, file: event.currentTarget.files[0] });
                                                        }}
                                                    />
                                                    <div className="d-flex align-items-center justify-content-center gap-2 logo-upload-row w-100">
                                                        <label htmlFor="file-upload" className="custom-file-upload w-100">
                                                            <i className="fa fa-upload"></i> {languageState.translation.INVOICE.UPLOAD_LOGO}
                                                        </label>

                                                    </div>
                                                    {logoFile.file ?
                                                        <div className="file-preview">
                                                            <div className="file-item">
                                                                <img
                                                                    src={URL.createObjectURL(logoFile.file)}
                                                                    alt="logo Preview"
                                                                    className="file-preview-image"
                                                                />
                                                                <button
                                                                    className="delete-img-button"
                                                                    onClick={() => handleRemoveFile()}
                                                                >
                                                                    ✖
                                                                </button>
                                                            </div>
                                                        </div> : invoiceState.freelancerLogo ?
                                                            <div className="file-preview">
                                                                <div className="file-item mx-auto">
                                                                    <img
                                                                        src={`${IMG_PREFIX}${invoiceState.freelancerLogo}`}
                                                                        alt="logo Preview"
                                                                        className="file-preview-image"
                                                                    />
                                                                    <button
                                                                        className="delete-img-button"
                                                                        onClick={() => handleRemoveFile()}
                                                                    >
                                                                        ✖
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6} md={12}>
                                                <div className="form-group d-flex flex-column gap-1">
                                                    <label htmlFor="bankAccountNumber" className="font-weight-600 label-input">
                                                        {languageState.translation.INVOICE.BANK_ACCOUNT_NUMBER}
                                                        <span className="text-red"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="bankAccountNumber"
                                                        name="bankAccountNumber"
                                                        placeholder="Enter in IBAN format (e.g., DE89370400440532013000)"
                                                        className={`form-control ${isValidIBAN(invoiceState.bankAccountNumber) ? '' : 'is-invalid'}`}
                                                        value={invoiceState.bankAccountNumber}
                                                        onChange={handleInputChange}
                                                    />
                                                    {!isValidIBAN(invoiceState.bankAccountNumber) && (
                                                        <small className="text-danger">{languageState.translation.INVOICE.VALID_IBAN}</small>
                                                    )}
                                                </div>
                                                <div className="form-group d-flex flex-column gap-1">
                                                    <label htmlFor="freelancerVate" className="font-weight-600 label-input">
                                                        {languageState.translation.INVOICE.VAT}
                                                        <span className="text-red"> *</span>

                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="freelancerVat"
                                                        name="freelancerVat"
                                                        placeholder="Enter in VAT format (e.g., DE 0000.000.000)"
                                                        className={`form-control ${isValidVat ? '' : 'is-invalid'}`}
                                                        value={invoiceState.freelancerVat}
                                                        onChange={(e) => {
                                                            if (isValidVAT(e.target.value)) {
                                                                setInvoiceState({ ...invoiceState, freelancerVat: e.target.value })
                                                            }
                                                            const { valid, cleaned } = isValidVAT(e.target.value);
                                                            setIsValidVat(valid);

                                                            if (valid) {
                                                                checkValidEUVat(cleaned);
                                                            }
                                                        }}
                                                    />
                                                    {!isValidVat && (
                                                        <small className="text-danger">{languageState.translation.INVOICE.VALID_VAT}</small>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6} md={12} className="form-group d-flex flex-column gap-1">
                                                <label className="font-weight-600 label-input">
                                                    {languageState.translation.INVOICE.ATTACH_FILES}

                                                </label>
                                                <DragAndDropUploadMultiple onFilesUpload={handleAttachementsUpload} onDeleteFile={handleDeleteAttachemets} />
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center">
                                            <Col lg={6} md={12} className="form-group d-flex flex-column gap-1">
                                                <label htmlFor="paymentDueDate" className="font-weight-600 label-input">
                                                    {languageState.translation.INVOICE.PAYMENT_DUE_DATE}
                                                    <span className="text-red"> *</span>

                                                </label>
                                                <Select
                                                    id="paymentDueDate"
                                                    name="paymentDueDate"
                                                    value={{
                                                        label: invoiceState.paymentDueDate
                                                            ? `${invoiceState.paymentDueDate} ${languageState.translation.INVOICE.WORKING_DAYS}`
                                                            : `${languageState.translation.INVOICE.SELECT_DUE_DATE}`,
                                                        value: invoiceState.paymentDueDate,
                                                    }}
                                                    onChange={handleSelectChange}
                                                    options={[
                                                        { value: 0, label: languageState.translation.INVOICE.SELECT_PAYMENY_DUE_DATE },
                                                        { value: 5, label: `5 ${languageState.translation.INVOICE.WORKING_DAYS}` },
                                                        { value: 7, label: `10 ${languageState.translation.INVOICE.WORKING_DAYS}` },
                                                        { value: 14, label: `14 ${languageState.translation.INVOICE.WORKING_DAYS}` },
                                                    ]}
                                                />
                                            </Col>
                                            <Col lg={6} md={12} className="w-100">
                                                <button className="save-defaults w-100" onClick={saveDefaults}>{languageState.translation.INVOICE.SAVE_DEFAULTS}</button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            )}
                        </Formik>
                    </Card>}
                <Row >
                    <Col lg="12" className="h-100">
                        <Card className="p-3 h-100">
                            <p className="font-weight-bold font-size-2">
                                #{languageState.translation.INVOICE.INVOICE}: {invoiceState?.invoiceId}
                            </p>
                            <p className="font-weight-500">
                                {languageState.translation.INVOICE.CREATION_DATE} : {moment().format("DD MMMM YYYY")}
                            </p>
                            <label htmlFor={`companyName`} className="font-weight-600 label-input">
                                {languageState.translation.INVOICE.COMPANY_NAME}
                                <span className="text-red"> *</span>

                            </label>
                            <Row className="d-flex align-items-center gap-1 mb-2" lg={12} md={12} sm={12}>
                                <Col className="d-flex w-100" lg={4} md={4} sm={6}>
                                    <div className="w-100">
                                        <Select
                                            id="companyName"
                                            name="companyName"
                                            value={{
                                                label: invoiceState.companyAlias || invoiceState.companyName || 'Select Company',
                                                value: invoiceState.companyName || "",
                                            }}
                                            options={[{ label: "Select Client", value: "" },
                                            ...clientsState.clients.map((client) => ({
                                                label: client?.alias ? client?.alias : client?.companyName,
                                                value: client?.companyName,
                                            }))]}

                                            onChange={handleCompanySelect}
                                            onInputChange={(value) => setInputValue(value)}
                                            inputValue={inputValue}
                                        />
                                    </div>


                                </Col>
                                <p className="text-center">
                                    {languageState.translation.REGISTER.OR}
                                </p>
                                <Col>
                                    <button className="add-invoice" onClick={() => setShowClientModal(true)}>
                                        {languageState.translation.INVOICE.ADD_NEW_CLIENT}
                                        <div className="fa fa-plus"></div>
                                    </button>
                                </Col>
                                {/*<Col className={`${companyDetails ? 'd-flex' : 'd-none'} align-items-center justify-content-between gap-1`} lg={8} md={8} sm={12}>
                                    <div className="w-50">
                                        <label htmlFor={`companyAddress`} className="font-weight-600 label-input">
                                            {languageState.translation.FOCUS.TEAMS.ADDRESS}
                                            <span className="text-red"> *</span>

                                        </label>
                                        <Input
                                            type="text"
                                            id={`companyAddress`}
                                            name="companyAddress"
                                            className="form-control"
                                            value={invoiceState.companyAddress.street}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="w-50">
                                        <label htmlFor={`companyIban`} className="font-weight-600 label-input">
                                            {languageState.translation.INVOICE.BANK_ACCOUNT_NUMBER}
                                            <span className="text-red"> *</span>

                                        </label>
                                        <Input
                                            type="text"
                                            id={`companyIban`}
                                            name="companyIban"
                                            className={`form-control ${isValidIBAN(invoiceState.companyIban) ? '' : 'is-invalid'}`}
                                            value={invoiceState.companyIban}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {!isValidIBAN(invoiceState.companyIban) && (
                                            <small className="text-danger">{languageState.translation.INVOICE.VALID_IBAN}</small>
                                        )}
                                    </div>
                                    <div className="w-50">
                                        <label htmlFor={`companyVat`} className="font-weight-600 label-input">
                                            {languageState.translation.INVOICE.VALID_VAT}
                                            <span className="text-red"> *</span>

                                        </label>
                                        <Input
                                            type="text"
                                            id={`companyVat`}
                                            name="companyVat"
                                            className={`form-control ${isValidVAT(invoiceState.companyVat) ? '' : 'is-invalid'}`}
                                            value={invoiceState.companyVat}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {!isValidVAT(invoiceState.companyVat) && (
                                            <small className="text-danger">{languageState.translation.INVOICE.VALID_VAT}</small>
                                        )}
                                    </div>
                                </Col>*/}
                            </Row>
                            {invoiceRow.map((detail, index) => (
                                <Row className="d-flex align-items-end justify-content-between mb-2" key={index}>
                                    <Col lg={4} md={4} sm={6}>
                                        <div>
                                            <label htmlFor={`item-${index}`} className="font-weight-600 label-input">
                                                {languageState.translation.INVOICE.ITEM}
                                                <span className="text-red"> *</span>
                                            </label>
                                            <Input
                                                type="text"
                                                id={`item-${index}`}
                                                name="item"
                                                className="form-control"
                                                value={detail.item}
                                                onChange={(e) => handleItemInputChange(e, index)}

                                            />
                                        </div>
                                    </Col>

                                    <Col className="d-flex align-items-end justify-content-center gap-1" lg={8} md={8} sm={12}>
                                        <div>
                                            <label htmlFor={`quantity-${index}`} className="font-weight-600 label-input">
                                                {languageState.translation.INVOICE.QUANTITY}
                                                <span className="text-red"> *</span>

                                            </label>
                                            <Input
                                                type="number"
                                                id={`quantity-${index}`}
                                                name="quantity"
                                                className="form-control"
                                                value={detail.quantity}
                                                onChange={(e) => handleDetailsInputChange(e, index)}

                                            />
                                        </div>
                                        <div>
                                            <label htmlFor={`unitPrice-${index}`} className="font-weight-600 label-input">
                                                {languageState.translation.INVOICE.UNIT_PRICE}
                                                <span className="text-red"> *</span>

                                            </label>
                                            <Input
                                                type="number"
                                                id={`unitPrice-${index}`}
                                                name="unitPrice"
                                                className="form-control"
                                                value={detail.unitPrice}
                                                onChange={(e) => handleDetailsInputChange(e, index)}

                                            />
                                        </div>
                                        <div>
                                            <label htmlFor={`vatPerRow-${index}`} className="font-weight-600 label-input">
                                                {languageState.translation.INVOICE.VAT}(%)
                                                <span className="text-red"> *</span>

                                            </label>
                                            <Input
                                                type="number"
                                                id={`vatPerRow-${index}`}
                                                name="vatPerRow"
                                                className="form-control"
                                                value={detail.vatPerRow}
                                                onChange={(e) => handleDetailsInputChange(e, index)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor={`total-${index}`} className="font-weight-600 label-input">
                                                {languageState.translation.COMMON.TOTAL}
                                            </label>
                                            <Input
                                                type="number"
                                                id={`total-${index}`}
                                                name="total"
                                                className="form-control"
                                                value={detail.total}
                                                disabled={true}
                                            />
                                        </div>
                                        {invoiceRow.length > 1 && (
                                            <div>
                                                <p
                                                    className="mb-2 font-weight-bold cursor-pointer"
                                                    onClick={() => handleDeleteRow(index)}
                                                >
                                                    X
                                                </p>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            ))}

                            <Row className="ml-1 mt-2">
                                <button className="add-invoice" onClick={handleAddNewRow}>
                                    {languageState.translation.INVOICE.ADD_NEW_ROW}
                                    <div className="fa fa-plus"></div>
                                </button>
                            </Row>

                            <Row className="invoice-summary" lg={12}>
                                <Row className="invoice-row">
                                    <Col lg={8} className="font-weight-bold">{languageState.translation.INVOICE.SUBTOTAL}:</Col>
                                    <Col lg={4} className="text-right">{formatCurrency(invoiceData.subtotal.toFixed(2))}</Col>
                                </Row>
                                <Row className="invoice-row">
                                    <Col lg={8} className="font-weight-bold">
                                        {languageState.translation.INVOICE.CALCULATED_TAXES}:
                                    </Col>
                                    <Col lg={4} className="text-right">{formatCurrency(invoiceData.calculatedTaxes.toFixed(2))}</Col>
                                </Row>
                                <Row className="invoice-row">
                                    <Col lg={8} className="font-weight-bold">{languageState.translation.INVOICE.TOTAL_INVOICE_AMOUNT}:</Col>
                                    <Col lg={4} className="text-right">{formatCurrency(invoiceData.totalAmount.toFixed(2))}</Col>
                                </Row>
                                {/* <Row className="invoice-row">
                                    <Col lg={8} className="font-weight-bold">{languageState.translation.INVOICE.EE_FEES}:</Col>
                                    <Col lg={4} className="text-right">{formatCurrency(invoiceData.calculatedExtraExpertiseFee.toFixed(2))}</Col>
                                </Row> 
                                <Row className="invoice-row">
                                    <Col lg={8} className="font-weight-bold">{languageState.translation.INVOICE.YOU_RECEIVE}:</Col>
                                    <Col lg={4} className="text-right">{formatCurrency(invoiceData.amountReceived.toFixed(2))}</Col>
                                </Row>*/}
                                <Row className="d-flex justify-content-end gap-2 w-100 p-3">
                                    <button
                                        className="reset-invoice"
                                        onClick={resetToDefault}
                                    >
                                        {languageState.translation.INVOICE.RESET_TO_DEFAULT}
                                    </button>
                                    <button
                                        className={`${isGenerateEnabled ? 'generate-invoice-enabled' : 'generate-invoice-disabled'} generate-invoice cursor-pointer`}
                                        onClick={handleGenerateInvoice}
                                        disabled={!isGenerateEnabled}
                                    >
                                        {languageState.translation.INVOICE.GENERATE_INVOICE}
                                    </button>
                                </Row>
                            </Row>

                        </Card>
                    </Col>
                    {/*<Col lg="4" className="h-100">
                        <Card className="p-3 h-100">
                            <div>
                                <h2>
                                    {languageState.translation.INVOICE.OPTIONS_QUESTION}
                                </h2>
                                <p>
                                    {languageState.translation.INVOICE.OPTIONS_SUBTEXT}
                                </p>
                            </div>
                            <div>
                                {optionsState.map((option, index) => (
                                    <div key={index} className="optionCard">
                                        <h2>{languageState.translation.INVOICE[option.label]}</h2>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="toggle-switch-checkbox"
                                                id={`toggleSwitch-${index}`}
                                                checked={option.enabled}
                                                onChange={() => handleToggle(index)}
                                            />
                                            <label
                                                className="toggle-switch-label"
                                                htmlFor={`toggleSwitch-${index}`}
                                            >
                                                <span className="toggle-switch-inner" />
                                                <span className="toggle-switch-switch" />
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card>
                    </Col>*/}
                </Row>
            </Container>
            <ModalAddClient
                modalShow={showClientModal}
                closeModal={() => {
                    getClients();
                    setShowClientModal(false)
                }}
            />
        </>
    )
}