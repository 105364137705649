import { useContext, useEffect } from 'react';
import { AppContext } from 'store/app.context';
import { DEFAULT_PRIVATE_PATH, MODULES } from 'config/core';
import SHARED_STATE from 'store/shared';
import { Card, CardBody, Container } from 'reactstrap';
import { CardRecruiter } from 'components/Recruiter/cardRecruiter';
import { useHistory } from 'react-router-dom';
import APP_ROUTES from 'navigation/app.routes';
import MissionOverview from '../mission-overview/mission-overview';
import './style.scss'
import HOME_STATE from 'store/home/home.state';

export function recruiterDashboard() {
  const { dispatchSharedState, languageState, userState, homeState, dispatchHomeState } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    dispatchSharedState({
      module: MODULES.RECRUITER,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);


  const gridTemplate = {
    gridTemplateColumns: 'repeat(auto-fit, minmax(min(350px, 100%), 1fr))',
  };



  if (homeState.publicUser && userState?.userInfo?.kind === "COMPANY") {
    dispatchHomeState({
      type: HOME_STATE.types.SET_USER_ID,
      publicUser: null,
    });
    history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CHAT, {
      user: homeState.publicUser,
    });
  }

  return (
    <>
      <Container className="mt-4 paragraph-card dashboard-recruiter " fluid>
        <div className="card-recruiter-detail-container">
          <Card className="card-recruiter-details">
            <CardBody>
              <p>{languageState.translation.HOME.DASHBOARD.ACTIVE_FREELANCERS}</p>
              <h3>0</h3>
              <p
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {
                  history.push('/private/mission/contractors');
                }}
              >
                {languageState.translation.HOME.DASHBOARD.MANAGER_AND_PAY_FREELANCER}
              </p>
            </CardBody>
          </Card>
          <Card className="card-recruiter-details">
            <CardBody>
              <p>{languageState.translation.HOME.DASHBOARD.ACTIVE_MISSION}</p>
              <h3>0</h3>
            </CardBody>
          </Card>
          <Card className="card-recruiter-details">
            <CardBody>
              <p>{languageState.translation.HOME.DASHBOARD.PAYOUT_PENDING_APPROVAL}</p>
              <h3>0 EURO</h3>
            </CardBody>
          </Card>
        </div>

        <div className="team-grid m-auto" style={gridTemplate}>
          <CardRecruiter
            onClick={() =>
              history.push(`/private${APP_ROUTES.RECRUITER.ADD_MISSION}`)
            }
            nomcard={languageState.translation.RECRUITER.POST_NEW_MISSION}
            background={'bg-gradient-blue'}
          />
          <CardRecruiter
            onClick={() => history.push(`/private${APP_ROUTES.RECRUITER.SYNC}`)}
            nomcard={languageState.translation.RECRUITER.SYNC_FREELANCERS}
            background={'bg-exprimary'}
          />
          <CardRecruiter
            onClick={() =>
              history.push(`/private${APP_ROUTES.RECRUITER.SHARE_ONBOARDING}`)
            }
            nomcard={languageState.translation.RECRUITER.SEND_ONBOARD_INFO}
            background={'bg-green-color'}
          />
        </div>

        <MissionOverview type={''} />
      </Container>
    </>
  );
}
