import React from 'react';
import JobsContainer from '../JobsContainer';
import CardsHeaderFocus from 'components/cards/card-header-focus';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap';
import classnames from 'classnames';
import { useState, useContext } from 'react';
import { AppContext } from 'store/app.context';
import ReactDatetime from 'react-datetime';
import { Field, Formik } from 'formik';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import useMissionService from 'services/missions/mission.service';
import { useHistory } from 'react-router-dom';
import useFilesService from 'services/files/files.services';
import MultipleImageUpload from 'components/multiple-image-upload/multiple-image-upload';
import ExEditor from 'components/editor/editor';
import Select from 'react-select';

const PostJobs = () => {
  const [margin, setMargin] = useState(false);
  const [phoneNumber, setphoneNumber] = useState(false);
  const [paymentMethos, setpaymentMethos] = useState(false);
  const [yourName, setyourName] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [emailAddress, setemailAddress] = useState(false);
  const [location, setlocation] = useState(false);
  const [image, setImage] = useState(null);
  const [freelancersList, setFreelancersList] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const { languageState, dispatchSharedState, dispatchMissionState } =
    useContext(AppContext);

  const history = useHistory();

  const { createMission, getMissions, updateMission } = useMissionService();
  const { uploadFile } = useFilesService();
  const [initialValues, setValues] = useState({
    client: '',
    email: '',
    location: '',
    phoneNumber: '',
    dailyBudget: 0,
    currency: 'EUR',
    marginWithClient: '',
    startDate: '',
    endDate: '',
    functionTitle: '',
    company: '',
    mainInfo: '',
    socialMessage: '',
    language: '',
  });

  const changeFreelancersList = (list) => {
    setFreelancersList(list.map((item) => item.id));
  };

  const changeInvitedUsersList = (list) => {
    setInvitedUsers(list);
  };

  const onFileUpload = async (event) => { };

  const submitForm = async (values) => {

    console.log(values)
   };

  const onSelectLanguage = (language, values) => {
    setValues({
      ...values,
      language: language.label,
    });
  };

  const onCategoriesChange = (categories, values) => {
    setValues({
      ...values,
      categories,
    });
  };

  return (
    <JobsContainer>
      <CardsHeaderFocus name={languageState.translation.SIDEBAR.POST_JOB} parentName="Dashboards" />
      <Container fluid className="mt-4">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values) => submitForm(values)
          }
        >
          {({ errors, values, handleChange }) => (
            <>
              <Card>
                <CardHeader>
                  <h1 className="mb-5">
                    Please provide all the details for the freelance assignment
                  </h1>
                  <div className="d-flex">
                    <h3 className="mb-0">Details of contact person </h3>
                    <div>
                      <input type="checkbox" className="ml-3" />
                      <label className="ml-2">use my profile info</label>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames('input-group-merge', {
                              focused: yourName,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-user" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              className="form-control"
                              placeholder={
                                languageState.translation.COMMON.FIRST_NAME
                              }
                              type="text"
                              onFocus={(e) => setyourName(true)}
                              onBlur={(e) => setyourName(false)}
                              name="client"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames('input-group-merge', {
                              focused: yourName,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-user" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              className="form-control"
                              placeholder={
                                languageState.translation.COMMON.LAST_NAME
                              }
                              type="text"
                              name="client"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames('input-group-merge', {
                              focused: phoneNumber,
                            })}
                          >
                            <PhoneInput
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="BE"
                              className="form-control"
                              id="input-phone"
                              name="phoneNumber"
                              placeholder={
                                languageState.translation.COMMON.PHONE_NUMBER
                              }
                              value={values.phoneNumber}
                              onChange={(val, event) =>
                                handleChange({
                                  ...event,
                                  target: { name: 'phoneNumber', value: val },
                                })
                              }
                              error={
                                values.phoneNumber
                                  ? isValidPhoneNumber(values.phoneNumber)
                                    ? undefined
                                    : 'Invalid phone number'
                                  : 'Phone number required'
                              }
                            />

                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className="fas fa-phone" />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {values.phoneNumber &&
                            !isValidPhoneNumber(values.phoneNumber) && (
                              <div className="invalid-feedback">
                                {
                                  languageState.translation.FORM_VALIDATION
                                    .PHONE
                                }
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames('input-group-merge', {
                              focused: yourName,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-user" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              className="form-control"
                              placeholder={
                                languageState.translation.SETTINGS.COMPANY_NAME
                              }
                              type="text"
                              name="client"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames('input-group-merge', {
                              focused: yourName,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-user" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              className="form-control"
                              placeholder={
                                languageState.translation.SETTINGS
                                  .COMPANY_WEBSITE
                              }
                              type="text"
                              name="client"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames('input-group-merge', {
                              focused: emailAddress,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-envelope" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              className="form-control"
                              placeholder={
                                languageState.translation.COMMON.EMAIL
                              }
                              type="email"
                              onFocus={(e) => setemailAddress(true)}
                              onBlur={(e) => setemailAddress(false)}
                              name="email"
                            />
                          </InputGroup>
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames('input-group-merge', {
                              focused: location,
                            })}
                          >
                            <Field
                              className="form-control"
                              placeholder={
                                languageState.translation.COMMON.CITY
                              }
                              type="text"
                              onFocus={(e) => setlocation(true)}
                              onBlur={(e) => setlocation(false)}
                              name="location"
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className="fas fa-map-marker" />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {errors.location && (
                            <div className="invalid-feedback">
                              {errors.location}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames('input-group-merge', {
                              focused: location,
                            })}
                          >
                            <Field
                              className="form-control"
                              placeholder={
                                languageState.translation.COMMON.COUNTRY
                              }
                              type="text"
                              onFocus={(e) => setlocation(true)}
                              onBlur={(e) => setlocation(false)}
                              name="location"
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className="fas fa-map-marker" />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {errors.location && (
                            <div className="invalid-feedback">
                              {errors.location}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <input type="checkbox" className="ml-3" />
                    <label className="ml-2">remote / hybrid job</label>
                  </div>
                  <h3>{languageState.translation.RECRUITER.MISSIONS.JOB_DETAILS_TITLE}</h3>
                  <Row>
                    <Col lg="6">
                      <Select
                        isClearable
                        isSearchable
                        className="form-control"
                        placeholder="Select a job type"
                        id='job-type'
                        name='job-type'
                        options={[{ value: "Full time", label: "Full time" }, { value: "Part time", label: "Part time" }]}
                      />
                    </Col>

                    <Col className="d-flex" lg="6">
                      <Input
                        type="text"
                        placeholder="Add or select tags"
                        className="ml-4"
                      />
                      <Button className="ml-2 btn-exprimary">{languageState.translation.COMMON.BUTTONS.ADD}</Button>
                    </Col>
                    <Col lg="6"></Col>
                    <Col
                      className="d-flex gap-1 mt-4 align-items-center"
                      lg="6"
                    >
                      <p className="ml-4 text-bold">Selected tags:</p>
                      <div className=" tag text-white p-2  w-max-content rounded">
                        Copywriting
                      </div>
                      <div className=" tag text-white p-2  w-max-content rounded">
                        Copywriting
                      </div>
                    </Col>
                  </Row>
                  <Input placeholder="Job title" className="mt-4 mb-3" />

                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder="Job description"
                    rows="5"
                  ></textarea>

                  <p className="text-bold mt-4">
                    documents (maximum filesize : 2mb per file)
                  </p>
                  <MultipleImageUpload />
                  <Row>
                    <Col className="col-lg-6 col-12 mt-4">
                      <Label className="text-bold">
                        {languageState.translation.COMMON.START_DATE}
                      </Label>
                      <ReactDatetime
                        onChange={(e) =>
                          setValues({
                            ...values,
                            startDate: e,
                          })
                        }
                        timeFormat={false}
                        dateFormat={'DD-MM-YYYY'}
                        initialValue={new Date()}
                      />
                      {errors.startDate && (
                        <div className="invalid-feedback">
                          {errors.startDate}
                        </div>
                      )}
                    </Col>

                    <Col className="col-lg-6 col-12 mt-4">
                      <Label className="text-bold">
                        {languageState.translation.COMMON.END_DATE}
                      </Label>
                      <ReactDatetime
                        onChange={(e) =>
                          setValues({
                            ...values,
                            endDate: e,
                          })
                        }
                        dateFormat={'DD-MM-YYYY'}
                        initialValue={new Date()}
                        timeFormat={false}
                      />
                      {errors.endDate && (
                        <div className="invalid-feedback">{errors.endDate}</div>
                      )}
                    </Col>
                    <Col md="6 mt-3">
                      <FormGroup>
                        <InputGroup
                          className={classnames('input-group-merge ', {
                            focused: location,
                          })}
                        >
                          <Field
                            className="form-control"
                            placeholder={
                              languageState.translation.RECRUITER.MISSIONS
                                .DAILY_BUDGET
                            }
                            type="text"
                            name="location"
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>EUR </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {errors.location && (
                          <div className="invalid-feedback">
                            {errors.location}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6 mt-3">
                      <FormGroup>
                        <InputGroup
                          className={classnames('input-group-merge ', {
                            focused: location,
                          })}
                        >
                          <Field
                            className="form-control"
                            placeholder={
                              languageState.translation.COMMON.HOURLY_RATE
                            }
                            type="text"
                            name="location"
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>EUR</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {errors.location && (
                          <div className="invalid-feedback">
                            {errors.location}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="mt-4">
                <CardHeader>
                  <h3 className="mb-2 mt-4">
                    {languageState.translation.ADD_CONTACT.PRIVATE_NOTES}{' '}
                  </h3>
                </CardHeader>
                <CardBody>
                  <ExEditor
                    placeholder="Quill WYSIWYG"
                    initialContent="Quill WYSIWYG"
                    onChangeContent={(html) => { }}
                  />
                </CardBody>
              </Card>

              <Button className="d-block btn-exprimary ml-auto my-5">
                {languageState.translation.COMMON.BUTTONS.SUBMIT_JOB_POST}
              </Button>
            </>
          )}
        </Formik>
      </Container>
    </JobsContainer>
  );
};

export default PostJobs;
