import classNames from 'classnames';
import { MODULES } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import { innerRoutesAdmin, innerRoutesCompany, innerRoutesFreelancer, innerRoutesRecruiter } from 'navigation/routes';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { AppContext } from 'store/app.context';
import './style.scss'

const InnerNav = ({ }) => {
  const { languageState, sharedState, projectState, connectState, userState } = useContext(AppContext);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState({});
  const [childs, setChilds] = useState([]);
  const [currentModule, setCurrentModule] = useState('');
  const [activeProject, setActiveProject] = useState();
  const [activeUserId, setActiveUserId] = useState(null);
  const [isConnectModule, setIsConnectModule] = useState(false);
  const [isFocusModule, setIsFocusModule] = useState(false);

  useEffect(() => {
    setActiveItem({});
    setChilds([]);
    let tempActiveUserId = null;
    if (connectState.activeUser && !location.pathname.includes('/edit-mission/')) {
      setActiveUserId(connectState.activeUser);
      tempActiveUserId = connectState.activeUser;
    }
    const module = sharedState.currentModule;
    if (
      module !== MODULES.FOCUS &&
      module !== MODULES.HOME &&
      module !== MODULES.RECRUITER &&
      module !== MODULES.GROW &&
      module !== MODULES.MISSION_OVERVIEW &&
      module !== MODULES.CHAT &&
      module !== MODULES.SETTINGS &&
      module !== MODULES.SHARED &&
      module !== MODULES.JOBS
    )
      document.getElementById('navbarbg').classList.add('bg-transparent');
    else document.getElementById('navbarbg').classList.remove('bg-transparent');
    const listInnerRoutes =
      userState?.userInfo?.kind === 'ADMIN'
        ? innerRoutesAdmin
        : userState?.userInfo?.kind === 'RECRUITER'
          ? innerRoutesRecruiter
          : userState?.userInfo?.kind === 'FREELANCER'
            ? innerRoutesFreelancer
            : innerRoutesCompany
    const filtredChilds = listInnerRoutes.filter(
      (item) => item.parent === module
    );
    setCurrentModule(module.toUpperCase());

    let currentPath = location.pathname;
    let isFocusModule = false;
    let isConnectModule = false;
    /* specially for Focus module cause it depends on project */
    if (location.pathname.includes('/focus/')) {
      isFocusModule = true;
      setIsFocusModule(true);
      setIsConnectModule(false);
      currentPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
    }
    if (location.pathname.includes('/connect')) {
      isConnectModule = true;
      setIsConnectModule(true);
      setIsFocusModule(false);
    } else {
      setActiveProject();
    }

    if (module === MODULES.MISSION_OVERVIEW) {
      setActiveProject({ id: location.pathname.split('/')[location.pathname.split('/').length - 1] });
    }
    /* */

    const activeChild = filtredChilds.find(
      (item) => {
        if (module === MODULES.MISSION_OVERVIEW) {
          if (currentPath === item.layout + APP_ROUTES.RECRUITER.MISSIONS) return false;
          return item
        } else {
          return item.layout +
            (isFocusModule
              ? item.to
              : isConnectModule
                ? item.path.substring(0, item.path.lastIndexOf('/:')) +
                '/' +
                tempActiveUserId
                : item.path) === currentPath
        }

      }
    );

    if (activeChild) {
      setChilds(filtredChilds);
      setActiveItem(activeChild);
    }
  }, [location, sharedState, connectState, userState]);

  useEffect(() => {
    /* specially for Focus module cause it depends on project */
    if (location.pathname.includes('/focus/')) {
      setActiveProject(projectState.selectedProject);
    }
  }, [projectState]);

  const handleClick = (e, link) => {
    if (link === '#') e.preventDefault()
  }

  return (
    <Nav
      className="nav-fill d-flex w-50"
      id="tabs-text"
      justified
      role="tablist"
    >
      {childs &&
        activeItem &&
        activeItem.parent &&
        childs.map((child, key) => (
          <NavItem className="inner-nav d-flex" key={key}>
            <NavLink
              key={key}
              onClick={(e) => handleClick(e, child.to ? child.to : child.path)}
              activeClassName={"text-bold"}
              aria-selected={child.name === activeItem.name}
              className={classNames('mb-sm-3 mb-md-0 text-capitalize', {
                active: child.name === activeItem.name,
              })}
              to={
                {
                  pathname: (child.layout +
                    (child.to
                      ? child.to
                      : isConnectModule && activeUserId && !location.pathname.includes('/edit-mission/')
                        ? child.path.substring(0, child.path.lastIndexOf('/:id')) +
                        '/' +
                        activeUserId
                        : currentModule == 'MISSION_OVERVIEW'
                          ? child.path.substring(0, child.path.lastIndexOf('/:id'))
                          : child.path) +
                    (activeProject ? `/${activeProject.id}` : '')),
                  state: { ...location.state },
                }
              }
              role="tab"
            >
              <span className=" text-secondary navbar-item">
                {languageState.translation.INNER_NAVIGATION?.[currentModule]?.[child.name]}
              </span>
            </NavLink>
          </NavItem>
        )
        )}
    </Nav>
  );
};

export default InnerNav;
