import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap';
import JobsContainer from '../JobsContainer';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CardsHeaderFocus from 'components/cards/card-header-focus';
import useJobsService from 'services/jobs/jobs.service';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/nl';
import { toast } from 'react-toastify';
import { DEFAULT_PRIVATE_PATH } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import { AppContext } from 'store/app.context';
import Empty from 'components/Empty/Empty';
import LoaderPage from 'components/LoaderPage/LoaderPage';
import './JobDetails.scss';
import JobCard from '../jobboard/JobCard';
import ExEditor from 'components/editor/editor';
import { getQueryParamsFromObject } from 'utils';
import { useSalesPipelineService } from 'services/sales-pipeline/sales-pipeline.service';
import APPLICATIONS_STATE from 'store/applications/applications.state';
import useUserService from 'services/profile/user.service';
import { UpgradePremiumModal } from 'components/modalUpgradePremium/modalUpgradePremium';
import useDashboardService from 'services/dashboard/dashboard.service';
import DASHBOARD_STATE from 'store/dashboard/dashboard.state';
import Select from 'react-select';
import { workPreferations } from 'components/modalStepper/onboarding.constant';
import useFilesService from 'services/files/files.services';

const JobDetails = () => {
  const {
    getJobDetail,
    getJobsSaved,
    checkIsApplied,
    applyJob,
    deleteApplication,
    postViewJob,
    saveNewJob,
    checkIsSaved,
    deleteSaved,
    getApplicationTemplates
  } = useJobsService();
  const { updateExtraData, addNote, getNotes, getAllApplications, deleteNote } = useSalesPipelineService();
  const { getSubscriptionsByUser } = useUserService();
  const { getDashBoardStats } = useDashboardService();
  const { uploadFile } = useFilesService();
  const history = useHistory();
  const { userState, languageState, jobsState, dispatchApplicationState, dashboardState, dispatchDashboardState } = useContext(AppContext);
  moment.locale(languageState.defaultLanguage.toLowerCase());
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [myJob, setMyjob] = useState(false);
  const { userInfo } = userState;
  const [job, setJob] = useState(null);
  const [isApplied, setIsApplied] = useState(false);
  const [isSaved, setIsSaved] = useState(false)
  const [allSkills, setAllSkills] = useState([])
  const [jobSaved, setJobSaved] = useState([])
  const [estimation, setEstimation] = useState('')
  const [alias, setAlias] = useState('')
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState('');
  const [application, setApplication] = useState(null)
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [previousUrl, setPreviousUrl] = useState([])
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const [membershipState, setMembershipState] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [uploadError, setUploadError] = useState(null)
  const [messageTemlates, setMessageTemplates] = useState([])
  const [applicationState, setApplicationState] = useState({
    rate: userState?.userInfo?.amount || null,
    paymentType: userState?.userInfo?.paymentType || '',
    selectedRequirements: [],
    personalMessage: '',
    url: '',
    template: {},
    personalVideo: '',
    touched: {
      rate: false,
      personalMessage: false,
      selectedRequirements: false
    },
  });

  const hoursPerWeekOptions = useMemo(() => [
    { value: "LESS_THAN_1_DAY", label: languageState.translation.COMMON.HOURS_PER_WEEK.LESS_THAN_1_DAY },
    { value: "BETWEEN_1_AND_3_DAYS", label: languageState.translation.COMMON.HOURS_PER_WEEK.BETWEEN_1_AND_3_DAYS },
    { value: "MORE_THAN_3_DAYS", label: languageState.translation.COMMON.HOURS_PER_WEEK.MORE_THAN_3_DAYS },
  ], [languageState]);


  const { id } = useParams();
  const prevUrl = sessionStorage.getItem("previousUrl");

  const getBreadcrumbPath = () => {
    switch (prevUrl) {
      case 'freelanceJobs':
        setPreviousUrl([{ label: `${languageState.translation.SIDEBAR.FREELANCE_JOBS}`, link: `/private${APP_ROUTES.JOBS.BOARD}` }])
        break;
      case 'pendingApplications':
        setPreviousUrl([{ label: `${languageState.translation.SIDEBAR.PENDING_APPLICATIONS}`, link: `/private${APP_ROUTES.TOOLS.PENDING_APPLICATIONS}` }])
        break;
    }
  };
  useEffect(() => {
    const geSubscription = async () => {
      await getDashBoardStats()
      const membership = await getSubscriptionsByUser()
      setMembershipState(membership)
    }
    geSubscription();
  }, []);

  useEffect(() => {
    const getTemplates = async () => {
      const templates = await getApplicationTemplates()
      setMessageTemplates(templates.data.map((temp) => {
        return {
          label: temp.title,
          value: temp.content
        }
      }))
    }
    getTemplates();
  }, [languageState])

  const fetchApplications = async () => {
    const result = await getAllApplications()

    const applicationApplied = result.find((application) => application.job.id === id);

    setApplication(applicationApplied);
  };
  useEffect(() => {
    fetchApplications();

  }, []);

  useEffect(() => {
    const reqData = getQueryParamsFromObject({});
    getJobsSaved(reqData)
  }, [])

  useEffect(() => {
    const checkIfApplied = async (currentID) => {
      const result = await checkIsApplied(currentID);
      setIsApplied(result?.isApplied);
    };
    if (id) {
      setIsLoading(true);
      getAsyncJob(id);
      checkIfApplied(id);
      checkIfSaved(id);
      getBreadcrumbPath()
    }
  }, [id]);

  useEffect(() => {
    if (jobsState && jobsState.jobSaved) {
      setJobSaved(jobsState.jobSaved)
    }
  }, [jobsState])

  useEffect(() => {
    if (job) {

      setAllSkills(job.missionsSkills);
    }
    if (job?.owner?.id === userInfo?.id) {
      setMyjob(true);
    }
  }, [userState, job]);

  useEffect(() => {
    getUserNote()
    
  }, [application])
  
  const getUserNote = async () => {
    if (application) {
      const fetchedNotes = await getNotes(application.id);
      setNotes(fetchedNotes);
    }
  };
  const getAsyncJob = async (currentID) => {
    getJobDetail(currentID).then(
      (res) => {
        setJob(res.data);
        setIsLoading(false);
      },
      (err) => {
        setJob(null);
        setIsLoading(false);
      }
    );
  };


  const checkIfSaved = async (currentId) => {
    const result = await checkIsSaved(currentId)
    setIsSaved(result.data)
  }

  const saveJob = async (id) => {
    const result = await saveNewJob(id)
    if (result && result.status === 201) {
      checkIfSaved(id)
      const reqData = getQueryParamsFromObject({});
      getJobsSaved(reqData)
      toast.success(languageState.translation.ALERT.JOB_SAVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };
  const deleteSavedJob = async () => {
    const result = await deleteSaved(id)
    if (result && result.status === 200) {
      checkIfSaved(id)
      toast.success(languageState.translation.ALERT.DELETE_JOB_SAVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  }

  const chatWithEmployer = () => {
    history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CHAT, {
      email: job?.owner?.email,
    });
  };
  if (isLoading) {
    return <JobsContainer>
      <LoaderPage />
    </JobsContainer>
  } else if (!job) {
    return (
      <JobsContainer>
        <Empty icon={undefined} message={undefined} />
      </JobsContainer>
    );
  }

  const openDetails = (id,) => {
    if (userState && userState.userInfo) {
      postViewJob(id)
      history.push('/private/jobs/job-details/' + id)
    }
  }

  const handleUpdateExtraData = async () => {
    const payload = {}
    const revenue = parseFloat(estimation);
    if (revenue >= 0) {
      payload.estimatedRevenue = revenue
    }
    if (alias) {
      payload.alias = alias
    }
    await updateExtraData(application.id, payload);

    if (application.status === "SELECTED") {
      dispatchApplicationState({
        type: APPLICATIONS_STATE.types.EDIT_JOB_APPLICATION,
        applications: { ...application, estimatedRevenue: revenue },
      })
    }
    else {
      dispatchApplicationState({
        type: APPLICATIONS_STATE.types.EDIT_JOB_APPLICATION,
        applictaions: { ...application, estimatedRevenue: revenue },
      });
    }
  }
  const handleAddNoteClick = () => {
    setIsAddingNote(true);
  };

  const handleNoteContentChange = (content) => {
    setNote(content);
  };

  const handleSaveNote = async () => {
    if (note) {
      await addNote(note, application?.id);
      const newNote = { note: note, created_at: new Date() };
      notes.unshift(newNote)
      setNote('')
      getUserNote()
     }
    setIsAddingNote(false);
  };

  const handleDeleteNote = async (id) => {
    if(id)
      {
        const newNotes = notes.filter((note) => note.id !== id);
      setNotes(newNotes);
      await deleteNote(id)
    }
  }

  const subtotal = applicationState.rate || 0;
  const eeFees = subtotal * 0.11;
  const youReceive = subtotal - eeFees;

  const isRateValid = applicationState.rate !== null && applicationState.rate >= 5;
  const isPersonalMessageValid = applicationState.personalMessage.length >= 37;
  const isRequirementsValid = applicationState.selectedRequirements.length > 0 ? applicationState.selectedRequirements.length > 0 && applicationState.touched.selectedRequirements : true;

  const handleInputChange = (field, value) => {
    setApplicationState((prev) => ({
      ...prev,
      [field]: value,
      touched: { ...prev.touched, [field]: true },
    }));
  }

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleDeleteVideo = () => {
    setSelectedVideo(null);
  }

  const handlePaymentTypeChange = (selectedOption) => {
    setApplicationState((prevState) => ({
      ...prevState,
      paymentType: selectedOption.value,
    }));
  };

  const handleRequirementChange = (req) => {
    setApplicationState((prev) => ({
      ...prev,
      selectedRequirements: [...prev.selectedRequirements, req],
      touched: { ...prev.touched, selectedRequirements: true }
    }));
  };

  const handleVideoInputChange = async (value) => {
    if (value.size / (1024 * 1024) < 30) {
      setUploadError(null)
      setSelectedVideo(value)
    }
    else {
      setSelectedVideo(null)
      setUploadError(languageState.translation.COMMON.LARGE_VIDEO)
    }
  };

  const handleApplyJob = async () => {
    let uploadedVideo = ''
    if (selectedVideo) {
      const response = await uploadFile(selectedVideo)
      uploadedVideo = response.data.filename
      setApplicationState({ ...applicationState, personalVideo: response.data.filename })
    }

    if (dashboardState.dashBoardStats?.weeklyApplications >= 1 && membershipState?.name === "Basic") {
      setShowSubscriptionModal(true);
      return;
    }

    dispatchDashboardState({
      type: DASHBOARD_STATE.types.UPDATE_WEEKLY_APPS,
      weeklyApplications: dashboardState.dashBoardStats?.weeklyApplications + 1,
    });
    const result = await applyJob(id, {
      selectedRequirements: applicationState.selectedRequirements,
      form: applicationState.personalMessage,
      rate: applicationState.rate,
      rateType: applicationState.paymentType,
      personalVideo: uploadedVideo
    });

    if (result) {
      toast.success(languageState.translation.JOBBOARD.APPLICATION_SUCCESFULLY, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      fetchApplications()
      setIsApplied(true);
    }

  };

  const handleDeleteApplication = async () => {
    const result = await deleteApplication(id);

    if (result) {
      toast.success(languageState.translation.JOBBOARD.APPLICATION_REMOVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setApplication(null)
      setIsApplied(false);
    }
  };

  const handleApplication = () => {
    if (isApplied) {
      handleDeleteApplication();
    } else {
      handleApplyJob();
    }
  };


  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('nl-BE', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);
  }

  return (
    <JobsContainer>
      <CardsHeaderFocus
        name={job?.functionTitle}
        breadcrumb
        className='job-breadcrumb'
        published={moment(job?.createdAt).format('DD MMMM YYYY')} type={undefined} subName={undefined}
        breadCrumbLinks={previousUrl}
      />

      <Container fluid className="mt-4 job-details">
        <div className="job-detail-header">
        </div>
        <Row>
          <Col lg="7" md="12">
            <Card>
              <CardBody>
                <div className="job-description">
                  <h1>{languageState.translation.COMMON.JOB_DESCRIPTION}</h1>
                  <p className="job-description-text" dangerouslySetInnerHTML={{ __html: job?.mainInfo }}>
                  </p>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="job-description">
                  <h1>{languageState.translation.COMMON.LIKE_TO_APPLY_FOR_THIS_JOB}</h1>
                  {allSkills && allSkills.length ? (
                    <div className=''>
                      <p className="font-weight-bold mb-2">
                        {languageState.translation.COMMON.HAVE_FOLLOWING_SKILLS_FOR_JOB}
                      </p>
                      <div className="required-skills">
                        <p className="required-skills-text">{languageState.translation.COMMON.REQUIRED_SKILLS}:</p>
                        {allSkills?.map((missionSkill) => (
                          <div key={missionSkill.id} className="skill">
                            <p className="skill-text text-nowrap">
                              {missionSkill.key}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  {job && job.missionRequirements && job.missionRequirements.length ? (
                    <div>
                      <p className="font-weight-bold mb-2">
                        {languageState.translation.COMMON.EXTRA_REQUIREMENTS_FOR_THIS_JOB}<span className='text-red'> *</span>
                      </p>
                      <div className="row mx-3">
                        {job.missionRequirements.map((req, index) => (
                          <div className="mb-2 col-lg-12 col-md-12 col-sm-12" key={index}>
                            <Input
                              className="custom-control-input position-relative"
                              id={`requirement-${index}`}
                              name={`requirement-${index}`}
                              value={req.item}
                              type="checkbox"
                              checked={application?.selectedRequirements ? application?.selectedRequirements.some(selectedReq => selectedReq.item === req.item) : applicationState.selectedRequirements.some(selectedReq => selectedReq.item === req.item)}
                              onChange={() => handleRequirementChange(req)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`requirement-${index}`}
                            >
                              {req.item}
                            </label>
                          </div>
                        ))}
                      </div>
                      {applicationState.selectedRequirements && !isRequirementsValid && (
                        <p className="text-red">
                          {languageState.translation.COMMON.REQUIREMENTS_ERROR}
                        </p>
                      )}
                    </div>
                  ) : null}
                </div>

                <div className="application-form" >
                  <label className="font-weight-bold mb-2">
                    {languageState.translation.COMMON.UPLOAD_VIDEO}
                  </label>
                  <div className="d-flex flex-column align-items-start mb-2">
                    <button
                      color={selectedVideo ? "danger" : "primary"}
                      className="mr-2 uploadVideoButton"
                      onClick={selectedVideo ? handleDeleteVideo : handleUploadButtonClick}
                    >
                      {selectedVideo ? "delete" : "Upload"}
                    </button>
                    <div className="visually-hidden">
                      <Input
                        type="file"
                        className="video-upload"
                        accept="video/*"
                        onChange={(e) => handleVideoInputChange(e.target.files[0])}
                        innerRef={fileInputRef}
                      />
                    </div>
                    {selectedVideo && (
                      <div className="mt-1 d-block">
                        <video controls width="100%">
                          <source src={URL.createObjectURL(selectedVideo)} type={selectedVideo.type} />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}
                    {uploadError && <p className='text-red font-weight-bold'>{uploadError}</p>}
                  </div>
                  <div className="form-group">
                    <label className={`font-weight-bold mb-2 mt-4 ${membershipState?.name === "Basic" ? 'text-muted' : null}`}>
                      {languageState.translation.COMMON.CHOOSE_TEMPLATE}
                    </label>
                    <Select
                      options={messageTemlates}
                      isSearchable={false}
                      isDisabled={membershipState?.name === "Basic"}
                      onChange={(selected) => {
                        handleInputChange('personalMessage', selected?.value)
                        handleInputChange('template', selected)
                      }}
                      placeholder={languageState.translation.COMMON.SELECT_TEMPLATE}
                      className="react-select-container "
                      classNamePrefix="react-select"
                    />
                    {membershipState?.name === "Basic" && <p className="font-weight-bold text-red small mt-2">
                      {languageState.translation.COMMON.TEMPLATE_ERROR}                    </p>}

                  </div>

                  <p className="font-weight-bold mb-2">
                    {languageState.translation.COMMON.PERSONAL_MESSAGE_FOR_THE_JOBPOSTER_LABEL}<span className='text-red'> *</span>
                  </p>

                  <ExEditor
                    key={applicationState.template?.label}
                    onChangeContent={(html) => handleInputChange('personalMessage', html)}
                    className="mb-4 mt-2"
                    name="personalMessage"
                    initialContent={isApplied ? application?.form : applicationState.personalMessage}
                  />

                  {applicationState.touched.personalMessage && !isPersonalMessageValid && (
                    <p className="text-red">
                      {languageState.translation.COMMON.MESSAGE_30_CARACTER}
                    </p>
                  )}

                  <div className="form-group mt-4">
                    <label className="font-weight-bold mb-2">
                      {languageState.translation.COMMON.SUGGEST_RATE}
                    </label>
                    <div className='d-flex align-items-center justify-content-between gap-1'>
                      <InputGroup className='w-50'>
                        <InputGroupAddon addonType="prepend" className='h-40'>
                          <InputGroupText>
                            <i className="fas fa-credit-card" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className={`form-control ${applicationState.touched.rate && !isRateValid ? 'is-invalid' : ''
                            }`}
                          onChange={(e) => handleInputChange('rate', parseFloat(e.target.value))}
                          placeholder={
                            languageState.translation.COMMON
                              .HOURLY_RATE
                          }
                          type="number"
                          name="hourlyRate"
                          value={isApplied ? application?.rate : applicationState?.rate}
                        />
                        <InputGroupAddon addonType="append" className='h-40'>
                          <InputGroupText>
                            <small className="font-weight-bold">
                              EUR
                            </small>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <Select
                        placeholder={languageState.translation.STEPPER.FIRST_STEP_PLACEHOLDERS.WORK_PREFERENCE}
                        options={workPreferations.map((el) => {
                          return {
                            label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[el],
                            value: el,
                          }
                        })}
                        name="paymentType"
                        value={application?.rateType ? {
                          label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[application?.rateType],
                          value: application?.rateType
                        } : applicationState.paymentType ? {
                          label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[applicationState.paymentType],
                          value: applicationState.paymentType
                        } : null}
                        onChange={handlePaymentTypeChange}
                        className='w-50'
                      />
                    </div>
                    {applicationState.touched.rate && !isRateValid && (
                      <p className="text-red">Rate must be greater than 5 euros.</p>
                    )}
                  </div>

                  <Row className="invoice-summary w-100" lg={12}>
                    <Row className="invoice-row">
                      <Col lg={8} className="font-weight-bold m-0 p-0 w-75">
                        {languageState.translation.INVOICE.SUBTOTAL}:
                      </Col>
                      <Col lg={4} className="text-right w-25 p-0">
                        {formatCurrency(subtotal)}
                      </Col>
                    </Row>
                    <Row className="invoice-row p-0">
                      <Col lg={10} className="font-weight-bold m-0 p-0 w-75">
                        {languageState.translation.INVOICE.EE_FEES}:
                      </Col>
                      <Col lg={2} className="text-right w-25 p-0">
                        {formatCurrency(eeFees)}
                      </Col>
                    </Row>
                    <Row className="invoice-row">
                      <Col lg={8} className="font-weight-bold m-0 p-0 w-75">
                        {languageState.translation.INVOICE.YOU_RECEIVE}:
                      </Col>
                      <Col lg={4} className="text-right w-25 p-0">
                        {formatCurrency(youReceive)}
                      </Col>
                    </Row>
                  </Row>

                </div>

                <div className="mt-3 d-flex justify-content-end">
                  <Button
                    onClick={() => (isSaved ? deleteSavedJob() : saveJob(id))}
                    color="danger"
                  >
                    <i className="fas fa-bookmark fa-sm mr-1" />{' '}
                    {isSaved
                      ? languageState.translation.COMMON.DELETE_SAVE_JOB
                      : languageState.translation.COMMON.SAVE_JOB}
                  </Button>
                  <Button
                    disabled={!isApplied ?
                      !(
                        !isApplied &&
                        isPersonalMessageValid &&
                        isRateValid &&
                        isRequirementsValid
                      ) : false
                    }
                    onClick={handleApplication}
                    color="danger"
                  >
                    {isApplied
                      ? languageState.translation.COMMON.BUTTONS.CANCEL_APPLICATION
                      : languageState.translation.COMMON.BUTTONS.SEND_APPLICATION}
                  </Button>
                </div>
              </CardBody>

            </Card>
            {/* <Card>
              <CardBody>

                <div className="job-description">
                  <h1>{languageState.translation.COMMON.EXTRA_INFO_ABOUT_THIS_TYPE_JOB}</h1>
                  <p className="job-description-text">
                    {job?.extraInfo}
                  </p>
                </div>
              </CardBody>
            </Card> */}
          </Col>
          <Col lg="5" md="12">
            <Card>
              <CardBody>
                <div className="job-description">
                  <h1>{languageState.translation.COMMON.EXTRA_INFO_ABOUT_THIS_JOB}</h1>
                  <div className='d-flex align-items-center flex-wrap'>
                    {job?.dailyBudget ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.dailyBudget} {languageState.translation.JOBBOARD.EURO_PER_DAY}
                        </p>
                      </div>
                    ) : null}
                    {/*  {job?.missionLocation ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.missionLocation}
                        </p>
                      </div>
                    ) : null} */}
                    {job?.nbViews ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.nbViews} {languageState.translation.JOBBOARD.VIEWS}
                        </p>
                      </div>
                    ) : null}

                    {/* {job?.company ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {languageState.translation.COMMON.COMPANY} {job.company}
                        </p>
                      </div>
                    ) : null} */}
                    {job?.country ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.country}
                        </p>
                      </div>
                    ) : null}
                    {job?.city ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.city}
                        </p>
                      </div>
                    ) : null}
                    {job?.missionCategories?.length ? (
                      job.missionCategories.map(el => (
                        <div key={el.id} className="skill mr-2 mb-2">
                          <p className="skill-text">
                            {el.label}
                          </p>
                        </div>
                      ))
                    ) : null}
                    {job?.hoursPerWeek ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {hoursPerWeekOptions.find((item) => item.value === job.hoursPerWeek)?.label} {languageState.translation.COMMON.PER_WEEK}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </CardBody>
            </Card>
            {
              isApplied ?
                <div>
                  <Card>
                    <CardBody>
                      <Label className='mt-2 font-weight-bold'>{languageState.translation.JOBBOARD.DEAL_STAGE}</Label>
                      <Input
                        className='mt-2 font-weight-bold'
                        type='text'
                        placeholder={application?.status}
                        disabled
                      />
                      <Label className='mt-2 font-weight-bold'>{languageState.translation.COMMON.ASSIGN_POTENTIAL_REVENUE}</Label>
                      <Input
                        className='mt-2'
                        type='text'
                        placeholder={`€ ${application?.estimatedRevenue ? application?.estimatedRevenue : 0}`}
                        pattern="^€\s.*"
                        onChange={(e) => setEstimation(e.target.value)}
                      />

                      <Label className='mt-2 font-weight-bold'>{languageState.translation.JOBBOARD.ASSIGN_ALIAS}</Label>
                      <Input
                        className=''
                        placeholder={`${application?.alias ? application?.alias : ""}`}
                        onChange={(e) => setAlias(e.target.value)}
                      />
                      <Button color="primary" outline className="w-100 mt-2 shadow-none" onClick={handleUpdateExtraData}>
                        {languageState.translation.COMMON.BUTTONS.SAVE}
                      </Button>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <h1>{languageState.translation.JOBBOARD.HISTORY}</h1>
                        {
                          !isAddingNote &&
                          <Button color="exprimary" onClick={handleAddNoteClick}>{languageState.translation.COMMON.ADD_NOTE}</Button>
                        }
                      </div>
                      {isAddingNote && (
                        <div>
                          <Input
                            className='mt-2'
                            type="textarea"
                            rows="4"
                            value={note}
                            onChange={(e) => handleNoteContentChange(e.target.value)}
                            autoFocus
                          />
                          <Button className='w-2 mt-2' color='primary' onClick={handleSaveNote}>{languageState.translation.COMMON.ADD_NOTE}</Button>
                        </div>
                      )}
                      <div className='notes-card mt-3'>
  <div>
    <div className=''>
      {notes.length > 0 ? (
        notes.map((note, index) => (
          <div key={index} className="note-item d-flex justify-content-between align-items-start mb-1">
            <div className="flex-grow-1 overflow-hidden">
              <p className="note-content" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                {note.note}
              </p>
              <small className='text-muted'>
                <p className="mb-0">{moment(note.created_at).format("DD-MM-YYYY HH:mm")}</p>
              </small>
            </div>
            <button
              className="ms-2 border-0 bg-transparent"
              onClick={() => handleDeleteNote(note?.id)}
            >
              x
            </button>
          </div>
        ))
      ) : (
        <p className="text-muted">No notes available.</p>
      )}
    </div>
  </div>
</div>

                    </CardBody>
                  </Card>
                </div> : ''
            }
            {job?.similarJobs?.length ?
              (<>
                <h1 className='mt-0'>{languageState.translation.COMMON.SIMILAR_OPEN_POSITION}</h1>
                {job.similarJobs.slice(0, 3).map((job, index) => (
                  <Card key={`job-${index}`}>
                    <JobCard
                      {...job}
                      openDetails={openDetails}
                      isPublished
                      saveJob={saveJob}
                      jobSaved={jobSaved && jobSaved.find(el => el.mission.id === job.id)}
                    />
                  </Card>
                ))}
              </>) : null
            }
          </Col>
        </Row>
      </Container>
      <UpgradePremiumModal
        modalShow={showSubscriptionModal}
        closeModal={() => setShowSubscriptionModal(false)}
      />
    </JobsContainer>
  );
};

export default JobDetails;