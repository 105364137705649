import { useContext } from 'react';
import { AppContext } from 'store/app.context';
import HOME_STATE from 'store/home/home.state';
import useHttpClient from '../http-client-hook';
import NEWS_STATE from 'store/news/news.state';
import moment from 'moment';

const useHomeService = () => {
  const { httpClient } = useHttpClient();
  const { dispatchHomeState, dispatchNewsState } = useContext(AppContext);

  const getQuickLinks = async () => {
    const result = await httpClient.get(`/quick-links`);
    if (result && result.status == 200) {
      dispatchHomeState({
        type: HOME_STATE.types.SET_QUICKLINKS,
        quickLinks: result?.data,
      });
    }
  };

  const getNbNewJob = async () => {
    const result = await httpClient.get('/jobs');
    if (result && result.status == 200) {
      dispatchHomeState({
        type: HOME_STATE.types.SET_NB_NEW_JOBS,
        nbNewJobs: result.data.meta.itemCount,
      });
    }
  }
  const parseRSSFeed = (rssJson) => {
    return rssJson.items.map((item) => {
      let content;
      let categories;
      if (item?.enclosure?.type) {
        const { url, type } = item.enclosure;
        if (type?.includes("image")) {
          content = <img src={url} alt={item.title} width={'90%'} height={'90%'}/>;
        } else if (type?.includes("video")) {
          content = (
            <video width={'90%'} height={'90%'} controls>
              <source src={url} type={type} />
            </video>
          );
        }
      }
if(item?.categories){
  categories = item?.categories.map((cat, index) => {
    return (
      <span key={index}>{cat}</span>    )
  })
}
      return {
        categories,
        title: item.title,
        subtitle: item.category || "",
        content,
        moreInfoLink: item.link,
        pubDate: moment(item.pubDate).format("DD MMMM YYYY"),
        description: item.contentSnippet,
        language:item.language,
        cta: item.cta,
      };
    });
  };

  const getNews = async () => {
    const response = await httpClient('/dashboard/news-feed')
    dispatchNewsState({
      type: NEWS_STATE.types.SET_NEWS,
      news: {items: parseRSSFeed(response.data), count: response.data.items.length}
    })
  }

  return {
    getQuickLinks,
    getNbNewJob,
    getNews
  };
};
export default useHomeService;
