import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Button, Card, CardHeader, CardBody, FormGroup, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, Input, Label, Tooltip } from 'reactstrap';
import AuthHeader from 'components/layout/header/auth-header';
import { AppContext } from 'store/app.context';
import { Formik, Field, Form } from 'formik';
import schema from './schema';
import Swal from 'sweetalert2';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useAuthService from 'services/auth/auth.service';
import SHARED_STATE from 'store/shared';
import './index.scss'
import Select from 'react-select';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import nl from 'i18n-iso-countries/langs/nl.json';

import { CSSTransition } from "react-transition-group";
import useUserService from 'services/profile/user.service';
import { IMG_PREFIX } from 'config/core';

countries.registerLocale(en);
countries.registerLocale(nl);

const Register = () => {
  const { signup } = useAuthService();
  const { search } = useLocation();
  const history = useHistory();

  const { languageState, dispatchSharedState } = useContext(AppContext);
  const { checkIsValidEUVat, getSubscriptions } = useUserService();
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [emailAccepted, setEmailAccepted] = useState(false)
  const [agreeAcceptEmail, setAgreeAcceptEmail] = useState(false);
  const [focusedFirstName, setfocusedFirstName] = useState(false);
  const [focusedLastName, setfocusedLastName] = useState(false);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [focusedConfirmPassword, setfocusedConfirmPassword] = useState(false);
  const [kindError, setKindError] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [step, setStep] = useState(1);
  const [tooltipAdresses, setTooltipAdresses] = useState(false);
  const emailInputRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);

  const translatedCountries = countries.getNames(languageState?.defaultLanguage);
  const EnglishCountries = countries.getNames("EN")

  const englishCountryOption = Object.entries(EnglishCountries).map(([code, name]) => ({
    value: code,
    label: name
  }));
  const countryOptions = Object.entries(translatedCountries).map(([code, name]) => ({
    value: code,
    label: name
  }));

  const [isLoading, setIsLoading] = useState(false)
  const [isValidVat, setIsValidVat] = useState(true)
  const [hasVat, setHasVat] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    VATNumber: '',
    companyName: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    country: languageState.defaultLanguage === "NL" ? "België" : 'Belgium',
    countryCode: 'BE',
    freelancersEmails: ''
  })
  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await getSubscriptions();
        setSubscriptionList(response)
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    };
    fetchSubscriptions();
    window.history.pushState(null, "", `/register/step-${step == 2 ? "two" : "one"}`);
  }, []);
  const subscriptions = useMemo(() => subscriptionList, [subscriptionList]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const subscriptionType = queryParams.get('subscription_type') ? queryParams.get('subscription_type') : "";
    if (subscriptionType) {
      const matchedSub = subscriptions.find(sub => sub.type === subscriptionType)
      if (matchedSub) {
        setSelectedCategory(matchedSub.userCategory);
        setSelectedUserType(matchedSub);
      }
    }
  }, [subscriptionList]);

  function isValidVAT(input) {
    const cleanedInput = input.replace(/[\s.]+/g, '');
    const vatRegex = /^[A-Z]{2}[A-Z0-9]{10,12}$/;
    return { valid: vatRegex.test(cleanedInput), cleaned: cleanedInput };
  }

  const checkValidEUVat = async (vat) => {
    const countryCode = vat.slice(0, 2);
    const vatCode = vat.slice(2);
    setIsLoading(true);
    const response = await checkIsValidEUVat(countryCode, vatCode);
    setIsValidVat(response.valid !== undefined ? response.valid : true)

    if (response.valid) {
      const parsedAddress = parseAddress(response?.address);

      setFieldValues((prev) => ({
        ...prev,
        companyName: response.name,
        street: parsedAddress.street,
        houseNumber: parsedAddress.houseNumber,
        postalCode: parsedAddress.postalCode,
        city: parsedAddress.city,
      }));

    }

    setIsLoading(false);
  };

  function parseAddress(input) {
    if (input) {

      const [firstLine, secondLine] = input.split('\n');
      const firstSpaceIndex = firstLine.indexOf(' ');

      const street = firstLine.substring(0, firstSpaceIndex).trim();
      const houseNumber = firstLine.substring(firstSpaceIndex + 1).trim();
      const postalCodeEndIndex = secondLine.indexOf(' ');

      const postalCode = secondLine.substring(0, postalCodeEndIndex).trim();
      const city = secondLine.substring(postalCodeEndIndex + 1).trim();
      return { street, houseNumber, postalCode, city, country: "" };
    }
    return {
      street: "", houseNumber: "", postalCode: "", city: "", country: ""
    }
  }


  const onChangePrivacy = () => {
    if (!privacyAccepted) {
      setShowPrivacy(false);
    }
    setPrivacyAccepted(!privacyAccepted);
  };

  const onChangeGDPR = () => {
    if (!emailAccepted) {
      setAgreeAcceptEmail(false)
    }
    setEmailAccepted(!emailAccepted)
  }

  const submitForm = async (values) => {
    let englishCountry =  englishCountryOption.find((country) => fieldValues.countryCode === country.value)


    const requestData = {
      name: `${fieldValues.firstName} ${fieldValues.lastName}`,
      firstName: fieldValues.firstName,
      lastName: fieldValues.lastName,
      email: fieldValues.email,
      password: fieldValues.password,
      kind: selectedCategory,
      language: languageState && languageState.defaultLanguage,
      receiveEmail: emailAccepted,
      addressDetails: {
        companyName: fieldValues.companyName,
        VATNumber: hasVat && isValidVAT(fieldValues.VATNumber).valid ? isValidVAT(fieldValues.VATNumber).cleaned : "",
        street: fieldValues.street,
        country: englishCountry.label,
        postalCode: fieldValues.postalCode,
        houseNumber: fieldValues.houseNumber,
        city: fieldValues.city,
        countryCode: englishCountry.value,
        type: "BILLING"
      }
    };
    if (!selectedCategory && !selectedUserType) {
      setKindError(true);
      return;
    }
    if (selectedUserType && selectedUserType.stripePriceId) {
      requestData.subPriceId = selectedUserType.stripePriceId;
    }
    if (!privacyAccepted) {
      setShowPrivacy(true);
      return;
    }

    const result = await signup(requestData);
    if (result?.data?.status === 409) {
      dispatchSharedState({
        type: SHARED_STATE.types.SET_MODAL_SHARED_OPEN,
        msg:
          result?.data?.message ||
          languageState.translation.COMMON.ERRORS.ERROR,
      });
      return;
    }

    if (result && result.status === 201) {
      Swal.fire(
        languageState.translation.ALERT.SUCCESS_REGISTER,
        selectedCategory === "FREELANCER" ? languageState.translation.ALERT.VERIF_INBOX : '',
        'success'
      ).then(() => {
        if (selectedCategory !== "FREELANCER") {
          history.push({
            pathname: '/login',
            state: { email: fieldValues.email }
          });
        } else {
          history.push('/login');
        }
      });
    }
  };

  return (
    <div className="auth-container auth-register-container">
      <Row className="m-0">
        <Col lg="12" className="p-0">
          <AuthHeader title={languageState.translation.AUTH.SIGNUP.CREATE_AN_ACCOUNT}
          />
          <Container fluid className="register-container">
            <Row className='d-flex justify-content-center m-0'>
              <Col lg="8" md="12" className="m-0 h-full">
                <Formik
                  initialValues={fieldValues}
                  enableReinitialize
                  validationSchema={schema(
                    languageState.translation.FORM_VALIDATION
                  )}
                  onSubmit={(values) => {
                    submitForm(values)
                  }}
                >
                  {({ errors, touched, handleBlur, setFieldValue }) => (
                    <Row className="justify-content-center w-100 m-0">
                      <Col lg="12" md="12">
                        <Card className="bg-secondary border-0 wrapper h-100 mb-0">
                          <CardHeader className="bg-transparent border-0">
                            {step == 1 ? <div className="text-dark text-left mt-2">
                              <h3>
                                {
                                  languageState.translation.AUTH.SIGNUP
                                    .SIGNUP_TYPE
                                }
                              </h3>
                            </div> : null}
                          </CardHeader>
                          <CardBody className="px-lg-2 py-lg-2 ">
                            <CSSTransition in={step === 1} timeout={300} classNames="fade" unmountOnExit>
                              <div className="user-selection-container step">
                                <div className='main-user-types'>
                                  {[...new Set(
                                    subscriptions
                                      .filter(sub => sub.userCategory !== "RECRUITER")
                                      .map(sub => sub.userCategory)
                                  )]
                                    .filter(category => !selectedCategory || selectedCategory === category)
                                    .map(category => (
                                      <button
                                        key={category}
                                        className={`main-type-card ${selectedCategory === category ? "selected-category" : ""}`}
                                        onClick={() => {
                                          setSelectedCategory(category)
                                          setSelectedUserType(subscriptions.find((cat) => cat.userCategory === category))
                                        }
                                        }
                                      >
                                        {category}
                                      </button>
                                    ))}
                                </div>
                                <div className="main-user-types flex-column">
                                  {selectedCategory && (
                                    <div className="subtype-container">
                                      {subscriptions
                                        .filter((cat) => cat.userCategory === selectedCategory)
                                        .map((userType) => (
                                          <div
                                            key={userType.type}
                                            className={`user-type-card`}
                                            style={{
                                              border: `2px solid ${selectedUserType?.type === userType.type ? "#FA5252" : ""}`
                                            }}
                                            onClick={() => {
                                              setSelectedUserType(userType)
                                              setKindError(false)
                                            }}
                                          >
                                            <img
                                              src={`${IMG_PREFIX}${userType.image}`}
                                              alt={userType.name}
                                              style={{ width: "100%", height: "150px", objectFit: "cover", borderRadius: "8px" }}
                                            />
                                            <h4 className="">{userType.name}</h4>
                                            <p>
                                              <strong>{userType.subTitle}</strong>
                                            </p>
                                            <p>€{userType.price}/{languageState.translation.COMMON.MONTH}</p>
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                </div>
                                {selectedUserType && (
                                  <div className=''>
                                    <button className="next-button d-flex align-items-center gap-1 float-right" onClick={() => {
                                      setStep(2);
                                      window.history.pushState(null, "", `/register/step-${step == 1 ? "two" : "one"}`);
                                    }}>{languageState.translation.AUTH.REGISTER.REGISTRATION_NEXT_STEP}<i className="fas fa-arrow-right"></i></button>
                                  </div>
                                )}
                              </div>
                            </CSSTransition>
                            <CSSTransition in={step === 2} timeout={300} classNames="fade" unmountOnExit>
                              <Form className='step'>
                                <button className="next-button d-flex align-items-center gap-1 ml-3 mb-3" type="button" onClick={() => {
                                  setStep(1);
                                  window.history.pushState(null, "", `/register/step-${step == 1 ? "two" : "one"}`);
                                }}><i className="fas fa-arrow-left"></i>{languageState.translation.COMMON.PREVIOUS}</button>

                                <div className="row m-0">
                                  <Col lg="12" className=' mb-1'>
                                    <h2 >{languageState.translation.AUTH.REGISTER.ACCOUNT_DETAILS}</h2>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup
                                      className={classnames({
                                        focused: focusedFirstName,
                                      })}
                                    >
                                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                                        <Field
                                          className="form-control"
                                          placeholder={
                                            languageState.translation.COMMON
                                              .FIRST_NAME
                                          }
                                          type="text"
                                          name="firstName"
                                          value={fieldValues.firstName}
                                          onChange={(e) => {
                                            const { selectionStart } = e.target;
                                            setFieldValues({
                                              ...fieldValues,
                                              firstName: e.target.value
                                            })
                                            setTimeout(() => {
                                              if (firstNameRef.current) {
                                                firstNameRef.current.setSelectionRange(selectionStart, selectionStart);
                                              }
                                            }, 0);
                                          }}
                                          onFocus={() =>
                                            setfocusedFirstName(true)
                                          }
                                          onBlur={() =>
                                            setfocusedFirstName(false)
                                          }
                                        />
                                      </InputGroup>
                                      {errors.firstName && touched.firstName && (
                                        <div className="invalid-feedback">
                                          {errors.firstName}
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup
                                      className={classnames({
                                        focused: focusedLastName,
                                      })}
                                    >
                                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                                        <Field
                                          className="form-control"
                                          placeholder={
                                            languageState.translation.COMMON
                                              .LAST_NAME
                                          }
                                          type="text"
                                          name="lastName"
                                          value={fieldValues.lastName}
                                          onChange={(e) => {
                                            const { selectionStart } = e.target;
                                            setFieldValues({
                                              ...fieldValues,
                                              lastName: e.target.value
                                            })
                                            setTimeout(() => {
                                              if (lastNameRef.current) {
                                                lastNameRef.current.setSelectionRange(selectionStart, selectionStart);
                                              }
                                            }, 0);
                                          }}
                                          onFocus={() => setfocusedLastName(true)}
                                          onBlur={() => setfocusedLastName(false)}
                                        />
                                      </InputGroup>
                                      {errors.lastName && touched.lastName && (
                                        <div className="invalid-feedback">
                                          {errors.lastName}
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="12">
                                    <FormGroup
                                      className={classnames({
                                        focused: focusedEmail,
                                      })}
                                    >
                                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                          <InputGroupText>
                                            <i className="ni ni-email-83" />
                                          </InputGroupText>
                                        </InputGroupAddon>
                                        <Field
                                          className="form-control"
                                          placeholder={
                                            languageState.translation.COMMON.EMAIL
                                          }
                                          type="email"
                                          name="email"
                                          value={fieldValues.email}
                                          onChange={(e) => {
                                            const { selectionStart } = e.target;
                                            setFieldValues({
                                              ...fieldValues,
                                              email: e.target.value
                                            })
                                            setTimeout(() => {
                                              if (emailInputRef.current) {
                                                emailInputRef.current.setSelectionRange(selectionStart, selectionStart);
                                              }
                                            }, 0);
                                          }}
                                          onFocus={() => setfocusedEmail(true)}
                                          onBlur={() => setfocusedEmail(false)}
                                        />
                                      </InputGroup>
                                      {errors.email && touched.email && (
                                        <div className="invalid-feedback">
                                          {errors.email}
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="12">
                                    <FormGroup
                                      className={classnames({
                                        focused: focusedPassword,
                                      })}
                                    >
                                      <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                          <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                          </InputGroupText>
                                        </InputGroupAddon>
                                        <Field
                                          className="form-control"
                                          placeholder={
                                            languageState.translation.COMMON
                                              .PASSWORD
                                          }
                                          type="password"
                                          name="password"
                                          onChange={(e) => {
                                            setFieldValues({
                                              ...fieldValues,
                                              password: e.target.value
                                            })
                                          }}
                                          onFocus={() =>
                                            setfocusedPassword(true)
                                          }
                                          onBlur={() =>
                                            setfocusedPassword(false)
                                          }
                                        />
                                      </InputGroup>
                                      {errors.password && touched.password && (
                                        <div className="invalid-feedback">
                                          {errors.password}
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="12">
                                    <FormGroup
                                      className={classnames({
                                        focused: focusedConfirmPassword,
                                      })}>
                                      <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                          <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                          </InputGroupText>
                                        </InputGroupAddon>
                                        <Field
                                          className="form-control"
                                          placeholder={
                                            languageState.translation.COMMON
                                              .CONFIRM_PASSWORD
                                          }
                                          type="password"
                                          name="confirmPassword"
                                          onChange={(e) => {
                                            setFieldValues({
                                              ...fieldValues,
                                              confirmPassword: e.target.value
                                            })
                                          }}
                                          onFocus={() =>
                                            setfocusedConfirmPassword(true)
                                          }
                                          onBlur={() =>
                                            setfocusedConfirmPassword(false)
                                          }
                                        />
                                      </InputGroup>
                                      {errors.confirmPassword &&
                                        touched.confirmPassword && (
                                          <div className="invalid-feedback">
                                            {errors.confirmPassword}
                                          </div>
                                        )}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="12" className='mb-1'>
                                    <h2 >{languageState.translation.AUTH.REGISTER.COMPANY_ADDRESS}</h2>
                                  </Col>
                                  <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <div className='d-flex text-dark my-3'>
                                      {languageState.translation.STEPPER.BASIC_INFO_SECTION_4}
                                      <div id="TooltipAdresses">
                                        <i className='fa fa-info-circle ml-2 cursor-pointer' />
                                      </div>
                                      <Tooltip
                                        isOpen={tooltipAdresses}
                                        target="TooltipAdresses"
                                        toggle={() => {
                                          setTooltipAdresses(!tooltipAdresses)
                                        }}
                                      >
                                        {languageState.translation.STEPPER.ADDRESSES_INFO}
                                      </Tooltip>
                                    </div>
                                    <div className="vat-toggle-card">
                                      <Input
                                        type="checkbox"
                                        id="hasVatCheckbox"
                                        checked={hasVat}
                                        onChange={(e) => setHasVat(e.target.checked)}
                                      />
                                      <Label htmlFor="hasVatCheckbox">
                                        {languageState.translation.COMMON.HAVE_VALID_VAT}
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col xs="12" sm="12" md="6" lg="6" xl="6"  >
                                    <Row lg={12} className='d-flex justify-content-between'>
                                      <Col lg={hasVat ? 5 : 12} md={12}>
                                        <Select
                                          options={countryOptions}
                                          className={`select-country ${errors.country && touched.country ? "error-input" : ""}`}
                                          value={countryOptions.find((el) => el.label === fieldValues.country)}
                                          placeholder={languageState.translation.PROFILE.COUNTRY}
                                          onChange={(e) => {
                                            setFieldValues({
                                              ...fieldValues,
                                              country: e.label,
                                              countryCode: e.value
                                            });
                                            setFieldValue("country", e.label);
                                          }}
                                          styles={{
                                            container: (base) => ({
                                              ...base,
                                              width: '100%',
                                              height: '100%'
                                            }),
                                            control: (base) => ({
                                              ...base,
                                              width: "100%",
                                            }),
                                          }}
                                        />
                                      </Col>
                                      {hasVat && <Col lg={7} md={12}>
                                        <div className='d-flex align-items-center'>
                                          <Input
                                            className={`${errors.VATNumber || !isValidVat ? "error-input" : ""}`}
                                            placeholder={languageState.translation.PROFILE.VAT_NUMBER}
                                            name="VATNumber"
                                            value={fieldValues.VATNumber}
                                            onChange={(e) => {
                                              const vatValue = e.target.value;
                                              const vatPrefix = vatValue.slice(0, 2);
                                              const matchingCountry = countryOptions.find((option) => option.value === vatPrefix);
                                              setFieldValues({
                                                ...fieldValues,
                                                VATNumber: vatValue,
                                                country: matchingCountry ? matchingCountry.label : "",
                                              });
                                              setFieldValue("VATNumber", vatValue.trim());
                                              setFieldValue("country", matchingCountry ? matchingCountry.label : "");
                                              const { valid, cleaned } = isValidVAT(vatValue.trim());
                                              setIsValidVat(valid);
                                              if (valid) {
                                                checkValidEUVat(cleaned);
                                              }
                                            }}
                                          />
                                          {isLoading && (
                                            <div className="spinner-border text-primary" style={{
                                              width: '1rem',
                                              height: '1rem',
                                              marginLeft: '-1.5rem'
                                            }}>
                                            </div>
                                          )}
                                        </div>
                                        <small className="text-red">
                                          {(errors && errors.VATNumber && touched.VATNumber && errors.VATNumber || !isValidVat) && <span className="visually-hidden text-red">Not a valid EU VAT</span>}
                                        </small>
                                      </Col>}
                                    </Row>
                                  </Col>
                                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <FormGroup>
                                      <Input
                                        className={`w-100 ${errors.companyName && touched.companyName ? "error-input" : ""}`}
                                        placeholder={languageState.translation.PROFILE.COMPANY_NAME}
                                        value={fieldValues.companyName}
                                        name="companyName"
                                        onChange={(e) => {
                                          setFieldValue("companyName", e.target.value)
                                          setFieldValues({ ...fieldValues, companyName: e.target.value })
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <FormGroup>
                                      <Input
                                        className={`w-100 ${errors.street && touched.street ? "error-input" : ""}`}
                                        placeholder={languageState.translation.PROFILE.STREET}
                                        value={fieldValues.street}
                                        name="street"
                                        onChange={(e) => {
                                          setFieldValue("street", e.target.value)
                                          setFieldValues({ ...fieldValues, street: e.target.value })
                                        }} />
                                    </FormGroup>
                                  </Col>
                                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <FormGroup>
                                      <Input
                                        className={`w-100 ${errors.houseNumber && touched.houseNumber ? "error-input" : ""}`}
                                        placeholder={languageState.translation.PROFILE.HOUSENUMBER}
                                        value={fieldValues.houseNumber}
                                        name="houseNumber"
                                        onChange={(e) => {
                                          setFieldValue("houseNumber", e.target.value)
                                          setFieldValues({ ...fieldValues, houseNumber: e.target.value })
                                        }} />
                                    </FormGroup>
                                  </Col>
                                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <FormGroup>
                                      <Input
                                        className={`w-100 ${errors.postalCode && touched.postalCode ? "error-input" : ""}`}
                                        placeholder={languageState.translation.PROFILE.POSTAL_CODE}
                                        value={fieldValues.postalCode}
                                        name="postalCode"
                                        onChange={(e) => {
                                          setFieldValues({ ...fieldValues, postalCode: e.target.value })
                                          setFieldValue("postalCode", e.target.value)
                                        }} />
                                      <small className="text-red">
                                        {errors && errors.postalCode && touched.postalCode && errors.postalCode}
                                      </small>
                                    </FormGroup>
                                  </Col>
                                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <FormGroup>
                                      <Input
                                        className={`w-100 ${errors.city && touched.city ? "error-input" : ""}`}
                                        placeholder={languageState.translation.COMMON.CITY}
                                        value={fieldValues.city}
                                        name="city"
                                        onChange={(e) => {
                                          setFieldValues({ ...fieldValues, city: e.target.value })
                                          setFieldValue("city", e.target.value)
                                        }}
                                        onBlur={handleBlur}
                                      />
                                      <small className="text-red">
                                        {errors && errors.city && touched.city && errors.city}
                                      </small>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="12">
                                    <Row className="my-4">
                                      <Col xs="12">
                                        <div className="custom-control custom-control-alternative custom-checkbox">
                                          <input
                                            className="custom-control-input"
                                            id="customCheckRegister"
                                            type="checkbox"
                                            onChange={() => onChangePrivacy()}
                                          />
                                          <label
                                            className="custom-control-label h-auto"
                                            htmlFor="customCheckRegister"
                                          >
                                            <span className="text-muted text-prewrap">
                                              {
                                                languageState.translation.AUTH
                                                  .SIGNUP.AGREE_GENERAL_CONDITIONS
                                              }

                                            </span>
                                          </label>
                                          <a
                                            className='ml-1 text-muted text-danger text-underline text-prewrap'
                                            target="_blank"
                                            href="https://extraexpertise.be/algemene-voorwaarden.html"

                                          >
                                            {
                                              languageState.translation.COMMON
                                                .GENERAL_CONDITIONS
                                            }

                                          </a>

                                          <span className="text-muted text-prewrap">
                                            {
                                              languageState.translation.AUTH
                                                .SIGNUP.AGREE_PRIVACY_POLICY
                                            }

                                          </span>

                                          <a
                                            className='ml-1 text-muted text-prewrap text-danger text-underline'
                                            target="_blank"
                                            href="https://extraexpertise.be/privacy-verklaring.html"

                                          >
                                            {
                                              languageState.translation.COMMON
                                                .PRIVARY_POLICY
                                            }

                                          </a>

                                        </div>
                                        {showPrivacy && (
                                          <div className="invalid-feedback text-prewrap">
                                            {
                                              languageState.translation
                                                .FORM_VALIDATION.PRIVACY_POLICY
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg="12">
                                    <div className="custom-control custom-control-alternative custom-checkbox">
                                      <input
                                        className="custom-control-input"
                                        id="customCheckRegisterGDPR"
                                        type="checkbox"
                                        onChange={() => onChangeGDPR()}
                                      />
                                      <label
                                        className="custom-control-label h-auto"
                                        htmlFor="customCheckRegisterGDPR"
                                      >
                                        <span className="text-muted text-prewrap">
                                          {languageState.translation.AUTH.SIGNUP.GDPR}
                                        </span>
                                      </label>
                                    </div>
                                    {/* {agreeAcceptEmail && (
                                    <div className="invalid-feedback text-prewrap">
                                      {
                                        languageState.translation
                                          .FORM_VALIDATION.GDPR
                                      }
                                    </div>
                                  )} */}
                                  </Col>
                                  <Col lg="12">
                                    <div className="text-center">
                                      <Button
                                        className="mt-4 d-flex align-items-center mr-1  float-right"
                                        color="danger"
                                        type="submit"
                                        disabled={hasVat && !isValidVat}
                                      >
                                        {
                                          selectedUserType?.type === "FREELANCER_BASIC"
                                            ? languageState.translation.AUTH.REGISTER.CREATE_FREE_ACCOUNT
                                            : selectedUserType?.type === "FREELANCER_EXPERT"
                                              ? languageState.translation.AUTH.REGISTER.FREE_TRIAL
                                              : languageState.translation.AUTH.SIGNUP
                                                .CREATE_ACCOUNT

                                        }
                                      </Button>
                                    </div>
                                  </Col>
                                </div>
                              </Form>
                            </CSSTransition>
                          </CardBody>
                        </Card>
                        <Row className="mt-2">
                          <Col xs="12">
                            <span>
                              {languageState.translation.AUTH.SIGNIN.SIGNIN}{' '}
                            </span>
                            <Link
                              className="text-danger"
                              to="/login"
                              onClick={(e) => history.push('/login')}
                            >
                              {' '}
                              <span> {languageState.translation.AUTH.SIGNIN.LOGINLINK}{' '}</span>
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Formik>
              </Col>
              {selectedUserType && (
                <Col lg="4" md="12"
                  className={`pricing-card`}
                  style={{
                    border: `2px solid #FA5252`,
                    color: "#FA5252",
                  }}
                >
                  <div className="pricing-header">
                    <h3 className="mt-1">{selectedUserType.name}</h3>
                    <span className={`pricing-badge`} style={{
                      backgroundColor: "#FA5252"
                    }}>
                      €{selectedUserType.price}/{languageState.translation.COMMON.MONTH}
                    </span>

                    <p className="pricing-subtitle">
                      <strong>{selectedUserType.subTitle}</strong>
                    </p>
                    <p className="pricing-description">{selectedUserType.description}</p>
                  </div>

                  <div className="pricing-features">
                    <h5>{languageState.translation.AUTH.REGISTER.YOU_GET}</h5>
                    <ul>
                      {selectedUserType.features.map((item, index) => (
                        <li key={index}>
                          <i className="ni ni-check-bold" />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
