import TYPES from './news.types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_NEWS: {
      return { ...state, news: action.news };
    }
  }
};

export default reducer;
