import classNames from 'classnames';
import CardHeaderConnect from 'components/cards/card-header-connect';
import UserExperiences from 'components/experiences/user-experiences';
import LanguageList from 'components/languages-list/language-list';
import ListNotes from 'components/notes/ListNotes';
import { IMG_PREFIX } from 'config/core';
import { CURRENCY, MODULES } from 'config/core';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Media, Row } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';
import CONNECT_STATE from 'store/connect/connect.state';
import SHARED_STATE from 'store/shared';
import { isAuthorized } from 'utils';
import { getDuration } from 'utils';
import ExtraDetails from 'components/extra-details';
import { Helmet } from "react-helmet";
import { isNil, upperFirst } from 'lodash';
import './index.scss'
import CardHeaderConnectFromMission from 'components/cards/card-header-connect-from-mission';
import useMissionService from 'services/missions/mission.service';
import { ProfileHeaderCard } from 'components/profileHeader/profileHeader.jsx';
import Resume from '../resume';

const ProfileDetails = () => {
  const { dispatchSharedState, dispatchConnectState, languageState, userState, missionState } = useContext(AppContext);
  const { getExperiencesByUser, getEducationsByUser, getLanguagesByUser, getUserById } = useConnectService();
  const { getMissionById } = useMissionService();
  const [userId, setUserId] = useState('');
  const [user, setUser] = useState(null);
  const [experiences, setExperiences] = useState(null);
  const [educations, setEducations] = useState(null);
  const [languages, setLanguages] = useState(null);
  const defaultComission = 1.1;
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (location && location.state && location.state.id) {
      getMissionById(location.state.id);
    }
  }, [location])

  useEffect(() => {

    if (id) {
      setUserId(id);
      dispatchConnectState({
        type: CONNECT_STATE.types.SELECT_USER,
        activeUser: id,
      });
    }
    dispatchSharedState({
      module: MODULES.CONNECT_YOUR_NETWORK,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, [id]);

  const getUser = async () => {
    const result = await getUserById(userId);
    if (result && result.status === 200) {
      setUser(result.data);
    }
  };

  const getEducations = async () => {
    const result = await getEducationsByUser(userId);
    if (result && result.status === 200) {
      const mappedEducations = result.data.map((item) => {
        const duration = getDuration(
          item.startDate,
          item.endDate,
          languageState
        );
        return {
          ...item,
          duration,
        };
      });
      setEducations(mappedEducations);
    }
  };

  const getExperiences = async () => {
    const result = await getExperiencesByUser(userId);
    if (result && result.status === 200) {
      const mappedExperiences = result.data.map((item) => {
        const duration = getDuration(
          item.startDate,
          item.endDate,
          languageState
        );

        return {
          ...item,
          duration,
        };
      });
      setExperiences(mappedExperiences);
    }
  };

  const getLanguages = async () => {
    const result = await getLanguagesByUser(userId);
    if (result && result.status === 200) {
      setLanguages(result.data);
    }
  };

  const getExperienceLevel = (level) => {
    if (isNil(level)) {
      return languageState.translation.COMMON.NOT_INDICATED
    }
    else if (level === 0) {
      return languageState.translation.COMMON.LEVELS.BEGINNER
    }
    else if (level < 3) {
      return languageState.translation.COMMON.LEVELS.JUNIOR;
    } else if (level < 5) {
      return languageState.translation.COMMON.LEVELS.INTERMEDIATE;
    } else {
      return languageState.translation.COMMON.LEVELS.SENIOR;
    }
  };

  useEffect(() => {
    if (userId) {
      getUser();
      getExperiences();
      getEducations();
      getLanguages();
    }
  }, [userId]);

  const rateIndication = () => {
    const level = user.experienceYears;
    const key = user.paymentType === 'DAILY_BASED'
      ? "PER_DAY"
      : user.paymentType === 'HOURLY_BASED'
        ? "PER_HOUR"
        : user.paymentType === 'WEEKLY_BASED'
          ? "PER_WEEK"
          : "PER_DAY";

    const calculatedAmount = user.amount
      ? Math.round(parseFloat(user.amount) * parseFloat(defaultComission))
      : 0; // Default to 0 if user.amount is missing

    return ['HOURLY_BASED', 'DAILY_BASED', 'WEEKLY_BASED'].includes(user.paymentType)
      ? `${calculatedAmount} euro ${languageState.translation.COMMON[key]}`
      : (level === 0 ? languageState.translation.COMMON.NOT_INDICATED : '');
  };

  return (
    <>
      {user &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>{languageState.translation.INNER_NAVIGATION.YOUR_NETWORK.PROFILE_DETAILS}</title>
        </Helmet>
      }
      {user && (
        <div className='detail-profile-page'>
          {location && location.state && location.state.fromMission ?
            <CardHeaderConnectFromMission
              name={
                user.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName.charAt(0)}.`
                  : ''
              }
              cover={user.profileCover}
              iduser={userId}
              user={user}
              previousPath={location.state && location.state.previousPath}
            /> :
            <ProfileHeaderCard
              name={
                user.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName.charAt(0)}.`
                  : ''
              }
              //cover={user.profileCover}
              user={user}
              previousPath={location.state && location.state.previousPath}
            />
          }

          {/*!location?.state?.fromMission && (
            <div className="rounded-img-container">
              <img className="rounded-img-profile object-fit-cover"
                src={
                  user?.profileImage
                    ? `${IMG_PREFIX}${user.profileImage}`
                    : 'assets/img/man.png'
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = require('assets/img/man.png').default;
                }}
              />
            </div>
          )*/}
          <Container className="mt-3" fluid>
            <Row>
              <Col className="col-lg-7 col-sm-12 col-md-12">
                {
                  location && location.state && location.state.fromMission && (
                    <Card>
                      <CardHeader className="font-weight-bold fs-2">
                        {languageState.translation.CONNECT.PERSONALISED_INTRODUCTION}
                      </CardHeader>
                      {location.state.user.application.form ? <p className="p-3" dangerouslySetInnerHTML={{ __html: location.state.user.application.form }}></p> : <h5> {languageState.translation.CONNECT.NO_PERSONALISED_INTRODUCTION} </h5>}
                    </Card>
                  )
                }
                <Card>
                  <CardHeader className="font-weight-bold fs-2">
                    {languageState.translation.CONNECT.HARD_SOFT_SKILLS}
                  </CardHeader>
                  <div className="d-flex p-3 wrap wrapper">
                    {(user?.skills || []).map((skill) => (
                      <span className="btn bg-exprimary no-animation" key={skill.id}>
                        {skill.key}
                      </span>
                    ))}

                  </div>
                </Card>
                <Card>
                  <CardHeader className="font-weight-bold fs-2">
                    {languageState.translation.CONNECT.SHORT_INTRO}{' '}
                    {user.firstName && user.lastName
                      ? `${upperFirst(user.firstName)} ${upperFirst(user.lastName.charAt(0))}.`
                      : upperFirst(user.name || "")}
                  </CardHeader>
                  <p className="p-3" dangerouslySetInnerHTML={{ __html: user.aboutMe }}></p>
                </Card>
                {/* {
                  location && location.state && location.state.fromMission && (
                    <Card>
                      <CardHeader className="font-weight-bold fs-2">
                        {languageState.translation.CONNECT.REVIEWS_AND_RATINGS}
                      </CardHeader>
                      <CardBody>
                        {reviewList && reviewList.length ? (
                          <Row className='gap-2'>
                            {reviewList.map(review => (
                              <Col key={review.id} lg={12} md={12}>
                                <div className='d-flex gap-2'>
                                  {review.profileImage
                                    ? (
                                      <span className="avatar avatar-sm rounded-circle">
                                        <img alt="..." src={`${IMG_PREFIX}${review.profileImage}`} />
                                      </span>
                                    )
                                    : (
                                      <Avatar
                                        firstName={review.firstName}
                                        lastName={review.lastName}
                                        name={`${review.firstName} ${review.lastName}`}
                                        forSidebar
                                        className="rounded-circle custom-avatar "
                                      />
                                    )}
                                  <div>
                                    <h5>{`${review.firstName} ${review.lastName}`}</h5>
                                    <p>{review.function}</p>
                                    <ReactStars
                                      value={review.rate}
                                      edit={false}
                                      size={24}
                                      activeColor="#ffd700"
                                    />
                                    <p>{review.comment}</p>
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        ) : <h5> {languageState.translation.CONNECT.NO_REVIEWRES} </h5>}
                        <h5> {languageState.translation.CONNECT.NO_REVIEWRES} </h5>
                      </CardBody>
                    </Card>
                  )
                } */}
                {
                  !location?.state?.fromMission && (
                    <Card>
                      <CardHeader className="font-weight-bold fs-2">
                        {languageState.translation.PROFILE.HIGHLIGHTED_PROJECTS}
                      </CardHeader>
                      <CardBody>
                        <UserExperiences experiences={experiences} />
                      </CardBody>
                    </Card>
                  )
                }
                {
                  !location?.state?.fromMission && (
                    <Card className="p-2">
                      <CardHeader className="font-weight-bold fs-2">
                        {languageState.translation.CONNECT.EXPERIENCES}
                      </CardHeader>
                      {experiences &&
                        experiences.length > 0 &&
                        experiences.map((experience) => (
                          <Media className="media-comment" key={experience.id}>
                            <Media className="w-100 px-3">
                              <div className="media-comment-text w-100">
                                <h6 className="h5 mt-0">{experience.position}{experience.position && experience.company ? ' - ' : ''}{experience.company}</h6>
                                <p className="text-sm lh-160">
                                  {experience.duration}
                                </p>
                                <p className="text-sm lh-160">
                                  {experience.startDate && moment(experience.startDate).format(
                                    'DD/MM/YYYY'
                                  )}{experience.startDate && experience.endDate ? ' - ' : ''}{experience.endDate
                                    ? moment(experience.endDate).format(
                                      'DD/MM/YYYY'
                                    )
                                    : languageState.translation.COMMON.TODAY}
                                </p>
                                <p className="text-sm lh-160">
                                  {experience.description}
                                </p>
                              </div>
                            </Media>
                          </Media>
                        ))}
                      {experiences && !experiences.length && (
                        <div className="d-flex justify-content-center">
                          <h5 className="mt-3">
                            {languageState.translation.CONNECT.NO_EXPERIENCES}
                          </h5>
                        </div>
                      )}
                    </Card>
                  )
                }
                {!location?.state?.fromMission && (
                  <Card className="p-2">
                    <CardHeader className="font-weight-bold fs-2">
                      {' '}
                      {languageState.translation.CONNECT.DEGREES}
                    </CardHeader>
                    {educations &&
                      educations.length > 0 &&
                      educations.map((education) => (
                        <Media className="media-comment" key={education.id}>
                          <Media className="w-100 p-3">
                            <div className="media-comment-text w-100">
                              <h6 className="h5 mt-0">{`${education.degree} - ${education.university}`}</h6>
                              <p className="text-sm lh-160">
                                {education.duration}
                              </p>
                              <p className="text-sm lh-160">
                                {`${moment(education.startDate).format(
                                  'DD/MM/YYYY'
                                )} - ${education.endDate
                                  ? moment(education.endDate).format(
                                    'DD/MM/YYYY'
                                  )
                                  : languageState.translation.COMMON.TODAY
                                  }`}
                              </p>
                              <p className="text-sm lh-160">
                                {education.description}
                              </p>
                            </div>
                          </Media>
                        </Media>
                      ))}
                    {educations && educations.length == 0 && (
                      <div className="d-flex justify-content-center">
                        <h5 className="mt-3">
                          {languageState.translation.CONNECT.NO_EDUCATIONS}
                        </h5>
                      </div>
                    )}
                  </Card>
                )}
              </Col>
              <Col className="col-lg-5">
                <Card className="p-3" style={{ minHeight: '100px' }}>
                  {(location && location.state && location.state.fromMission) ||
                    (userState?.userInfo?.kind === 'COMPANY' || userState?.userInfo?.kind === 'ADMIN') && (
                      <>
                        <span className="text-muted">
                          {languageState.translation.CONNECT.RATE_INDICATION}
                        </span>
                        <span className="font-weight-bold fs-2">
                          {rateIndication()}
                        </span>
                      </>
                    )}
                  <span className="text-muted mt-2">
                    {languageState.translation.CONNECT.EXPERIENCES_YEARS}
                  </span>
                  <span className="font-weight-bold fs-2">
                    {`${getExperienceLevel(user.experienceYears)} (${user.experienceYears} ${languageState.translation.COMMON.YEARS})`}
                  </span>
             
                </Card>
                <Card
                  className={classNames('p-3', {
                    'bg-aviable': user.statusAviability === 'available' || user.statusAviability === 'OPEN_FOR_PROJECT',
                    'bg-not-aviable': user.statusAviability !== 'available' && user.statusAviability !== 'OPEN_FOR_PROJECT' && user.statusAviability !== 'OPEN_FOR_PART_TIME',
                    'bg-not-part-time': user.statusAviability === 'OPEN_FOR_PART_TIME',
                  })}
                >
                  <CardBody className='p-0'>
                    <span>
                      {languageState.translation.CONNECT.CURRENT_STATUS}
                    </span>{' '}
                    <br />
                    <span className="font-weight-bold fs-2 text-uppercase">
                      {
                        languageState.translation?.CONNECT[
                        user.statusAviability
                        ]
                      }
                    </span>
                  </CardBody>
                </Card>
                {
                  location && location.state && location.state.fromMission && (
                    <Card className="p-3">
                      <CardHeader className="p-0 font-weight-bold fs-2">
                        {languageState.translation.CONNECT.COMPLIANCY_INDICATION_DETAILS}
                      </CardHeader>
                      <CardBody className='px-0'>
                        {
                          location && location.state && location.state.user && location.state.user.application && location.state.user.application.validatedRequirements && location.state.user.application.validatedRequirements.length ? (
                            location.state.user.application.validatedRequirements.map((comp, index) => (
                              <div key={index} className={`d-flex my-2`}>
                                <Input
                                  id="remote"
                                  className="ml-0"
                                  name="remote"
                                  type="checkbox"
                                  key={index}
                                  checked
                                />
                                <Label className="ml-4 " for="remote">
                                  {comp}
                                </Label>
                              </div>
                            ))
                          ) : <h5> {languageState.translation.CONNECT.NO_COMPLIANCY_INDICATION_DETAILS} </h5>
                        }
                      </CardBody>
                    </Card>
                  )
                }
                {/* {
                  location && location.state && location.state.fromMission && (
                    <Card className="p-0">
                      <CardHeader className="p-3 font-weight-bold fs-2">
                        {languageState.translation.CONNECT.WORKING_SCHEDULE}
                      </CardHeader>
                      <CardBody className='p-0'>
                        {
                          schedules && schedules.length ? (
                            schedules.map(schedule => (
                              <div key={schedule.id} className='border-bottom px-3'>
                                <h5 className='mt-2'>{schedule.day}</h5>
                                <Row className='m-0'>
                                  {schedule.timeWorked && schedule.timeWorked.length ? (
                                    schedule.timeWorked.map(time => (
                                      <Col lg={6} md={6} sm={12} className='pb-2 px-0'>
                                        <p className='font-weight-normal'>{moment(time.stratTime, 'HH:mm').format('HH:mm a')} - {moment(time.endTime, 'HH:mm').format('HH:mm a')}</p>
                                      </Col>
                                    ))
                                  ) : <p className='font-weight-normal pb-3'>{languageState.translation.CONNECT.NOT_AVAILABLE}</p>}
                                </Row>
                              </div>
                            ))
                          ) : <h5> {languageState.translation.CONNECT.NO_WORKING_SCHEDULE} </h5>
                        }
                        <h5> {languageState.translation.CONNECT.NO_WORKING_SCHEDULE} </h5>
                      </CardBody>
                    </Card>
                  )
                } */}
                {<Card className="p-3">
                  <CardHeader className="p-0 font-weight-bold fs-2">
                    {languageState.translation.CONNECT.LANGUAGES}
                  </CardHeader>
                  <CardBody className='px-0'>
                    <LanguageList
                      type="PROFILE"
                      languages={languages}
                      languageState={languageState}
                    />
                  </CardBody>
                </Card>}
                <Card className="p-3">
                  <CardHeader className="p-0 font-weight-bold fs-2">
                    {languageState.translation.CONNECT.PERSONAL_NOTES}
                  </CardHeader>
                  <CardBody>
                    {userId && isAuthorized() && <ListNotes userId={userId} />}
                  </CardBody>
                </Card>
                {(userState?.userInfo?.kind === "ADMIN" || userState.userInfo?.kind === "RECRUITER") &&
                  <Card className="p-3 mt-5">
                    <Resume />
                  </Card>
                }
                {/* <Card className="p-3">
                  <CardBody className='p-0'>
                    <span className="font-weight-bold fs-2">
                      {languageState.translation.CONNECT.SHARE_SOCIAL_MEDIA}
                    </span>
                    <div className="d-flex justify-content-center mt-4">
                      <TwitterShareButton
                        url={urlToShare}
                        className="mr-2"
                        children={<TwitterIcon size={36} round />}
                      />
                      <FacebookShareButton
                        className="mr-2"
                        children={<FacebookIcon size={36} round />}
                        url={urlToShare}
                      />
                      <LinkedinShareButton
                        url={urlToShare}
                        children={<LinkedinIcon size={36} round />}
                      />
                    </div>
                  </CardBody>
                </Card> */}
                {userState && userState.userInfo && userState.userInfo.kind === 'COMPANY' ? <Card className="p-3">
                  <CardHeader className="p-0 font-weight-bold fs-2">
                    {languageState.translation.CONNECT.EXTRA_DETAILS}
                  </CardHeader>
                  <CardBody>
                    {userId && isAuthorized() && <ExtraDetails details={user.extraInfo} />}
                  </CardBody>
                </Card> : null}
              </Col>
            </Row>
          </Container>
        </div >
      )}
    </>
  );
};

export default ProfileDetails;
