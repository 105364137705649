import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { ModalBody, Row, Col, Button, ModalFooter, Tooltip } from 'reactstrap';
import useUserService from 'services/profile/user.service';
import { AppContext } from 'store/app.context';
import STEPPER_STATE from 'store/stepper';
import { secondStepperScreenSchema } from './schema';
import UserCard from 'components/user-card/user-card';
import { FileUploader } from "react-drag-drop-files";
import useFilesService from 'services/files/files.services';
import CropAvatarEditor from 'components/CropAvatarEditor/CropAvatarEditor';
import { toast } from 'react-toastify';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import nl from 'i18n-iso-countries/langs/nl.json';
const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

countries.registerLocale(en);
countries.registerLocale(nl);

const SecondStepperScreen = ({ timeRemaining }) => {
  const { dispatchStepperState, languageState, userState } = useContext(AppContext);
  const { updateBasicInfos, autoUpdateUserInfo, inviteMultipleUsers } = useUserService();
  const { uploadFile } = useFilesService();
  const [image, setImage] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
/*   const [tooltipAdresses, setTooltipAdresses] = useState(false);
 */
  const [isSubmitted, setIsSubmitted] = useState(false);

  const translatedCountries = countries.getNames(languageState?.defaultLanguage); // Get translated country names
  const countryOptions = Object.entries(translatedCountries).map(([code, name]) => ({
    value: code,
    label: name
  }));

 
  const handleImageChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onProfilePictureChange = async (file) => {
    const result = await uploadFile(file);

    if (result && result.status === 201) {
      const reqData = {
        profileImage: result.data.filename,
      };
      const result2 = await updateBasicInfos(reqData);
      if (result2 && result2.status === 200) {
        autoUpdateUserInfo();
        setImage(null)
      }
    }
  };

  const handleSecondStepperSubmit = async (values) => {
    const { profileImage, freelancersEmails, ...rest } = values
    
    if (userState?.userInfo?.profileImage) {// Send multiple invitations 
      if (freelancersEmails) {
        const dataToSend = freelancersEmails.split('\n').map((el) => { return { email: el } })
        const sendInvitations = await inviteMultipleUsers(dataToSend);
        if (sendInvitations && sendInvitations.status === 201) {
          toast.success(languageState.translation.ALERT.SUCCESS_ADD_USER, {
            position: 'bottom-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      }

     
        autoUpdateUserInfo();
        dispatchStepperState({
          type: STEPPER_STATE.types.CHANGE_CURRENT_STEPPER_STATE,
          currentStepPayload: 3,
        });
      
    };
  }

  return (
    <Formik
      initialValues={{
        profileImage: null,
        freelancersEmails:""
      }}
      enableReinitialize
      validationSchema={secondStepperScreenSchema(
        languageState.translation.FORM_VALIDATION
      )}
      onSubmit={(values) => {
        handleSecondStepperSubmit(values);
      }}
    >
      {({ errors, touched, values, handleChange, setFieldValue, handleBlur, handleSubmit }) => (
        <Form>
          <ModalBody className='pt-0'>
            <Row>
              <Col xs="12" sm="12" md="12" lg="4" xl="4">
                <div className='font-weight-bolder my-3'>
                  {languageState.translation.STEPPER.PROFILE_LOOK}
                </div>
                <div className='mb-2'>
                  <UserCard
                    openModalAddNote={() => { }}
                    openModalDelete={() => { }}
                    openModalAddUserTags={() => { }}
                    user={userState.userInfo}
                    key={userState.userInfo.id}
                    mode={'ONBOARDING'}
                    openModalAddUserGroup={() => { }} module={undefined} />
                </div>
              </Col>
              <Col xs="12" sm="12" md="12" lg="8" xl="8">
                <Row>
                  <Col xs="12" sm="12" md="12" lg="12" xl="12"
                    className={isSubmitted && !userState?.userInfo?.profileImage ? "error-input" : ""}
                  >
                    <div className='font-weight-bolder my-3'>{languageState.translation.STEPPER.BASIC_INFO_SECTION_3}</div>
                    <div className="dropzone dropzone-multiple profile-image-upload my-3" id="dropzone-multiple">
                      <div className="fallback">
                        <FileUploader
                          name="file"
                          label={languageState.translation.COMMON.UPLOAD_PROFILE_PICTURE_LABEL}
                          handleChange={handleImageChange}
                          types={fileTypes}
                        />
                      </div>
                    </div>
                    {
                      image && <CropAvatarEditor
                        image={image}
                        setImage={setImage}
                        onProfilePictureChange={(file) => onProfilePictureChange(file)} />
                    }
                  </Col>
                  {isSubmitted && !userState?.userInfo?.profileImage && (
                    <div className="invalid-feedback">{languageState.translation.FORM_VALIDATION.REQUIRED_FIELD}</div>
                  )}
                 {/*  <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <div className='d-flex font-weight-bolder my-3'>
                      {languageState.translation.STEPPER.BASIC_INFO_SECTION_4}
                      <div id="TooltipAdresses">
                        <i className='fa fa-info-circle ml-2 cursor-pointer' />
                      </div>
                      <Tooltip
                        isOpen={tooltipAdresses}
                        target="TooltipAdresses"
                        toggle={() => {
                          setTooltipAdresses(!tooltipAdresses)
                        }}
                      >
                        {languageState.translation.STEPPER.ADDRESSES_INFO}
                      </Tooltip>
                    </div>
                  </Col> */}
                  
                  {/* 
                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <CountryDropdown
                      className={`form-control w-100 ${errors.country && touched.country ? "error-input" : ""}`}
                      value={values.country}
                      placeholder="België"
                      name="country"
                      onChange={(value) => {
                        setFieldValue('country', value);
                      }}
                    /> */}
                  {/*<Select
                      options={countryOptions}
                      className={`w-100 ${errors.country && touched.country ? "error-input" : ""}`}
                      value={countryOptions.find((el) => el.value === values.country)}
                      placeholder={languageState.translation.PROFILE.COUNTRY}
                      menuPlacement='top'
                      onChange={(e) => setFieldValue('country', e.value)}
                    />*/}
                  {/*
                  </Col>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <div className='d-flex font-weight-bolder my-3'>
                    {languageState.translation.STEPPER.BASIC_INFO_SECTION_5}
                      <div id="TooltipExample">
                        <i className='fa fa-info-circle ml-2 cursor-pointer' />
                      </div>
                      <Tooltip
                        isOpen={tooltipOpen}
                        target="TooltipExample"
                        toggle={() => {
                          setTooltipOpen(!tooltipOpen)
                        }}
                      >
                        {languageState.translation.STEPPER.INVITE_FREELANCERS_INFO}
                      </Tooltip>
                    </div>
                    <Input
                      className={`w-100 ${errors.freelancersEmails && touched.freelancersEmails ? "error-input" : ""}`}
                      placeholder={languageState.translation.STEPPER.SECOND_STEP_PLACEHOLDERS.INVITE_FREELANCERS}
                      name="freelancersEmails"
                      type="textarea"
                      onChange={handleChange}
                    />
                    {errors.freelancersEmails && touched.freelancersEmails && (
                      <div className="invalid-feedback">{errors.freelancersEmails}</div>
                    )}
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className='d-flex justify-content-end'>

            <Button
              className="btn-next-link"
              color="link"
              onClick={() => {
                setIsSubmitted(true);
                handleSubmit();
              }}
            >
              {languageState.translation.COMMON.ONBOARDING_BTN_STEP2_NEXT}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default SecondStepperScreen;
