import React, { useEffect, useState } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import DropwdownFlags from 'components/flags-dropdown';
import { Link } from 'react-router-dom';

const AuthHeader = ({ title, lead }) => {
  const logo = require('assets/img/logo_transparent.png').default;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const shouldHideElement = width >= 800;

  return (
    <Container
      style={{
        backgroundColor: '#FA5252',
        width: '100%',
        minWidth: '100%',
      }}
    >
      {
        shouldHideElement ? null :
          <div className='logo-auth-container text-center mt-2' style={{ position: width > 400 ? "absolute" : 'relative', left: '0', right: '0' }}>
            <Link to={'/'} className='mt-2'>
              <img src={logo} alt="ExtraExpertise." width={width > 500 ? '200' : '150'} />
            </Link>
          </div>
      }
      <div className={`d-flex flags p-2 ${shouldHideElement ? 'justify-content-between' : 'justify-content-end'}`}>
        {
          shouldHideElement ?
            <Link to={'/'}>
              <img src={logo} alt="ExtraExpertise." width={'40%'} />
            </Link> : null
        }
        <DropwdownFlags direction="left" />
      </div>
      <div className="header bg-splash-exprimary py-3 ">
        <Container>
          <div className="header-body text-center">
          {/*   <Row className="justify-content-center">
              <Col lg="12" md="12" xl="12">
                {title ? <h3 className="text-white ">{title}</h3> : null}
              </Col>
            </Row> */}
          </div>
        </Container>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          {/* <polygon className="fill-default" points="2560 0 2560 100 0 100" /> */}
        </svg>
      </div>
    </Container>
  );
};

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default AuthHeader;
