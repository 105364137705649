import React, { useContext, useEffect, useState } from "react";
import { Container, Table, FormGroup, Card, Input, Label } from "reactstrap";
import useUserService from "services/profile/user.service";
import { AppContext } from "store/app.context";



export const NotificationPreferences = () => {

    const { languageState } = useContext(AppContext);
    const { getUserPreferences, setUserPreferences } = useUserService();
    const [disableNotifications, setDisableNotifications] = useState(false);
    const [optionsState, setOptionState] = useState([])
    useEffect(() => {
        const getPreferences = async () => {
            const response = await getUserPreferences();
            setOptionState(response.data.map(pref => ({
                type: pref.type,
                email: pref.preferences.includes("EMAIL"),
                web: pref.preferences.includes("WEB")
            })))
        }
        getPreferences()
    }, [])
    const handleToggle = (index, type) => {
        setOptionState((prevOptions) => {
            return prevOptions.map((option, i) => {
                if (i === index) {
                    const updatedOption = { ...option, [type]: !option[type] };

                    const preferences = {
                        type: updatedOption.type,
                        preferences: [
                            ...(updatedOption.email ? ["EMAIL"] : []),
                            ...(updatedOption.web ? ["WEB"] : []),
                        ]
                    };

                    setUserPreferences(preferences);
                    return updatedOption;
                }
                return option;
            });
        });
    };


    return (
        <Container className="mt-4 align-items-center">
    <h2 className="mb-3">
        {languageState.translation.SETTINGS.NOTIFICATIONS_PREFEENCES}
    </h2>
    <Card className="p-3">
        <FormGroup check>
            <Input
                type="checkbox"
                id="disableNotifications"
                checked={disableNotifications}
                onChange={() => setDisableNotifications(!disableNotifications)}
            />
            <Label check htmlFor="disableNotifications">
                {languageState.translation.NOTIFICATIONS.DISABLE_ALL}
            </Label>
        </FormGroup>
        
        {!disableNotifications && (
            <div className="table-responsive">
                <Table className="table table-bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Web</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody >
                        {optionsState.map((option, index) => (
                            <tr key={index} >
                                <td>{languageState.translation.NOTIFICATIONS[option.type]}</td>
                                <td className="text-center">
                                    <FormGroup switch>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="toggle-switch-checkbox"
                                                id={`toggleWeb-${index}`}
                                                checked={option.web}
                                                onChange={() => handleToggle(index, "web")}
                                            />
                                            <label
                                                className="toggle-switch-label"
                                                htmlFor={`toggleWeb-${index}`}
                                            >
                                                <span className="toggle-switch-inner" />
                                                <span className="toggle-switch-switch" />
                                            </label>
                                        </div>
                                    </FormGroup>
                                </td>
                                <td>
                                    <FormGroup switch>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="toggle-switch-checkbox"
                                                id={`toggleEmail-${index}`}
                                                checked={option.email}
                                                onChange={() => handleToggle(index, "email")}
                                            />
                                            <label
                                                className="toggle-switch-label"
                                                htmlFor={`toggleEmail-${index}`}
                                            >
                                                <span className="toggle-switch-inner" />
                                                <span className="toggle-switch-switch" />
                                            </label>
                                        </div>
                                    </FormGroup>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )}
    </Card>
</Container>

    );
};
