import TYPES from './news.types';
import reducer from './news.reducer';

const INITIAL_STATE = {
  news: [],
};

const NEWS_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default NEWS_STATE;
